import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Checkbox,
  CircularProgress,
  Collapse,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  styled,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import {CanceledError} from "axios";
import {AccountType} from "common/values/account-type/account-type";
import AHBoolean from "common/values/boolean/boolean";
import Guid from "common/values/guid/guid";
import Individual from "marketplace/entities/individual/individual";
import Forum from "messaging/entities/forum/forum";
import {enqueueSnackbar} from "notistack";
import React, {useEffect} from "react";
import {useSession} from "users/session/session-context";
import CommentThread from "work/entities/comment-thread/comment-thread";
import {
  FreelyPatchableFields,
  ProposalField,
} from "work/entities/proposal/proposal";
import FieldRedline, {
  FieldRedlineArray,
} from "work/entities/proposal/redlining/field-redline";
import ProposalRedline from "work/entities/proposal/redlining/proposal-redline";
import SwitchRedline from "work/entities/proposal/redlining/view/redline-field-inputs/switch-redline";
import TeamRedlineSelection from "work/entities/proposal/redlining/view/redline-field-inputs/team-redline-selection";
import TeamTemplateAPIService from "work/entities/team-template/api/team-template-service";
import TeamTemplate from "work/entities/team-template/team-template";
import {ProposalFieldName} from "work/values/constants";
import ProposalIssues from "work/values/proposal-issues/proposal-issues";

const ListContainer = styled(Grid)(
  ({theme}) => ({
    minHeight: "20rem",
  }));
const TemplateContainer = styled(Grid)(
  ({theme}) => ({
    [theme.breakpoints.down("lg")]: {
      width: "100%",
    }
  }));
const TemplateList = styled(List)(
  ({theme}) => ({
    marginRight: theme.spacing(2),
    maxHeight: "25rem",
    minWidth: "20rem",
    overflowY: "auto",
  }));
const TemplateLoader = styled(CircularProgress)(
  ({theme}) => ({
    color: theme.palette.primary.main,
    margin: theme.spacing(2),
  }));
const RedlineTeamSelect = styled(TeamRedlineSelection)(
  ({theme}) => ({
    [theme.breakpoints.down("lg")]: {
      marginTop: theme.spacing(2),
    },
    marginLeft: theme.spacing(2),
  }));
const HeadControls = styled("div")(
  ({theme}) => ({
    display: "flex",
    justifyContent: "space-between",
    paddingLeft: theme.spacing(2),
  }));
const Change = styled("div")<{ focused: boolean }>(
  ({theme, focused}) => ({
    border: focused ? "2px solid" : 0,
    borderColor: theme.palette.primary.main,
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(2),
  }));

type TeamTabProps = {
  isOpen: boolean;
  proposalRedline: ProposalRedline;
  issues?: ProposalIssues;
  activeReviewField: ProposalField | undefined;
  commentThreads: CommentThread[];
  disableEditing?: boolean;
  onProposalRedlineChange: (
    newRedline: ProposalRedline,
    traversalFieldOverride?: ProposalField | null
  ) => void;
  onTraverseToNewField?: (fieldInfo: ProposalField | undefined) => void;
  onCommentsClicked: (
    field: ProposalField,
    title?: string
  ) => void;

  fieldsToPatch?: FreelyPatchableFields;

  teamLeader?: Individual;
  onTeamTemplatesUpdated: (newTemplateIds: Guid[]) => void;
  onTeamMemberQuitProposal: (memberId: Guid) => void;
};

const TeamTab = (props: Readonly<TeamTabProps>) => {
  const {
    isOpen,
    proposalRedline,
    activeReviewField,
    commentThreads,
    disableEditing,
    onProposalRedlineChange,
    onTraverseToNewField,
    onCommentsClicked,

    teamLeader,
    fieldsToPatch,
    onTeamTemplatesUpdated,
    onTeamMemberQuitProposal,
  } = props;

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [templateTeams, setTemplateTeams] = React.useState<TeamTemplate[]>([]);
  const [selectedTemplateIds, setSelectedTemplateIds] = React.useState<Guid[]>(
    []
  );

  const session = useSession();
  const isLargeDownDisplay = useMediaQuery((theme: any) =>
    theme.breakpoints.down("lg")
  );

  useEffect(
    () => {
      if (!isOpen) return;

      let abortController = new AbortController();
      getTemplateTeams(abortController);
      setSelectedTemplateIds(fieldsToPatch?.teamTemplateIds ?? []);

      return () => {
        abortController.abort();
        abortController = new AbortController();
      };
    },
    [isOpen]
  );

  async function getTemplateTeams(
    abortController: AbortController
  ): Promise<void> {
    const entityId = session.currentEntity.id;
    if (!entityId) return;

    try {
      setIsLoading(true);
      const teamTemplateService = new TeamTemplateAPIService(session);
      const templates = await teamTemplateService.getTeamTemplates(
        entityId,
        session.context?.viewingAsVendor
          ? AccountType.Vendor
          : AccountType.Client,
        abortController
      );
      setTemplateTeams(templates);
    } catch (error) {
      if (error instanceof CanceledError) return;
      console.error(error);
    }
    setIsLoading(false);
  }

  function handleTemplateExpanded(template: TeamTemplate) {
    const templates = [...templateTeams];
    let targetTemplate = templates.find((team) =>
      team.id?.isEqualTo(template.id)
    );
    template.isExpanded = !targetTemplate?.isExpanded;

    setTemplateTeams(templates);
  }

  async function handleTemplateSelectionToggled(
    template: TeamTemplate
  ): Promise<void> {
    try {
      if (!template.id) throw new Error("No template selected");
      let updatedSelectedTemplateIds = [...selectedTemplateIds];

      const isAlreadySelected = selectedTemplateIds.some((id) =>
        id.isEqualTo(template.id)
      );
      if (isAlreadySelected) {
        updatedSelectedTemplateIds = updatedSelectedTemplateIds.filter(
          (id) => !id.isEqualTo(template.id)
        );
      } else {
        let newTeamMembersRedline: FieldRedlineArray<Individual> =
          proposalRedline.team;
        template.members.forEach((member: Individual) => {
          if (!getIsMemberAlreadySelected(member)) {
            newTeamMembersRedline = newTeamMembersRedline.addEntry(member);
          }
        });
        const newProposalRedline = proposalRedline.updateTeamMembersRedline(
          newTeamMembersRedline
        );
        onProposalRedlineChange(
          newProposalRedline,
          null
        );
        updatedSelectedTemplateIds.push(template.id);
      }
      setSelectedTemplateIds(updatedSelectedTemplateIds);
      onTeamTemplatesUpdated(updatedSelectedTemplateIds);
      onCommentsClicked(
        ProposalField.Team,
        "Team"
      );
    } catch (err) {
      console.error(err);
      enqueueSnackbar(
        "Failed to apply template. Please try again",
        {
          variant: "error",
        }
      );
    } finally {
      setIsLoading(false);
    }
  }

  function handleToggleTeamMemberSelection(member: Individual) {
    if (getIsMemberAlreadySelected(member)) {
      onProposalRedlineChange(
        proposalRedline.updateTeamMembersRedline(
          proposalRedline.team.removeEntryByFieldId(member?.id ?? undefined),
        ),
        null
      );
    } else {
      onProposalRedlineChange(
        proposalRedline.updateTeamMembersRedline(
          proposalRedline.team.addEntry(member)
        ),
        null
      );
    }
    onCommentsClicked(
      ProposalField.Team,
      "Team"
    );
  }

  function getIsMemberAlreadySelected(member: Individual): boolean {
    return (
      (teamLeader?.isEqualTo(member) ?? false) ||
      proposalRedline.team.redlines.some((individualRedline) =>
        individualRedline.currentEntry
          ? individualRedline.currentEntry.isEqualTo(member)
          : false
      )
    );
  }

  function renderTemplates() {
    return (
      <TemplateList subheader={<ListSubheader>Templates</ListSubheader>}>
        {templateTeams.length === 0 && (
          <>
            {isLoading ? (
              <TemplateLoader size={32} thickness={4}/>
            ) : (
              <ListItem key="noTemplates">
                <ListItemText>No Templates</ListItemText>
              </ListItem>
            )}
          </>
        )}
        {templateTeams.map((template: TeamTemplate) => {
          const isSelected = selectedTemplateIds.some((id) =>
            id.isEqualTo(template.id)
          );

          return (
            <div key={template.id?.value}>
              <ListItem
                secondaryAction={
                  <Tooltip
                    title={`${template.isExpanded ? "Hide" : "Show"} Members`}
                  >
                    <span>
                      <IconButton
                        edge="end"
                        disabled={!proposalRedline.team?.isResolved}
                        onClick={() => handleTemplateExpanded(template)}
                        size="medium"
                      >
                        {template.isExpanded ? (
                          <ExpandLessIcon/>
                        ) : (
                          <ExpandMoreIcon/>
                        )}
                      </IconButton>
                    </span>
                  </Tooltip>
                }
              >
                <ListItemIcon>
                  <Tooltip
                    title={
                      !isSelected ? "Select Template" : "Deselect Template"
                    }
                  >
                    <span>
                      <Checkbox
                        edge="start"
                        color="primary"
                        checked={isSelected}
                        indeterminate={
                          !isSelected &&
                          (template.userIsReferenced(teamLeader?.userId) ||
                            proposalRedline.team.redlines
                              .map((memberRedline) => memberRedline.field.id)
                              .some((memberId) =>
                                template.userIsReferenced(memberId ?? undefined)
                              ))
                        }
                        tabIndex={-1}
                        disableRipple
                        onChange={() =>
                          handleTemplateSelectionToggled(template)
                        }
                      />
                    </span>
                  </Tooltip>
                </ListItemIcon>
                <ListItemText>{template.name.value}</ListItemText>
              </ListItem>
              <Collapse
                in={template.isExpanded}
                timeout="auto"
                sx={{backgroundColor: "#FAFAFA"}}
              >
                <Divider/>
                <List
                  disablePadding
                  dense
                  subheader={<ListSubheader>Leader</ListSubheader>}
                >
                  {renderTemplateLeader(
                    template,
                    isSelected
                  )}
                </List>
                <List
                  disablePadding
                  dense
                  subheader={<ListSubheader>Members</ListSubheader>}
                >
                  {renderTemplateMembers(
                    template,
                    isSelected
                  )}
                </List>
                <Divider/>
              </Collapse>
            </div>
          );
        })}
      </TemplateList>
    );
  }

  function renderTemplateLeader(template: TeamTemplate, isSelected: boolean) {
    return (
      <ListItem
        key="teamLeader"
        secondaryAction={
          <Checkbox
            edge="end"
            color="primary"
            disabled
            checked={Boolean(
              teamLeader?.userId?.isEqualTo(template?.leader?.userId)
            )}
            tabIndex={-1}
            disableRipple
          />
        }
      >
        <ListItemText>{template.leader.getFullName()}</ListItemText>
      </ListItem>
    );
  }

  function renderTemplateMembers(template: TeamTemplate, isSelected: boolean) {
    return template?.members?.map((member: Individual) => (
      <ListItem
        key={member.userId?.value}
        onClick={() => handleToggleTeamMemberSelection(member)}
        secondaryAction={
          <Checkbox
            edge="end"
            color="primary"
            checked={proposalRedline.team?.redlines.some((m) =>
              m.currentEntry?.userId?.isEqualTo(member.userId)
            )}
            tabIndex={-1}
            disableRipple
            onClick={() => handleToggleTeamMemberSelection(member)}
          />
        }
      >
        <ListItemText
          style={{whiteSpace: "pre-line"}}
          primary={`${member.profile?.firstName} ${member.profile?.lastName}`}
        />
      </ListItem>
    ));
  }

  function handleTeamRestrictedChanged(
    newRedline: FieldRedline<AHBoolean>,
    traversalFieldOverride?: ProposalField | null
  ) {
    try {
      onProposalRedlineChange(
        proposalRedline.updateTeamRestrictedRedline(newRedline),
        traversalFieldOverride
      );
      onCommentsClicked(
        ProposalField.Team,
        "Team"
      );
    } catch (error) {
      console.error(error);
      enqueueSnackbar(
        "Error updating team restriction",
        {variant: "error"}
      );
    }
  }

  if (!isOpen) return null;

  return (
    <>
      <HeadControls>
        <Change
          focused={
            activeReviewField?.name === ProposalFieldName.TeamRestriction
          }
        >
          <SwitchRedline
            boolRedline={proposalRedline?.teamRestricted}
            disabled={disableEditing}
            promptTitleText="Restrict team?"
            promptMessageText="Restrict team members from freely leaving team without triggering a renegotiation?"
            okButtonText="Restrict Team"
            unsetLabel="Team Not Restricted"
            setLabel="Team Restricted"
            onBoolRedlineChange={(newRedline, traversalFieldOverride) => {
              try {
                handleTeamRestrictedChanged(
                  newRedline,
                  traversalFieldOverride
                );
              } catch (error) {
                console.error(error);
                enqueueSnackbar(
                  "Error updating team restriction",
                  {
                    variant: "error",
                  }
                );
              }
            }}
          />
        </Change>
      </HeadControls>
      <ListContainer container direction="row">
        {!disableEditing && (
          <>
            <TemplateContainer>
              {renderTemplates()}
              {isLargeDownDisplay && <Divider/>}
            </TemplateContainer>
            {!isLargeDownDisplay && (
              <Grid>
                <Divider orientation="vertical"/>
              </Grid>
            )}
          </>
        )}
        <Grid size="grow">
          <RedlineTeamSelect
            activeReviewField={activeReviewField}
            commentThreads={commentThreads}
            disableEditing={disableEditing}
            teamLeader={teamLeader}
            teamMembersRedline={proposalRedline.team}
            onCommentsClicked={(memberId?: Guid, name?: string) => {
              if (!memberId) {
                onCommentsClicked(
                  ProposalField.Team,
                  name
                );
              } else {
                onCommentsClicked(
                  ProposalField.TeamMember(memberId),
                  name
                );
              }
            }}
            onTeamMembersRedlineChange={(
              newTeamRedline: FieldRedlineArray<Individual>,
              traversalFieldOverride?: ProposalField | null
            ) => {
              try {
                onProposalRedlineChange(
                  proposalRedline.updateTeamMembersRedline(newTeamRedline),
                  traversalFieldOverride
                );
                onCommentsClicked(
                  ProposalField.Team,
                  "Team"
                );
              } catch (error) {
                console.error(error);
                enqueueSnackbar(
                  "Error updating team members",
                  {
                    variant: "error",
                  }
                );
              }
            }}
            onMemberQuitTeam={(memberId: Guid) => {
              try {
                onTeamMemberQuitProposal(memberId);
              } catch (error) {
                console.error(error);
                enqueueSnackbar(
                  "Error quitting team",
                  {variant: "error"}
                );
              }
            }}
            onMemberClicked={(memberId: Guid) => {
              onTraverseToNewField?.(ProposalField.TeamMember(memberId));
            }}
          />
        </Grid>
      </ListContainer>
    </>
  );
};

export default TeamTab;
