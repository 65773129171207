import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Checkbox,
  Chip,
  CircularProgress,
  Collapse,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  styled,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import {CanceledError} from "axios";
import {AccountType} from "common/values/account-type/account-type";
import Guid from "common/values/guid/guid";
import {enqueueSnackbar} from "notistack";
import React, {useEffect, useState} from "react";
import {useSession} from "users/session/session-context";
import CommentThread from "work/entities/comment-thread/comment-thread";
import FeeScheduleTemplateAPIService from "work/entities/fee-schedule-template/api/fee-schedule-template-api-service";
import FeeScheduleTemplate from "work/entities/fee-schedule-template/fee-schedule-template";
import {
  FreelyPatchableFields,
  ProposalField,
} from "work/entities/proposal/proposal";
import {FeeScheduleRedline} from "work/entities/proposal/redlining/fee-schedule-redline/fee-schedule-redline";
import ProposalRedline from "work/entities/proposal/redlining/proposal-redline";
import FeeScheduleRedlineSelection
  from "work/entities/proposal/redlining/view/redline-field-inputs/fee-schedule-redline-selection";
import FeeScheduleCategory from "work/values/fee-schedule-category/fee-schedule-category";
import ProposalIssues from "work/values/proposal-issues/proposal-issues";

const ListContainer = styled(Grid)(
  ({theme}) => ({
    minHeight: "20rem",
  }));
const TemplateContainer = styled(Grid)(
  ({theme}) => ({
    [theme.breakpoints.down("lg")]: {
      width: "100%",
    },
  }));
const TemplateList = styled(List)(
  ({theme}) => ({
    marginRight: theme.spacing(2),
    maxHeight: "25rem",
    minWidth: "20rem",
    overflowY: "auto",
  }));
const TemplateLoader = styled(CircularProgress)(
  ({theme}) => ({
    color: theme.palette.primary.main,
    margin: theme.spacing(2),
  }));
const FeeScheduleTagChip = styled(Chip)(
  ({theme}) => ({
    color: "#777",
    marginRight: theme.spacing(0.25),
  }));
const RedlineCategorySelection = styled(FeeScheduleRedlineSelection)(
  ({theme}) => ({
    [theme.breakpoints.down("lg")]: {
      marginTop: theme.spacing(2),
    },
    height: "100%",
    marginLeft: theme.spacing(2),
  })
);

type FeeScheduleTabProps = {
  isOpen: boolean;
  proposalRedline: ProposalRedline;
  issues?: ProposalIssues;
  activeReviewField: ProposalField | undefined;
  commentThreads: CommentThread[];
  disableEditing?: boolean;
  onProposalRedlineChange: (
    newRedline: ProposalRedline,
    traversalFieldOverride?: ProposalField | null
  ) => void;
  onTraverseToNewField?: (fieldInfo: ProposalField | undefined) => void;
  onCommentsClicked: (field: ProposalField, name?: string) => void;

  fieldsToPatch?: FreelyPatchableFields;

  onFeeScheduleTemplatesUpdated: (templateIds: Guid[], newProposalRedline?: ProposalRedline) => void;
};

const FeeScheduleTab = (props: Readonly<FeeScheduleTabProps>) => {
  const {
    isOpen,
    proposalRedline,
    activeReviewField,
    commentThreads,
    disableEditing,
    onProposalRedlineChange,
    onTraverseToNewField,
    onCommentsClicked,

    fieldsToPatch,

    onFeeScheduleTemplatesUpdated,
  } = props;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [templateFeeSchedules, setTemplateFeeSchedules] = useState<
    FeeScheduleTemplate[]
  >([]);
  const [selectedTemplateIds, setSelectedTemplateIds] = useState<Guid[]>([]);

  const isLargeDownDisplay = useMediaQuery((theme: any) =>
    theme.breakpoints.down("lg")
  );
  const session = useSession();

  useEffect(
    () => {
      let abortController = new AbortController();
      getTemplateFeeSchedules(abortController);
      setSelectedTemplateIds(fieldsToPatch?.feeScheduleTemplateIds ?? []);

      return () => {
        abortController.abort();
        abortController = new AbortController();
      };
    },
    []
  );

  async function getTemplateFeeSchedules(
    abortController: AbortController
  ): Promise<void> {
    const entityId = session?.currentEntity.id;
    if (!entityId) return;

    try {
      setIsLoading(true);

      const feeScheduleService = new FeeScheduleTemplateAPIService(session);
      const templates = await feeScheduleService.getFeeScheduleTemplates(
        entityId,
        session.context?.viewingAsVendor
          ? AccountType.Vendor
          : AccountType.Client,
        abortController
      );

      setTemplateFeeSchedules(templates);
    } catch (error) {
      if (error instanceof CanceledError) return;
      console.error(error);
    }
    setIsLoading(false);
  }

  function handleTemplateExpanded(template: FeeScheduleTemplate) {
    let templates = [...templateFeeSchedules];
    let selectedTemplate = templates.find((feeSchedule) =>
      feeSchedule.id?.isEqualTo(template.id)
    );
    template.isExpanded = !selectedTemplate?.isExpanded;

    setTemplateFeeSchedules(templates);
    onCommentsClicked(
      ProposalField.FeeSchedule,
      undefined
    );
  }

  function handleTemplateSelectionToggled(template: FeeScheduleTemplate) {
    try {
      if (!template.id) throw new Error("No template selected");

      let updatedSelectedTemplateIds = [...selectedTemplateIds];
      const isAlreadySelected = selectedTemplateIds.some((id) =>
        id.isEqualTo(template.id)
      );

      let newProposalRedline: ProposalRedline | undefined = undefined;

      if (isAlreadySelected) {
        updatedSelectedTemplateIds = updatedSelectedTemplateIds.filter(
          (id) => !id.isEqualTo(template.id)
        );
      } else {
        updatedSelectedTemplateIds.push(template.id);

        let newFeeScheduleRedline: FeeScheduleRedline =
          proposalRedline.feeSchedule;
        for (const category of template.categories) {
          newFeeScheduleRedline = newFeeScheduleRedline.addEntry(category);
        }
        newProposalRedline = proposalRedline.updateFeeScheduleRedline(
          newFeeScheduleRedline
        );
      }

      setSelectedTemplateIds(updatedSelectedTemplateIds);
      onFeeScheduleTemplatesUpdated(
        updatedSelectedTemplateIds,
        newProposalRedline
      );
      onCommentsClicked(
        ProposalField.FeeSchedule,
        undefined
      );
    } catch (error) {
      console.error(error);
      enqueueSnackbar(
        "Failed to apply template.",
        {
          variant: "error",
        }
      );
    } finally {
      setIsLoading(false);
    }
  }

  function handleTemplateCategoryToggled(
    event: React.MouseEvent,
    templateCategory: FeeScheduleCategory
  ) {
    event.stopPropagation();
    const feeScheduleCategory = templateCategory.clone();

    if (!feeScheduleCategory) return;

    if (getIsCategoryAlreadySelected(feeScheduleCategory)) {
      const updatedRedline = proposalRedline.updateFeeScheduleRedline(
        proposalRedline.feeSchedule.removeEntryByFieldId(feeScheduleCategory.id)
      );

      onProposalRedlineChange(
        updatedRedline,
        null
      );
    } else {
      const updatedRedline = proposalRedline.updateFeeScheduleRedline(
        proposalRedline.feeSchedule.addEntry(feeScheduleCategory)
      );
      onProposalRedlineChange(
        updatedRedline,
        null
      );
    }
    onCommentsClicked(
      ProposalField.FeeSchedule,
      undefined
    );
  }

  function getIsCategoryAlreadySelected(
    category: FeeScheduleCategory | null
  ): boolean {
    if (!category) return false;
    return proposalRedline.feeSchedule.redlines.some(
      (categoryRedline) =>
        categoryRedline.field.id?.isEqualTo(category.id) &&
        categoryRedline.currentEntry !== null
    );
  }

  function renderTemplates() {
    return (
      <TemplateList subheader={<ListSubheader>Templates</ListSubheader>}>
        {templateFeeSchedules.length === 0 && (
          <>
            {isLoading ? (
              <TemplateLoader size={32} thickness={4}/>
            ) : (
              <ListItem key="noTemplates">
                <ListItemText>No Templates</ListItemText>
              </ListItem>
            )}
          </>
        )}
        {templateFeeSchedules.map((template: FeeScheduleTemplate) => {
          const isSelected = selectedTemplateIds.some((id) =>
            id.isEqualTo(template.id)
          );
          const feeScheduleCategories = template.categories.map(
            (templateCategory) => templateCategory.clone()
          );
          const indeterminate =
            !isSelected &&
            feeScheduleCategories.some(
              (category) => category && getIsCategoryAlreadySelected(category)
            );

          return (
            <div key={template.id?.value}>
              <ListItem
                secondaryAction={
                  <Tooltip
                    title={`${
                      template.isExpanded ? "Hide" : "Show"
                    } Categories`}
                  >
                    <span>
                      <IconButton
                        edge="end"
                        onClick={() => handleTemplateExpanded(template)}
                        size="medium"
                      >
                        {template.isExpanded ? (
                          <ExpandLessIcon/>
                        ) : (
                          <ExpandMoreIcon/>
                        )}
                      </IconButton>
                    </span>
                  </Tooltip>
                }
              >
                <ListItemIcon>
                  <Tooltip
                    title={
                      !isSelected ? "Select Template" : "Deselect Template"
                    }
                  >
                    <span>
                      <Checkbox
                        edge="start"
                        color="primary"
                        checked={isSelected}
                        indeterminate={indeterminate}
                        tabIndex={-1}
                        disableRipple
                        onChange={() =>
                          handleTemplateSelectionToggled(template)
                        }
                      />
                    </span>
                  </Tooltip>
                </ListItemIcon>
                <ListItemText
                  primary={template.name}
                  secondary={template.tags.map((tag) => (
                    <FeeScheduleTagChip
                      key={tag.value}
                      size="small"
                      label={tag.value}
                    />
                  ))}
                />
              </ListItem>
              <Collapse
                in={template.isExpanded}
                timeout="auto"
                style={{backgroundColor: "#FAFAFA"}}
              >
                <Divider/>
                <List disablePadding dense>
                  {renderTemplateCategories(template)}
                </List>
                <Divider/>
              </Collapse>
            </div>
          );
        })}
      </TemplateList>
    );
  }

  function renderTemplateCategories(template: FeeScheduleTemplate) {
    return template.categories.map((category: FeeScheduleCategory) => (
      <ListItem
        key={category.name.value}
        onClick={(event) => handleTemplateCategoryToggled(
          event,
          category
        )}
        secondaryAction={
          <Checkbox
            edge="end"
            color="primary"
            checked={getIsCategoryAlreadySelected(category)}
            tabIndex={-1}
            disableRipple
            onClick={(event) => handleTemplateCategoryToggled(
              event,
              category
            )}
          />
        }
      >
        <ListItemText
          disableTypography
          primary={category.name.value}
          secondary={
            <>
              <div>{category.description?.value}</div>
              <div>{category.feeString}</div>
            </>
          }
        />
      </ListItem>
    ));
  }

  if (!isOpen) return null;

  return (
    <ListContainer container direction="row">
      {!disableEditing && (
        <TemplateContainer>
          {renderTemplates()}
          {isLargeDownDisplay && <Divider/>}
        </TemplateContainer>
      )}
      <Grid size="grow">
        <RedlineCategorySelection
          activeReviewField={activeReviewField}
          commentThreads={commentThreads}
          disableEditing={disableEditing}
          feeScheduleRedline={proposalRedline.feeSchedule}
          onCommentsClicked={onCommentsClicked}
          onFeeScheduleRedlineChange={(
            newFeeScheduleRedline: FeeScheduleRedline,
            traversalFieldOverride?: ProposalField | null
          ) => {
            try {
              onProposalRedlineChange(
                proposalRedline.updateFeeScheduleRedline(newFeeScheduleRedline),
                traversalFieldOverride
              );
            } catch (error) {
              console.error(error);
              enqueueSnackbar(
                "Failed to update fee schedule. Please try again",
                {
                  variant: "error",
                }
              );
            }
          }}
          onCategoryClicked={(categoryId: Guid) => {
            onTraverseToNewField?.(
              ProposalField.FeeScheduleCategory(categoryId)
            );
          }}
        />
      </Grid>
    </ListContainer>
  );
};

export default FeeScheduleTab;
