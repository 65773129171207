import Guid from "common/values/guid/guid";
import EntityMember from "legal-entities/entities/entity-member/entity-member";
import { Moment } from "moment";

export default class ProposalReviewer {
    userId: Guid;
    canEdit: boolean;
    entityMember?: EntityMember;
    dateApproved?: Moment;
    customMessage?: string;

    constructor(
        userId: Guid, 
        canEdit: boolean, 
        entityMember?: EntityMember, 
        dateApproved?: Moment, 
        customMessage?: string
    ) {
        this.userId = userId;
        this.canEdit = canEdit;
        this.entityMember = entityMember;
        this.dateApproved = dateApproved;
        this.customMessage = customMessage;
    }

    public isEqualTo(other: ProposalReviewer | null | undefined): boolean {
        if (!other) {
            return false;
        }
        return this.userId.isEqualTo(other.userId) && this.canEdit === other.canEdit;
    }

    public clone(): ProposalReviewer {  
        return new ProposalReviewer(
            this.userId.clone(), 
            this.canEdit,
            this.entityMember?.clone(),
            this.dateApproved,
            this.customMessage
        );
    }

    public toJSON() : any {
        return {
            userId: this.userId,
            canEdit: this.canEdit,
            individual: this.entityMember?.toJSON(),
            dateApproved: this.dateApproved ?? '',
            customMessage: this.customMessage ?? ''
        }
    }
}
