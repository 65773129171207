import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
  SerializedError,
} from "@reduxjs/toolkit";
import {RootState} from "app/realtime-store/redux-store";
import PaginationParameters from "common/contracts/pagination-parameters";
import Guid from "common/values/guid/guid";
import {useSelector} from "react-redux";
import Session from "users/session/session";
import ProposalAPIService, {
  ProposalNotFoundError,
} from "work/entities/proposal/api/proposal-api-service";
import ProposalParameters from "work/entities/proposal/api/request-contracts/proposal-parameters";
import Proposal from "work/entities/proposal/proposal";
import ProposalRedline from "work/entities/proposal/redlining/proposal-redline";
import ProposalBuilder from "work/entities/proposal/utils/proposal-builder";

type ProposalStoreState = {
  byId: {
    entries: Record<string, Proposal | null | undefined>;
    loading: Record<string, boolean>;
    error: Record<string, SerializedError | null>;
  };
  builders: {
    entries: Record<string, ProposalBuilder | undefined>;
    loading: Record<string, boolean>;
    error: Record<string, SerializedError | null>;
  };
  byQuery: {
    entries: Record<string, Proposal[]>;
    loading: Record<string, boolean>;
    error: Record<string, SerializedError | null>;
  };
  interfaceQueries: {
    entries: Record<string, ProposalQuery>;
  };
};
const initialState: ProposalStoreState = {
  byId: {
    entries: {},
    loading: {},
    error: {},
  },
  builders: {
    entries: {},
    loading: {},
    error: {},
  },
  byQuery: {
    entries: {},
    loading: {},
    error: {},
  },
  interfaceQueries: {
    entries: {},
  },
};

export class ProposalQuery {
  constructor(
    public proposalParams: ProposalParameters,
    public pagination?: PaginationParameters
  ) {
  }

  public asSearchParams(): URLSearchParams {
    return new URLSearchParams([
      ...this.proposalParams.asSearchParams(),
      ...(this.pagination?.asSearchParams() ?? []),
    ]);
  }
}

export const populateProposal = createAsyncThunk(
  "proposals/getProposalById",
  async (
    {
      session,
      id
    }: { session: Session; id: Guid; },
    thunkAPI
  ) => {
    try {
      const apiService = new ProposalAPIService(session);
      return await apiService.getProposalById(id);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const populateRevisions = createAsyncThunk(
  "proposals/getRevisionsByProposalId",
  async (
    {session, proposalId}: { session: Session; proposalId: Guid },
    thunkAPI
  ) => {
    try {
      const apiService = new ProposalAPIService(session);
      const revisions = await apiService.getProposalRevisionsById(proposalId);
      return revisions;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const populateProposalsByQuery = createAsyncThunk(
  "proposals/getProposalsByQuery",
  async (
    {session, query}: { session: Session; query: ProposalQuery },
    thunkAPI
  ) => {
    try {
      const apiService = new ProposalAPIService(session);
      const proposals = await apiService.getProposals(query.proposalParams);
      return proposals;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const proposalsSlice = createSlice({
  name: "proposals",
  initialState,
  reducers: {
    addProposal: (state, action: PayloadAction<Proposal>) => {
      const proposal = action.payload;
      if (!proposal.id) {
        return;
      }

      state.byId.entries[proposal.id.value] = proposal;
      state.byQuery = {
        entries: {},
        loading: {},
        error: {},
      };

    },
    removeProposal: (state, action: PayloadAction<Guid>) => {
      state.byId.entries[action.payload.value] = null;
      state.byQuery = {
        entries: {},
        loading: {},
        error: {},
      };
    },
    addProposalBuilder: (
      state,
      action: PayloadAction<{ proposalId: Guid; builder: ProposalBuilder }>
    ) => {
      if (
        !action.payload.proposalId ||
        state.builders.entries[action.payload.proposalId.value]
      ) {
        return;
      }
      const proposalId = action.payload.proposalId.value;
      state.builders.entries[proposalId] = action.payload.builder as any;
    },
    clearNewProposalBuilder: (state) => {
      state.builders.entries[Guid.empty.toString()] = undefined;
    },
    replaceProposalBuilder: (
      state,
      action: PayloadAction<{ proposalId: Guid; builder: ProposalBuilder }>
    ) => {
      const proposalId = action.payload.proposalId.value;
      state.builders.entries[proposalId] = action.payload.builder as any;
    },
    removeBuilderByProposalId: (state, action: PayloadAction<Guid>) => {
      delete state.builders.entries[action.payload.value];
    },
    updateProposalInterfaceQuery: (
      state,
      action: PayloadAction<{
        interfaceName: string;
        query: ProposalQuery;
      }>
    ) => {
      state.interfaceQueries.entries[action.payload.interfaceName] =
        action.payload.query;
    },
    resetProposalSessionByProposalId: (state, action: PayloadAction<Guid>) => {
      const proposal = state.byId.entries[action.payload.value];
      if (!proposal) {
        return;
      }
      state.byId.entries[action.payload.value] = proposal.resetSession();
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      populateProposal.pending,
      (state, action) => {
        const proposalId = action.meta.arg.id.value;
        state.byId.loading[proposalId] = true;
        state.byId.error[proposalId] = null;
      }
    );
    builder.addCase(
      populateProposal.fulfilled,
      (state, action) => {
        const proposal = action.payload as Proposal;
        if (proposal?.supersedes?.id) {
          let staleProposal: Proposal | undefined;
          for (const [_, existingProposal] of Object.entries(state.byId.entries)) {
            if (existingProposal?.replacedBy?.value === proposal?.id?.value) {
              if (staleProposal) {
                console.warn("Multiple stale proposals found for superseded proposal");
              }
              staleProposal = existingProposal as Proposal;
            }
          }

          if (staleProposal?.redline) {
            proposal.createRedline(staleProposal.redline);
          }
        }
        const proposalId = action.meta.arg.id.value;
        state.byId.loading[proposalId] = false;
        state.byId.entries[proposalId] = action.payload as any;
      }
    );
    builder.addCase(
      populateProposal.rejected,
      (state, action) => {
        const proposalId = action.meta.arg.id.value;
        state.byId.loading[proposalId] = false;
        state.byId.error[proposalId] = action.error;
        if (action.payload instanceof ProposalNotFoundError) {
          state.byId.entries[proposalId] = null;
        }
      }
    );

    builder.addCase(
      populateProposalsByQuery.pending,
      (state, action) => {
        if (!action.meta.arg.query?.proposalParams) {
          return;
        }
        const query = action.meta.arg.query.proposalParams
          .asSearchParams()
          .toString();
        state.byQuery.loading[query] = true;
        state.byQuery.error[query] = null;
      }
    );
    builder.addCase(
      populateProposalsByQuery.fulfilled,
      (state, action) => {
        if (!action.meta.arg.query?.proposalParams) {
          return;
        }
        const query = action.meta.arg.query.asSearchParams().toString();
        state.byQuery.loading[query] = false;
        state.byQuery.entries[query] = action.payload;
      }
    );
    builder.addCase(
      populateProposalsByQuery.rejected,
      (state, action) => {
        if (!action.meta.arg.query?.proposalParams) {
          return;
        }
        const query = action.meta.arg.query.proposalParams
          .asSearchParams()
          .toString();
        state.byQuery.loading[query] = false;
        state.byQuery.error[query] = action.error;
      }
    );
  },
});

export const {
  addProposal,
  removeProposal,
  addProposalBuilder,
  clearNewProposalBuilder,
  replaceProposalBuilder,
  removeBuilderByProposalId,
  resetProposalSessionByProposalId,
  updateProposalInterfaceQuery,
} = proposalsSlice.actions;
export const getProposalById = (id?: Guid) =>
  useSelector((state: RootState) => {
    if (!id) return undefined;
    return state.proposals?.byId.entries[id.value];
  });
export const getIsLoadingProposalById = (id?: Guid) =>
  useSelector((state: RootState) => {
    if (!id) return false;
    return (
      state.proposals?.byId.loading[id.value]
    );
  });
export const getErrorLoadingProposalById = (id?: Guid) =>
  useSelector((state: RootState) => {
    if (!id) return undefined;
    return state.proposals?.byId.error[id.value];
  });
export const getProposalBuilderByProposalId = (proposalId?: Guid | null) =>
  useSelector((state: RootState) => {
    if (!proposalId) return undefined;
    return state.proposals?.builders.entries[proposalId.value];
  });
export const getProposalQueryByInterface = (interfaceName: string) =>
  useSelector(
    (state: RootState) =>
      state.proposals.interfaceQueries.entries[interfaceName]
  );
export const getProposalsByQuery = (query: ProposalQuery) =>
  useSelector((state: RootState) => {
    if (!query) {
      return undefined;
    }
    const searchParams = query.asSearchParams().toString();
    return state.proposals?.byQuery.entries[searchParams];
  });
export const getIsLoadingProposalsByQuery = (query: ProposalQuery) =>
  useSelector((state: RootState) => {
    if (!query) {
      return false;
    }
    const searchParams = query.asSearchParams().toString();
    return state.proposals?.byQuery.loading[searchParams];
  });
export const getErrorLoadingProposalsByQuery = (query: ProposalQuery) =>
  useSelector((state: RootState) => {
    if (!query) {
      return false;
    }
    const searchParams = query.asSearchParams().toString();
    return state.proposals?.byQuery.error[searchParams];
  });

export default proposalsSlice;
