import CommentIcon from "@mui/icons-material/Comment";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import GetAppIcon from "@mui/icons-material/GetApp";
import InfoIcon from "@mui/icons-material/Info";
import UploadIcon from "@mui/icons-material/Upload";

import {
  Badge,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListSubheader,
  Tooltip,
  Typography,
} from "@mui/material";
import {styled} from "@mui/material/styles";
import LoadingButton from "common/components/loading-button";
import Guid from "common/values/guid/guid";
import DocumentAPIService from "documents/entities/document/api/document-api-service";
import Document from "documents/entities/document/document";
import DocumentTopic from "documents/values/document-topic";
import UnavailableDocument from "documents/values/unavailable-document";
import moment from "moment";
import {enqueueSnackbar} from "notistack";
import React from "react";
import {defaultStyles, FileIcon} from "react-file-icon";
import {useSession} from "users/session/session-context";
import CommentThread from "work/entities/comment-thread/comment-thread";
import {ProposalField} from "work/entities/proposal/proposal";
import WorkDocument, {WorkDocumentType} from "work/values/work-document/work-document";

const DocumentList = styled(List)(
  ({theme}) => ({
    "&.MuiList-root": {
      marginLeft: 0,
    },
    minWidth: "20rem",
  }));
const ListSubhead = styled(ListSubheader)(
  ({theme}) => ({
    backgroundColor: "#FFF",
    color: "#000",
    display: "flex",
    fontSize: "1.4em",
    justifyContent: "space-between",
    paddingRight: 0,
    whiteSpace: "nowrap",
  }));
const NoRowsPlaceholder = styled(Typography)(
  ({theme}) => ({
    fontSize: "1.3em",
    paddingTop: theme.spacing(1),
  }));
const DocumentIcon = styled(ListItemAvatar)(
  ({theme}) => ({
    marginRight: theme.spacing(1),
    minWidth: "2rem",
    maxWidth: "2rem",
  }));

type SelectedDocumentListProps = {
  className?: string;
  documents?: (WorkDocument)[];
  documentType: WorkDocumentType;
  downloadingFileId: Guid | null;
  disableCommenting?: boolean;
  commentThreads?: CommentThread[];
  disableEditing?: boolean;
  conflictsCheckWaived?: boolean;
  onCommentsClicked: (field: ProposalField, name?: string) => void;
  onDownload: (documentId: Guid) => void;
  onDocumentRemoved: (documentId: Guid) => void;
  onDocumentAdded: (document: WorkDocument) => void;
};

export default function SelectedDocumentList(
  props: Readonly<SelectedDocumentListProps>
) {
  const {
    className,
    documents,
    documentType,
    downloadingFileId,
    disableCommenting,
    commentThreads,
    disableEditing,
    conflictsCheckWaived,
    onCommentsClicked,
    onDownload,
    onDocumentRemoved,
    onDocumentAdded,
  } = props;

  const session = useSession();
  const documentFileUploaderRef = React.useRef<HTMLInputElement | null>(null);
  const [isUploading, setIsUploading] = React.useState(false);

  function getShouldShowBadge(document?: Document): boolean {
    if (!document) return false;

    let field: ProposalField | undefined;
    if (documentType === WorkDocumentType.Conflicts) {
      field = ProposalField.ConflictsDocument(document.id);
    } else {
      field = session.context?.viewingAsVendor
        ? ProposalField.VendorPolicyDocument(document.id)
        : ProposalField.ClientPolicyDocument(document.id);
    }

    return commentThreads?.some((thread) => thread.field.isEqualTo(field)) ?? false;
  }

  function renderCommentsButton(document?: Document) {
    return (
      <Tooltip
        title={
          disableCommenting ? "Save proposal to enable commenting" : "Comments"
        }
      >
        <span>
          <IconButton
            disabled={disableCommenting}
            onClick={(event) => {
              event.stopPropagation();
              if (!document) return;
              let field: ProposalField;
              if (documentType === WorkDocumentType.Conflicts) {
                field = ProposalField.ConflictsDocument(document?.id);
              } else {
                field = session.context?.viewingAsVendor
                  ? ProposalField.VendorPolicyDocument(document?.id)
                  : ProposalField.ClientPolicyDocument(document?.id);
                onCommentsClicked(
                  field,
                  document?.name?.value
                );
              }
            }}
          >
            <Badge
              variant="dot"
              color="secondary"
              overlap="circular"
              invisible={!getShouldShowBadge(document)}
            >
              <CommentIcon fontSize="medium"/>
            </Badge>
          </IconButton>
        </span>
      </Tooltip>
    );
  }

  function renderActionButtons(document: Document) {
    return (
      <>
        <Tooltip title="Download Document">
          <span>
            <IconButton
              edge="end"
              disabled={downloadingFileId !== null}
              onClick={() => onDownload(document.id)}
              size="medium"
            >
              {downloadingFileId?.isEqualTo(document.id) ? (
                <CircularProgress color="primary" size={24}/>
              ) : (
                <GetAppIcon color="primary"/>
              )}
            </IconButton>
          </span>
        </Tooltip>
        {!disableEditing && (
          <Tooltip title="Remove Document">
            <span>
              <IconButton
                edge="end"
                disabled={downloadingFileId !== null}
                onClick={() => onDocumentRemoved(document.id)}
                size="medium"
              >
                <DeleteIcon color="error"/>
              </IconButton>
            </span>
          </Tooltip>
        )}
        {renderCommentsButton(document)}
      </>
    );
  }

  async function handleDocumentFileUpload(
    event: React.ChangeEvent<HTMLInputElement>
  ): Promise<void> {
    if (!event.currentTarget.files) return;

    const file: File = event.currentTarget.files[0];

    try {
      setIsUploading(true);

      let context: string | undefined;
      context = documentType === WorkDocumentType.Conflicts ? "Conflicts" : "Policy";
      const service = new DocumentAPIService(session);
      const document = await service.createDocument(
        file,
        file.name,
        [
          new DocumentTopic(
            undefined,
            undefined,
            context
          ),
        ]
      );
      if (!(document instanceof Document)) {
        console.error("Uploaded document is not available.");
        throw new Error("Uploaded document is not available.");
      }
      enqueueSnackbar(
        "Uploaded file",
        {variant: "success"}
      );
      onDocumentAdded(WorkDocument.fromDocument(
        document,
        documentType
      ));
    } catch (error: any) {
      if (error.response && error.response.status === 415) {
        enqueueSnackbar(
          error.response.data,
          {variant: "error"}
        );
      } else if (error.response && error.response.status === 422) {
        enqueueSnackbar(
          "Unsupported file type",
          {variant: "error"}
        );
      } else {
        enqueueSnackbar(
          "Unknown error occurred during upload",
          {
            variant: "error",
          }
        );
      }
    } finally {
      event.target.value = "";
      setIsUploading(false);
    }
  }

  return (
    <>
      <DocumentList disablePadding className={className}>
        <ListSubhead>
          {documentType === WorkDocumentType.Conflicts && (
            <span>
              Your Conflicts Checks
              <Tooltip
                disableFocusListener
                title="Conflicts document(s) establish potential conflicts checks required by participating clients/vendors"
                placement="bottom"
                arrow
              >
                <IconButton size="large" edge="end">
                  <InfoIcon/>
                </IconButton>
              </Tooltip>
            </span>
          )}
          {(documentType === WorkDocumentType.ClientPolicy || documentType === WorkDocumentType.VendorPolicy) && (
            <span>
              Your Policies
              <Tooltip
                disableFocusListener
                title="Policy document(s) establish the policies required by participating clients/vendors"
                placement="bottom"
                arrow
              >
                <IconButton size="large" edge="end">
                  <InfoIcon/>
                </IconButton>
              </Tooltip>
            </span>
          )}
        </ListSubhead>
        {!disableEditing && (
          <ListItem key="uploadButton">
            <LoadingButton
              color="primary"
              loading={isUploading}
              startIcon={<UploadIcon/>}
              onClick={() => documentFileUploaderRef.current?.click()}
            >
              {`Upload ${documentType === WorkDocumentType.Conflicts ? "Conflicts Check" : "Policy Document"
              }`}
            </LoadingButton>
          </ListItem>
        )}
        {!conflictsCheckWaived && (!documents || documents.length < 1) && (
          <ListItem key="noDocuments">
            <NoRowsPlaceholder>None</NoRowsPlaceholder>
          </ListItem>
        )}
        {!conflictsCheckWaived &&
          documents
            ?.filter((document) => !(document instanceof UnavailableDocument))
            .map((document: Document | UnavailableDocument) => {
              const availableDocument = document as Document;
              return (
                <ListItem
                  key={document.id.value as React.Key}
                  secondaryAction={
                    renderActionButtons(availableDocument)
                  }
                >
                  <DocumentIcon>
                    {document instanceof UnavailableDocument ? (
                      <FileIcon/>
                    ) : (
                      <FileIcon
                        extension={document.fileType}
                        {...defaultStyles[
                          document.fileType.toLowerCase() as keyof typeof defaultStyles
                          ]}
                      />
                    )}
                  </DocumentIcon>
                  <ListItemText
                    primary={availableDocument.name?.value}
                    secondary={moment(availableDocument.created).format(
                      "MM/DD/YY hh:mm A"
                    )}
                    slotProps={{
                      primary: {
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        width: "75%",
                      }
                    }}
                  />
                </ListItem>
              );
            })}
      </DocumentList>
      <input
        type="file"
        hidden={true}
        ref={documentFileUploaderRef}
        onChange={handleDocumentFileUpload}
      />
    </>
  );
}
