import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
  SerializedError,
} from "@reduxjs/toolkit";
import { RootState } from "app/realtime-store/redux-store";
import Guid from "common/values/guid/guid";
import MessagingAPIService from "messaging/api/messaging-api-service";
import { useSelector } from "react-redux";
import Session from "users/session/session";
import CommentThread from "work/entities/comment-thread/comment-thread";
import { savePendingComments } from "work/entities/comment/store/comments-redux-slice";
import Proposal from "work/entities/proposal/proposal";

type CommentThreadsStoreState = {
  byProposalId: {
    entries: Record<string, CommentThread[]>;
    loading: Record<string, boolean>;
    error: Record<string, SerializedError | null>;
  };
};

const initialState: CommentThreadsStoreState = {
  byProposalId: {
    entries: {},
    loading: {},
    error: {},
  },
};
export const populateCommentThreadsByProposal = createAsyncThunk(
  "commentThreads/getCommentThreadsByProposal",
  async (
    { session, proposal, proposalId }: { session: Session; proposal?: Proposal, proposalId?: Guid },
    thunkAPI
  ) => {
    const id: string | undefined = proposal?.id?.value ?? proposalId?.value;
    if (!id)
      return thunkAPI.rejectWithValue("Proposal id is required");
    try {
      const apiService = new MessagingAPIService(session);
      const messageForums = await apiService.getForums(
        "Work.Proposal",
        new Guid(id),
        "any"
      );
      return messageForums.map(CommentThread.fromForum);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
const commentThreadsSlice = createSlice({
  name: "CommentThreads",
  initialState,
  reducers: {
    addCommentThread: (state, action: PayloadAction<CommentThread>) => {
      if (action.payload.id) {
        const existingThreads =
          state.byProposalId.entries[action.payload.proposalId.value] ??
          ([] as CommentThread[]);
        const newThreads = existingThreads.filter(
          (thread) =>
            thread.id?.value !== action.payload.id?.value
        );
        newThreads.push(action.payload);
        state.byProposalId.entries[action.payload.proposalId.value] =
          newThreads;
      }
    },
    addCommentThreads: (state, action: PayloadAction<CommentThread[]>) => {
      action.payload.forEach((thread) => {
        const existingThreads =
          state.byProposalId.entries[thread.proposalId.value] ??
          ([] as CommentThread[]);
        const newThreads = existingThreads.filter((t) => t.id?.value !== thread.id?.value);
        newThreads.push(thread);
        state.byProposalId.entries[thread.proposalId.value] = newThreads;
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      populateCommentThreadsByProposal.pending,
      (state, action) => {
        const proposalId = action.meta.arg.proposal?.id?.value.toString() ?? action.meta.arg.proposalId?.value.toString();
        if (!proposalId) {
          console.warn(
            "Proposal id not referenced in populateCommentThreadsByProposal"
          );
          return;
        }
        state.byProposalId.loading[proposalId] = true;
        state.byProposalId.error[proposalId] = null;
      }
    );
    builder.addCase(
      populateCommentThreadsByProposal.fulfilled,
      (state, action) => {
        const proposalId = action.meta.arg.proposal?.id?.value.toString() ?? action.meta.arg.proposalId?.value.toString();
        if (!proposalId) {
          console.warn(
            "Proposal id not referenced in populateCommentThreadsByProposal"
          );
          return;
        }

        state.byProposalId.loading[proposalId] = false;
        state.byProposalId.entries[proposalId] = action.payload;
      }
    );
    builder.addCase(
      populateCommentThreadsByProposal.rejected,
      (state, action) => {
        const proposalId = action.meta.arg.proposal?.id?.value.toString();
        if (!proposalId) {
          console.warn(
            "Proposal id not referenced in populateCommentThreadsByProposal"
          );
          return;
        }
        state.byProposalId.loading[proposalId] = false;
        state.byProposalId.error[proposalId] = action.error;
      }
    );
    builder.addCase(
      savePendingComments.fulfilled,
      (state, action) => {
        const newComments = action.payload;
        if (!newComments.length) {
          return;
        }
        const newCommentsThreads = action.payload.map((comment) => comment.thread);
        newCommentsThreads.forEach((thread) => {
          const existingThreads =
            state.byProposalId.entries[thread.proposalId.value] ??
            ([] as CommentThread[]);
          const newThreads = existingThreads.filter((t) => t.id !== thread.id);
          newThreads.push(thread);
          state.byProposalId.entries[thread.proposalId.value] = newThreads;
        });
      }
    )
  },
});

export const { addCommentThread, addCommentThreads } =
  commentThreadsSlice.actions;
export const getCommentThreadsByProposal = (proposal?: Proposal | null, proposalId?: Guid | null) =>
  useSelector((state: RootState) => {
    const id = proposal?.id?.value ?? proposalId?.value;
    if (!id) return undefined;
    return state.commentThreads?.byProposalId.entries[id];
  });
export const getIsLoadingCommentThreadsByProposal = (proposal?: Proposal | null, proposalId?: Guid | null) => {
  return useSelector((state: RootState) => {
    const id = proposal?.id?.value ?? proposalId?.value;
    if (!id) return false;
    return state.commentThreads?.byProposalId.loading[id];
  });
}
export const getErrorLoadingCommentThreadsByProposal = (proposal?: Proposal | null, proposalId?: Guid | null) => {
  return useSelector((state: RootState) => {
    const id = proposal?.id?.value ?? proposalId?.value;
    if (!id) return undefined;
    return state.commentThreads?.byProposalId.error[id];
  });
}

export default commentThreadsSlice;
