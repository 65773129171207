import {MarkEmailRead, MarkEmailUnread} from "@mui/icons-material";
import CancelIcon from "@mui/icons-material/Cancel";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import PublicIcon from "@mui/icons-material/Public";
import SaveIcon from "@mui/icons-material/Save";
import UndoIcon from "@mui/icons-material/Undo";
import {
  Badge,
  Chip,
  IconButton,
  Link,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import {green, lightBlue} from "@mui/material/colors";
import {styled} from "@mui/material/styles";
import {useDialog} from "app/providers/dialog";
import {useAttorneyHubDispatch} from "app/realtime-store/redux-store";
import Guid from "common/values/guid/guid";
import parse from "html-react-parser";
import {
  getIndividualByUserId,
  populateIndividuals
} from "marketplace/entities/individual/realtime-store/individuals-redux-slice";
import ViewIndividualProfile from "marketplace/values/individual-profile/view/view-individual-profile";
import IndividualAvatar from "marketplace/view/individual-avatar";
import moment from "moment";
import React from "react";
import {useSession} from "users/session/session-context";
import Comment from "work/entities/comment/comment";
import {addComment, removeComment} from "work/entities/comment/store/comments-redux-slice";
import ReactMarkdown from "react-markdown";
import MessageFileInfo from "../../../../messaging/values/attachment/message-file-info";
import {DefaultExtensionType, defaultStyles, FileIcon} from "react-file-icon";
import {filesize} from "filesize";


const SystemCommentContainer = styled('div')(({theme}) => ({
  '@container (min-width: 800px)': {
    width: '75%'
  },
  '@container (min-width: 0px)': {
    width: '100%'
  },
  margin: theme.spacing(0, 'auto', 4, 'auto'),
  width: '80%'
}));
const SystemCommentInfo = styled('div')(({theme}) => ({
  alignItems: 'baseline',
  display: 'flex',
  marginBottom: theme.spacing(0.5),
}));
const SystemCommentTime = styled(Typography)(({theme}) => ({
  fontSize: '0.8em',
  marginLeft: theme.spacing(1.32)
}));
const EditCommentInput = styled(TextField)(({theme}) => ({
  "& .MuiOutlinedInput-root": {
    borderRadius: 0,
    "& fieldset": {
      borderColor: theme.palette.common.black,
    },
    "&:hover fieldset": {
      borderColor: theme.palette.common.black,
    },
    "&.Mui-focused fieldset": {
      borderColor: theme.palette.common.black,
    },
  },
}));
const CommentListItem = styled(ListItem, {
  shouldForwardProp: (prop) => prop !== "pending" && prop !== "unread",
})<{ pending: boolean, unread: boolean }>(({pending, unread}) => ({
  opacity: pending ? 0.66 : 1,
  '& .MuiTypography-root': {
    fontWeight: unread ? 500 : 'normal'
  }
}));
const NameLink = styled(Link, {
  shouldForwardProp: (prop) => prop !== "unread" && prop !== "disabled"
})<{ disabled: boolean, unread: boolean }>(({theme, disabled, unread}) => ({
  display: "inline-block",
  cursor: disabled ? "default" : "pointer",
  textDecoration: disabled ? "none" : "underline",
  "&::before": {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: "50%",
    content: '""',
    display: "inline-block",
    height: unread ? "8px" : 0,
    marginRight: unread ? "8px" : 0,
    verticalAlign: "middle",
    width: unread ? "8px" : 0
  }
}));
const CommentDate = styled(Typography)(({theme}) => ({
  color: theme.palette.text.secondary,
  display: "block",
}));
const ExternalChip = styled(Chip)(({theme}) => ({
  border: 0,
  marginLeft: theme.spacing(0.5),
}));
const PendingChip = styled(Chip)(() => ({
  border: 0,
}));
const CommentContent = styled("span")(({theme}) => ({
  width: '100%',
  '& span.action': {
    fontWeight: 500,
    '&.accepted': {
      color: theme.palette.success.main
    },
    '&.rejected': {
      color: theme.palette.error.main
    }
  },
  '& > span.comment-redline': {
    width: '100%',
    '& > span.change-group': {
      whiteSpace: 'nowrap',
      '& .added': {
        '&.resolved': {
          color: theme.palette.text.primary
        },
        backgroundColor: 'unset',
        color: theme.palette.text.primary
      }
    },
    '& span.added': {
      backgroundColor: green[300],
      color: theme.palette.text.primary,
      '&.resolved': {
        backgroundColor: 'unset',
        color: lightBlue[700]
      }
    },
    '& span.removed': {
      textDecoration: 'line-through',
      textDecorationColor: theme.palette.error.main,
      '&.resolved': {
        textDecorationColor: lightBlue[700]
      }
    },
    '& span.unchanged': {
      color: theme.palette.text.primary
    }
  }
}));
const SystemMessageContent = styled('div')(({theme}) => ({
  backgroundColor: '#e0e0e0',
  borderRadius: theme.spacing(0.66),
  display: 'block',
  textAlign: 'center',
  padding: theme.spacing(2),
  '& > p': {
    margin: 0
  }
}));

type CommentItemProps = {
  className?: string;
  comment: Comment;
  audienceSwitch: React.ReactNode;
};

export default function CommentItem(props: Readonly<CommentItemProps>) {
  const {
    className,
    comment,
    audienceSwitch,
  } = props;

  const session = useSession();
  const dispatch = useAttorneyHubDispatch();
  const [isBeingEdited, setIsBeingEdited] = React.useState<boolean>(false);
  const [editedCommentText, setEditedCommentText] = React.useState<string>("");
  const commenter = getIndividualByUserId(comment.senderId);
  if (!commenter && comment.senderId) {
    dispatch(populateIndividuals({session, userIds: [comment.senderId]}))
  }

  const {openDialog} = useDialog();

  function getPendingStatus(comment: Comment): string {
    if (comment.isDeleted) return "Pending Deletion";
    if (comment.markedForEdit) return "Pending Edit";
    return "Pending";
  }

  function getCommentContent(comment: Comment) {
    if (comment.isDeleted && !comment.markedForDeletion) {
      return (<i>This comment was deleted</i>)
    }
    return (
      <CommentContent>
        {parse(comment.content)}
      </CommentContent>
    );
  }

  function handleViewCommenterProfile() {
    if (!commenter) {
      console.warn("Commenter not found");
      return;
    }

    openDialog({
      component: <ViewIndividualProfile profile={commenter.profile}/>,
      titleStyle: {
        position: "absolute",
        right: 0,
        top: 0,
      },
      contentSxProps: {
        display: "flex",
        overflowX: "hidden",
      },
      MuiProps: {
        maxWidth: "lg",
        fullWidth: true,
      },
    });
    setTimeout(() => adjustDialogPosition(false), 500);
  }


  function adjustDialogPosition(panelOpen: boolean) {
    for (const dialog of document.getElementsByClassName("MuiDialog-root")) {
      dialog.setAttribute(
        "style",
        `padding-right: ${
          panelOpen ? "0px" : "400px"
        }; transition: padding-right 225ms;`
      );
    }
  }

  return comment.senderId ? (
    <CommentListItem
      className={className}
      key={comment.id?.value ?? Guid.generate().value}
      pending={comment.isPending}
      unread={
        Boolean(
            (comment.senderId && !comment.senderId.isEqualTo(session.user?.id)) &&
            (!comment.markedForReadReceipt && !comment.isReadByUser(session.user?.id)) ||
            comment.markedForUnread
        )
      }
      disableGutters
      alignItems="flex-start"
    >
      <ListItemAvatar>
        <IndividualAvatar
          avatarId={commenter?.profile?.avatarId}
          individualId={commenter?.id}
          session={session}
        />
      </ListItemAvatar>
      {isBeingEdited && (
        <EditCommentInput
          multiline
          rows={4}
          variant="outlined"
          fullWidth
          value={editedCommentText}
          helperText={audienceSwitch}
          slotProps={{
            input: {
              endAdornment: (
                <>
                  <Tooltip title="Save comment">
                    <IconButton
                      color="primary"
                      size="small"
                      disabled={editedCommentText.trim() === ""}
                      onClick={() => {
                        if (editedCommentText.trim() === "") return;
                        comment.edit(editedCommentText);
                        dispatch(addComment(comment));
                        setIsBeingEdited(false);
                      }}
                    >
                      <SaveIcon fontSize="inherit"/>
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Cancel editing">
                    <IconButton
                      color="primary"
                      size="small"
                      onClick={() => {
                        setIsBeingEdited(false);
                        setEditedCommentText("");
                      }}
                    >
                      <CancelIcon fontSize="inherit"/>
                    </IconButton>
                  </Tooltip>
                </>
              ),
            },
          }}
          onKeyDown={(event) => {
            if (event.key === "Enter" && editedCommentText.trim() !== "") {
              event.preventDefault();
              comment.content = editedCommentText;
              dispatch(addComment(comment));
            } else if (event.key === "Escape") {
              setIsBeingEdited(false);
            }
          }}
          onChange={(event) => setEditedCommentText(event.target.value)}
        />
      )}
      {(!isBeingEdited && comment.senderId) && (
        <ListItemText
          primary={
            <>
              <NameLink
                disabled={!commenter?.isVisible}
                unread={
                  Boolean(
                      (comment.senderId && !comment.senderId.isEqualTo(session.user?.id)) &&
                      (!comment.markedForReadReceipt && !comment.isReadByUser(session.user?.id)) ||
                      comment.markedForUnread
                  )
                }
                onClick={(event) => {
                  event.preventDefault();
                  if (!commenter?.isVisible) return;
                  handleViewCommenterProfile();
                }}
              >
                {commenter?.getFullName()}
              </NameLink>
              {comment.thread.isExternal && (
                <ExternalChip
                  label="External"
                  size="small"
                  icon={<PublicIcon/>}
                  variant="outlined"
                />
              )}
              {comment.isPending && (
                <PendingChip
                  label={getPendingStatus(comment)}
                  size="small"
                  color="warning"
                  variant="outlined"
                />
              )}
              <CommentDate variant="caption">
                {comment.publishedOn?.format("MMM D, YYYY h:mm A") ??
                  moment().format("MMM D, YYYY h:mm A")}
              </CommentDate>
            </>
          }
          secondary={getCommentContent(comment)}
          slotProps={{
            primary: {
              fontWeight: 500
            },
            secondary: {
              color: "textPrimary"
            }
          }}
        />
      )}
      {comment.senderId && !comment.senderId.isEqualTo(session.user?.id) && (
        <Tooltip
          title={
            (!comment.markedForReadReceipt &&
              !comment.isReadByUser(session.user?.id)) ||
            comment.markedForUnread
              ? "Mark Read"
              : "Mark Unread"
          }
        >
          <IconButton
            color="primary"
            size="small"
            onClick={() => {
              if (!comment.id) return;
              !comment.isReadByUser(session.user?.id)
                ? comment.setRead()
                : comment.setUnread();
              dispatch(addComment(comment));
            }}
          >
            {(!comment.markedForReadReceipt &&
              !comment.isReadByUser(session.user?.id)) ||
            comment.markedForUnread ? (
              <MarkEmailUnread fontSize="inherit"/>
            ) : (
              <MarkEmailRead fontSize="inherit"/>
            )}
          </IconButton>
        </Tooltip>
      )}
      {(!comment.isDeleted || comment.markedForDeletion) &&
        comment.senderId?.isEqualTo(session.user?.id) && (
          <>
            {!isBeingEdited && !comment.isAutoGenerated && !comment.isDeleted && (
              <>
                <Tooltip title="Edit comment">
                  <IconButton
                    color="primary"
                    size="small"
                    onClick={() => {
                      setIsBeingEdited(true);
                      setEditedCommentText(comment.content);
                    }}
                  >
                    <EditIcon fontSize="inherit"/>
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete comment">
                  <IconButton
                    color="primary"
                    size="small"
                    onClick={() => {
                      if (comment.markedForCreation) {
                        dispatch(removeComment(comment.id));
                        return;
                      }
                      comment.setDeleted();
                      dispatch(addComment(comment));
                    }}
                  >
                    <DeleteIcon color="error" fontSize="inherit"/>
                  </IconButton>
                </Tooltip>
              </>
            )}
            {comment.isPending && comment.isDeleted && (
              <Tooltip title="Undo deletion">
                <IconButton
                  color="primary"
                  size="small"
                  onClick={() => {
                    if (!comment.id) return;
                    comment.setUndeleted();
                    dispatch(addComment(comment));
                  }}
                >
                  <UndoIcon fontSize="inherit"/>
                </IconButton>
              </Tooltip>
            )}
          </>
        )}
    </CommentListItem>
  ) : (
    <SystemCommentContainer>
      <SystemCommentInfo style={{justifyContent: 'center'}}>
        <SystemCommentTime>
          <time>
            {comment.publishedOn?.format('MM/DD/YY hh:mm A')}
          </time>
        </SystemCommentTime>
      </SystemCommentInfo>
      <SystemMessageContent>
        <ReactMarkdown>{comment.content}</ReactMarkdown>
      </SystemMessageContent>
    </SystemCommentContainer>
  );
}
