import {ConfirmResponse, useConfirmDialog} from "app/providers/confirm-dialog";
import {DialogComponent, DialogProps} from "common/components/dialog";
import Guid from "common/values/guid/guid";
import React, {createContext, useContext, useState} from "react";

type DialogContextType = {
  openDialog: (dialogProps: DialogProps, onCloseCallback?: () => void, getIsDirty?: () => boolean) => void;
  closeDialog: () => void;
  closeAllDialogs: () => void;
}

type DialogInfo = {
  id: Guid;
  dialog: React.ReactElement;
  open: boolean;
  getIsDirty?: () => boolean;
}

export const DialogContext = createContext<DialogContextType>(undefined!);

export default function DialogProvider({children}: Readonly<{ children: React.ReactNode }>) {
  const [dialogs, setDialogs] = useState<DialogInfo[]>([]);

  const confirm = useConfirmDialog();

  const closeDialog = () => {
    const updatedDialogs = [...dialogs];
    if (updatedDialogs.length > 0) {
      updatedDialogs[0].open = true;
    }
    setDialogs(updatedDialogs);
  }

  const closeAllDialogs = () => {
    setDialogs([]);
  }

  async function handleDialogClose(onCloseCallback?: () => void, getIsDirty?: () => boolean) {
    const isDirty = getIsDirty?.();
    if (isDirty) {
      const response = await confirm({
        title: 'Discard changes?',
        message: 'Are you sure you want to discard changes?',
        okButtonText: 'Discard',
        cancelButtonText: 'Cancel'
      });

      if (response === ConfirmResponse.Cancel) return;
    }

    closeAllDialogs();
    onCloseCallback?.();
  }

  const openDialog = (newDialogProps: DialogProps, onCloseCallback?: () => void, getIsDirty?: () => boolean) => {
    let updatedDialogs = [...dialogs];

    const newDialog: DialogInfo = {
      id: Guid.generate(),
      open: true,
      dialog: (
        <DialogComponent
          {...newDialogProps}
          onClose={() => handleDialogClose(
            onCloseCallback,
            getIsDirty
          )}
          onBack={closeDialog}
          hideBackButton={dialogs.length < 1}
          hideCloseButton={dialogs.length > 0}
        />
      )
    };

    if (!newDialogProps.resetStack) {
      if (updatedDialogs.length > 0) {
        updatedDialogs[0].open = false;
      }
      updatedDialogs.unshift(newDialog);
    } else {
      dialogs.forEach((dialogInfo: DialogInfo) => {
        dialogInfo.open = false;
      })
      updatedDialogs = [newDialog];
    }

    setDialogs(updatedDialogs);
  }

  const dialogState = {openDialog, closeDialog, closeAllDialogs};

  return (
    <DialogContext.Provider value={dialogState}>
      {children}
      {dialogs.map((dialogInfo: DialogInfo) => (
        <span key={dialogInfo.id.value} style={{display: dialogInfo?.open ? 'unset' : 'none'}}>
          {dialogInfo?.dialog}
        </span>
      ))}
    </DialogContext.Provider>
  );
}

export function useDialog() {
  const context = useContext(DialogContext);
  if (context === undefined) {
    throw new Error("useDialog must be used within a DialogProvider");
  }
  return context;
}
