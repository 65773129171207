import AddCommentIcon from "@mui/icons-material/AddComment";
import CloseIcon from "@mui/icons-material/Close";
import CommentIcon from "@mui/icons-material/Comment";
import DoneIcon from "@mui/icons-material/Done";
import SearchIcon from "@mui/icons-material/Search";
import {
  Avatar,
  Button,
  CircularProgress,
  FormControlLabel,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Popover,
  Switch,
  TextField,
  Tooltip,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { CanceledError } from "axios";
import EmailAddress from "common/values/email-address/email-address";
import Guid from "common/values/guid/guid";
import Name from "common/values/name/name";
import EntityMemberAPIService from "legal-entities/entities/entity-member/api/entity-member-api-service";
import EntityMember from "legal-entities/entities/entity-member/entity-member";
import LegalEntity from "legal-entities/entities/legal-entity/legal-entity";
import EntityName from "legal-entities/values/entity-name/entity-name";
import _, { debounce } from "lodash";
import IndividualAPIService from "marketplace/entities/individual/api/individual-api-service";
import Individual from "marketplace/entities/individual/individual";
import IndividualAvatar from "marketplace/view/individual-avatar";
import React, { ChangeEvent, useEffect } from "react";
import { useSession } from "users/session/session-context";
import Proposal from "work/entities/proposal/proposal";
import ProposalBuilder from "work/entities/proposal/utils/proposal-builder";
import ProposalReviewer from "work/values/proposal-reviewer";
import ReviewerMessagePopover from "work/view/components/reviewer-message-popover";

const PopoverContainer = styled("div")(({ theme }) => ({
  minWidth: "25rem",
  padding: theme.spacing(2),
  textAlign: "center",
  width: "min-content",
}));
const SearchField = styled(TextField)(({ theme }) => ({
  width: "100%",
})) as typeof TextField;
const LoaderProgress = styled(CircularProgress)(({ theme }) => ({
  display: "inline-block",
  marginBottom: theme.spacing(4),
  marginTop: theme.spacing(4),
}));
const ListContainer = styled(List)(({ theme }) => ({
  maxHeight: "20rem",
  overflowX: "hidden",
  overflowY: "auto",
}));
const ButtonContainer = styled("div")(({ theme }) => ({
  display: "grid",
  gap: theme.spacing(1),
  gridTemplateColumns: "1fr 1fr",
  width: "100%",
}));
const SelectedIcon = styled(Avatar)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  opacity: '0.75',
  position: "absolute",
  zIndex: 1,
}));
const EditingSwitch = styled(FormControlLabel)(({ theme }) => ({
  alignItems: "flex-end",
  "& .MuiFormControlLabel-label": {
    fontSize: "0.7em",
  },
}));
const ReviewerAvatar = styled(ListItemAvatar)(({ theme }) => ({
  position: "relative",
}));

type ReviewerSelectorProps = {
  proposal?: Proposal;
  proposalBuilder?: ProposalBuilder;
  popoverAnchor?: HTMLButtonElement;
  onChange?: (reviewers: ProposalReviewer[]) => void;
  onPopoverClose?: () => void;
};

export default function ReviewerSelector(
  props: Readonly<ReviewerSelectorProps>
) {
  const {
    proposal,
    proposalBuilder,
    popoverAnchor,
    onChange,
    onPopoverClose,
  } = props;

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [reviewerPool, setReviewerPool] = React.useState<ProposalReviewer[]>([]);
  const [reviewerResults, setReviewerResults] = React.useState<ProposalReviewer[]>([]);
  const [originalReviewers, setOriginalReviewers] = React.useState<ProposalReviewer[]>([]);
  const [selectedReviewers, setSelectedReviewers] = React.useState<ProposalReviewer[]>([]);
  const [currentReviewerEditing, setCurrentReviewerEditing] = React.useState<ProposalReviewer>();
  const [searchTerm, setSearchTerm] = React.useState<string>("");
  const [popoverAnchorElement, setPopoverAnchorElement] = React.useState<HTMLElement | null>(null);

  const session = useSession();

  useEffect(() => {
    if (!popoverAnchor || reviewerPool.length > 0) return;

    let abortController = new AbortController();

    getReviewers(abortController);

    return () => {
      abortController.abort();
      abortController = new AbortController();
    };
  }, [popoverAnchor, reviewerPool]);

  useEffect(() => {
    if(session.context?.viewingAsVendor) {
      setSelectedReviewers(proposalBuilder?.currentSpec.vendorReviewers ?? []);
    } else {
      setSelectedReviewers(proposalBuilder?.currentSpec.clientReviewers ?? []);
    }
  }, [proposalBuilder]);

  useEffect(() => {
    if(session.context?.viewingAsVendor) {
      setSelectedReviewers(proposal?.vendorReviewers ?? []);
    } else {
      setSelectedReviewers(proposal?.clientReviewers ?? []);
    }
  }, [proposal]);

  async function getReviewers(abortController: AbortController) {
    setIsLoading(true);

    try {
      // Get entity members
      const entityMemberService = new EntityMemberAPIService(session);
      const entityMemberInfo: EntityMember[] =
        await entityMemberService.getLegalEntityMembersUserInfo(
          new LegalEntity(session.entities?.[0].entityId),
          abortController
        );

      // Get team members
      const teamMemberIds = proposalBuilder?.currentSpec.team?.memberUserIds
        ?? proposal?.team?.memberUserIds ?? [];
      const individualService = new IndividualAPIService(session);
      const teamMembers: Individual[] =
        await individualService.getUsersProfileInfo(
          teamMemberIds.filter((id) => id !== undefined),
          abortController
        );
      teamMembers.forEach((member: Individual) => {
        const entityMember = entityMemberInfo.find((e) =>
          e.userId.isEqualTo(member.userId));
        if (!entityMember) {
          let memberInfo = {
            ...member,
            email:
              member.profile?.email ?? new EmailAddress("unknown@example.com"),
            entityId: member.entityId ?? Guid.generate(),
            entityName: new EntityName(
              member.company?.profile?.name ?? "Unknown"
            ),
            individualId: member.id,
            isAdmin: false,
            isOfficer: false,
            isActive: true,
            memberId: member.id,
            name: new Name(member.profile?.firstName, member.profile?.lastName),
            userId: member.userId ?? undefined,
          };
          const newEntityMember = new EntityMember(memberInfo);
          entityMemberInfo.push(newEntityMember);
        }
      });

      // Existing proposal reviewers
      let proposalReviewers: ProposalReviewer[] = [];
      if (session.context?.viewingAsVendor) {
        proposalReviewers = proposalBuilder?.currentSpec.vendorReviewers
          ?? proposal?.vendorReviewers.map(r => r.clone()) ?? [];
      } else {
        proposalReviewers = proposalBuilder?.currentSpec.clientReviewers
          ?? proposal?.clientReviewers.map(r => r.clone()) ?? [];
      }

      // Exclude current user and convert to ProposalReviewer array
      let reviewerPool: ProposalReviewer[] = entityMemberInfo.filter(
        (reviewer: EntityMember) => reviewer.userId.value !== session.user?.id?.value
      )
      .map((entityMember: EntityMember) => {
        return new ProposalReviewer(entityMember.userId, false, entityMember);
      });

      // Merge existing reviewers with the pool
      proposalReviewers.forEach((reviewer: ProposalReviewer) => {
        const existingReviewer = reviewerPool.find((r) =>
          r.userId.isEqualTo(reviewer.userId)
        );
        if (existingReviewer) {
          existingReviewer.canEdit = reviewer.canEdit;
          existingReviewer.customMessage = reviewer.customMessage;
          existingReviewer.dateApproved = reviewer.dateApproved;
        } else {
          reviewerPool.push(reviewer);
        }
      });

      setReviewerPool(reviewerPool);
      setReviewerResults(reviewerPool);
      setOriginalReviewers(proposalReviewers.map(r => r.clone()));
      setSelectedReviewers(proposalReviewers.map(r => r.clone()));
    } catch (err) {
      if (err instanceof CanceledError) return;
      console.error(err);
    }
    setIsLoading(false);
  }

  function handleMenuClose() {
    onChange?.(selectedReviewers);
    setReviewerResults(reviewerPool);
    setSearchTerm("");
    onPopoverClose?.();
  }

  const debouncedSearch = debounce(
    (value) =>
      setReviewerResults(
        reviewerPool.filter((reviewer) =>
          reviewer.entityMember?.name.toString().toLowerCase().includes(value.toLowerCase()) ||
          reviewer.entityMember?.email.toString().toLowerCase().includes(value.toLowerCase())
        )
      ),
    100
  );

  function search(value: string) {
    setSearchTerm(value);

    if (value === "") {
      setReviewerResults(reviewerPool);
      return;
    }

    debouncedSearch(value);
  }

  function handleReviewerSelected(
    event: React.MouseEvent<HTMLDivElement>,
    reviewer: ProposalReviewer
  ) {
    event.stopPropagation();

    const alreadySelected = selectedReviewers.some((r) =>
      r.userId.isEqualTo(reviewer.userId)
    );

    if (!alreadySelected) {
      setSelectedReviewers((prevReviewers) => [...prevReviewers, reviewer]);
    } else {
      setSelectedReviewers((prevReviewers) =>
        prevReviewers.filter((r) => r.userId.value !== reviewer.userId.value)
      );
    }
  }

  function handleEditToggled(
    _: ChangeEvent<HTMLInputElement>,
    editedReviewer: ProposalReviewer
  ) {
    const updatedReviewers = [];

    for (const selectedReviewer of selectedReviewers) {
      const updatedReviewer = selectedReviewer.clone();
      if (selectedReviewer.userId.isEqualTo(editedReviewer.userId)) {
        updatedReviewer.canEdit = !selectedReviewer.canEdit;
      } 
      updatedReviewers.push(updatedReviewer);
    }

    if(!selectedReviewers.some((selectedReviewer) => editedReviewer.userId.isEqualTo(selectedReviewer.userId))) {
      const addedReviewer = editedReviewer.clone();
      addedReviewer.canEdit = true;
      updatedReviewers.push(addedReviewer);
    }
    setSelectedReviewers(updatedReviewers);
  }

  function handleMessagePopoverOpened(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    reviewer?: ProposalReviewer
  ) {
    setCurrentReviewerEditing(reviewer);
    setPopoverAnchorElement(event?.currentTarget);
  }

  function handleReviewerMessageChanged(userId?: Guid, message?: string) {
    setPopoverAnchorElement(null);

    if (!userId || !selectedReviewers) return;

    // Update the custom message for the reviewer
    const updatedReviewers = [...selectedReviewers].map((r) => {
      if (r.userId.isEqualTo(userId)) {
        r.customMessage = message;
      }
      return r;
    });

    setSelectedReviewers(updatedReviewers);
  }

  function getMemberType(reviewer: ProposalReviewer): string {
    const team = proposalBuilder?.currentSpec.team ?? proposal?.team;
    if (team?.leader?.userId.isEqualTo(reviewer.userId)) {
      return "Team Leader";
    }
    if (team?.memberUserIds.some((id) => id.isEqualTo(reviewer.userId))) {
      return "Team Member";
    }
    return "Coworker";
  }

  return (
    <Popover
      anchorEl={popoverAnchor}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      transformOrigin={{ vertical: "bottom", horizontal: "center" }}
      open={popoverAnchor !== undefined}
      onClose={handleMenuClose}
    >
      <PopoverContainer>
        <SearchField
          placeholder="Search for reviewer"
          slotProps={{
            input: {
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  {searchTerm !== "" && (
                    <IconButton
                      size="small"
                      onClick={async () => {
                        setSearchTerm("");
                        search("");
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            },
          }}
          value={searchTerm}
          onChange={(event) => search(event.target.value)}
        />
        {isLoading && <LoaderProgress size={50} />}
        {!isLoading && (
          <ListContainer dense>
            {reviewerResults.length < 1 && (
              <ListItem>
                <ListItemText>No Reviewers Found</ListItemText>
              </ListItem>
            )}
            {reviewerResults.length > 0 &&
              reviewerResults.map((reviewer: ProposalReviewer) => {
                const isSelected: boolean = selectedReviewers.some((r) =>
                  r.userId.isEqualTo(reviewer.userId)
                );
                const isEditor: boolean = selectedReviewers.some((r) =>
                  r.userId.isEqualTo(reviewer.userId) && r.canEdit
                );
                const hasApproved: boolean = selectedReviewers.some((r) =>
                  r.userId.isEqualTo(reviewer.userId) && r.dateApproved
                );
                return (
                  <ListItem
                    key={reviewer.userId.value}
                    disablePadding
                    secondaryAction={
                      <div>
                        <EditingSwitch
                          label={isEditor ? "full edit" : "comment only"}
                          labelPlacement="bottom"
                          control={
                            <Switch
                              checked={isEditor}
                              color="primary"
                              onChange={(event) =>
                                handleEditToggled(event, reviewer)
                              }
                              onClick={(event) => event.stopPropagation()}
                              size="small"
                            />
                          }
                        />
                        {isSelected && !originalReviewers.some((r) => r.userId.isEqualTo(reviewer.userId)) && (
                          <Tooltip title={`${reviewer.customMessage ? "Edit" : "Add"} Custom Message`}>
                            <IconButton
                              size="medium"
                              onClick={(event) => handleMessagePopoverOpened(event, reviewer)}
                            >
                              {reviewer.customMessage ? (
                                <CommentIcon color="secondary" />
                              ) : (
                                <AddCommentIcon color="secondary" />
                              )}
                            </IconButton>
                          </Tooltip>
                        )}
                      </div>
                    }
                  >
                    <ListItemButton
                      selected={isSelected}
                      onClick={(event) => handleReviewerSelected(event, reviewer)}
                    >
                      <ReviewerAvatar>
                        {isSelected && (
                          <SelectedIcon>
                            <DoneIcon />
                          </SelectedIcon>
                        )}
                        <IndividualAvatar
                          avatarId={reviewer.entityMember?.avatarId}
                          individualId={reviewer.entityMember?.individualId}
                          session={session}
                        />
                      </ReviewerAvatar>
                      <ListItemText
                        primary={reviewer.entityMember?.name.toString()}
                        secondary={
                          hasApproved ? "Approved" : getMemberType(reviewer)
                        }
                        slotProps={{
                          primary: {
                            color: isSelected ? "primary" : "initial",
                            fontWeight: isSelected ? "bold" : "normal"
                          },
                          secondary: {
                            color: hasApproved ? "success" : "textSecondary",
                          }
                        }}
                      />
                    </ListItemButton>
                  </ListItem>
                );
              })}
          </ListContainer>
        )}
        <ButtonContainer>
          <Button
            variant="contained"
            color="primary"
            onClick={handleMenuClose}
          >
            Ok
          </Button>
          <Button 
            variant="outlined" 
            color="primary"
            onClick={() => {
              setSelectedReviewers(originalReviewers);
              setReviewerResults(reviewerPool);
              setSearchTerm("");
            }}
          >
            Reset
          </Button>
        </ButtonContainer>
      </PopoverContainer>
      <ReviewerMessagePopover
        userId={currentReviewerEditing?.userId ?? undefined}
        anchorEl={popoverAnchorElement}
        message={currentReviewerEditing?.customMessage}
        onCanceled={() => setPopoverAnchorElement(null)}
        onMessageChanged={(userId?: Guid, message?: string) => {
          handleReviewerMessageChanged(userId, message);
        }}
      />
    </Popover>
  );
}
