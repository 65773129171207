import {ArrowBack as ArrowBackIcon, Close as CloseIcon} from "@mui/icons-material";
import {
  Dialog,
  DialogContent,
  DialogProps as MUIDialogProps,
  DialogTitle,
  IconButton,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery
} from "@mui/material";
import {styled, SxProps} from "@mui/material/styles";
import React, {ReactNode} from "react";

const Title = styled(DialogTitle)(
  ({theme}) => ({
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(
        1,
        2
      )
    },
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between'
  }));
const TitleNav = styled('div')(
  ({theme}) => ({
    display: 'flex',
    alignItems: 'center'
  }));
const BackButton = styled(IconButton)(
  ({theme}) => ({
    marginRight: theme.spacing(1),
    zIndex: 999
  }));
const CloseButton = styled(IconButton)(
  ({theme}) => ({
    zIndex: 999
  }));
const Content = styled(DialogContent)(
  ({theme}) => ({
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1)
    }
  }));

export interface DialogProps {
  MuiProps?: Partial<MUIDialogProps>;
  component: React.ReactNode;
  title?: ReactNode | string;
  contentSxProps?: SxProps<Theme>;
  titleStyle?: React.CSSProperties;
  titleTypographyStyle?: React.CSSProperties;
  hideCloseButton?: boolean;
  hideBackButton?: boolean;
  onBack?: () => void;
  onClose?: () => void;
  resetStack?: boolean;
}

export const DialogComponent = React.forwardRef((
  props: Readonly<DialogProps>, ref: React.ForwardedRef<HTMLDivElement>) => {
  const {
    MuiProps,
    component,
    title,
    contentSxProps,
    titleStyle,
    titleTypographyStyle,
    hideBackButton,
    hideCloseButton,
    onBack,
    onClose
  } = props;

  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  return (
    <Dialog open fullScreen={isSmallScreen} ref={ref} disablePortal {...MuiProps}>
      <Title style={titleStyle}>
        <TitleNav>
          {!hideBackButton && (
            <Tooltip title="Back" placement="bottom">
              <span>
                <BackButton
                  aria-label="back"
                  onClick={onBack}
                  size="medium"
                  edge="start">
                  <ArrowBackIcon/>
                </BackButton>
              </span>
            </Tooltip>
          )}
          <Typography style={titleTypographyStyle} variant="h5">
            {title}
          </Typography>
        </TitleNav>
        {!hideCloseButton && (
          <Tooltip title="Close" placement="bottom">
            <span>
              <CloseButton
                aria-label="close"
                onClick={onClose}
                size="medium"
                edge="end">
                <CloseIcon/>
              </CloseButton>
            </span>
          </Tooltip>
        )}
      </Title>
      <Content sx={contentSxProps}>
        {component}
      </Content>
    </Dialog>
  );
});
