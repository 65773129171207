import AHBoolean from "common/values/boolean/boolean";
import Date from "common/values/date/date";
import Percent from "common/values/percent/percent";
import Individual from "marketplace/entities/individual/individual";
import {ProposalField} from "work/entities/proposal/proposal";
import {FeeScheduleRedline} from "work/entities/proposal/redlining/fee-schedule-redline/fee-schedule-redline";
import FieldRedline, {
  FieldRedlineArray,
} from "work/entities/proposal/redlining/field-redline";
import {RedlineChange} from "work/entities/proposal/redlining/redline-change";
import ProjectDescription from "work/values/project-description/project-description";
import ProjectName from "work/values/project-name/project-name";
import WorkDocument from "work/values/work-document/work-document";

export default class ProposalRedline {
  private _name: FieldRedline<ProjectName>;
  private _description: FieldRedline<ProjectDescription>;
  private _responseDueBy: FieldRedline<Date>;
  private _startDate: FieldRedline<Date>;
  private _endDate: FieldRedline<Date>;
  private _team: FieldRedlineArray<Individual>;
  private _feeSchedule: FeeScheduleRedline;
  private _clientPolicyDocuments: FieldRedlineArray<WorkDocument>;
  private _vendorPolicyDocuments: FieldRedlineArray<WorkDocument>;
  private _conflictsCheckWaived: FieldRedline<AHBoolean>;
  private _teamRestricted: FieldRedline<AHBoolean>;
  private _conflictsDocuments: FieldRedlineArray<WorkDocument>;
  private _discount: FieldRedline<Percent>;
  private _lastFieldUpdated: ProposalField | undefined;
  private _fieldsPendingReview: ProposalField[] = [];

  constructor(
    name: FieldRedline<ProjectName>,
    description: FieldRedline<ProjectDescription>,
    dates: RedlinedDates,
    team: FieldRedlineArray<Individual>,
    feeSchedule: FeeScheduleRedline,
    conflictsCheckWaived: FieldRedline<AHBoolean>,
    teamRestricted: FieldRedline<AHBoolean>,
    documents: RedlinedDocuments,
    discount: FieldRedline<Percent>
  ) {
    this._name = name;
    this._description = description;
    this._responseDueBy = dates?.responseDueBy;
    this._startDate = dates?.startDate;
    this._endDate = dates?.endDate;
    this._team = team;
    this._feeSchedule = feeSchedule;
    this._clientPolicyDocuments = documents.clientPolicyDocuments;
    this._vendorPolicyDocuments = documents.vendorPolicyDocuments;
    this._conflictsCheckWaived = conflictsCheckWaived;
    this._teamRestricted = teamRestricted;
    this._conflictsDocuments = documents.conflictsDocuments;
    this._discount = discount;

    this.registerFieldsPendingReview();
  }

  public get name(): FieldRedline<ProjectName> {
    return this._name;
  }

  public get description(): FieldRedline<ProjectDescription> {
    return this._description;
  }

  public get responseDueBy(): FieldRedline<Date> {
    return this._responseDueBy;
  }

  public get startDate(): FieldRedline<Date> {
    return this._startDate;
  }

  public get endDate(): FieldRedline<Date> {
    return this._endDate;
  }

  public get team(): FieldRedlineArray<Individual> {
    return this._team;
  }

  public get feeSchedule(): FeeScheduleRedline {
    return this._feeSchedule;
  }

  public get clientPolicyDocuments(): FieldRedlineArray<WorkDocument> {
    return this._clientPolicyDocuments;
  }

  public get vendorPolicyDocuments(): FieldRedlineArray<WorkDocument> {
    return this._vendorPolicyDocuments;
  }

  public get conflictsCheckWaived(): FieldRedline<AHBoolean> {
    return this._conflictsCheckWaived;
  }

  public get teamRestricted(): FieldRedline<AHBoolean> {
    return this._teamRestricted;
  }

  public get conflictsDocuments(): FieldRedlineArray<WorkDocument> {
    return this._conflictsDocuments;
  }

  public get discount(): FieldRedline<Percent> {
    return this._discount;
  }

  public get lastFieldUpdated(): ProposalField | undefined {
    return this._lastFieldUpdated;
  }

  public get hasRejections(): boolean {
    return (
      this._name.isRejected ||
      this._description.isRejected ||
      this._responseDueBy.isRejected ||
      this._startDate.isRejected ||
      this._endDate.isRejected ||
      this._team.hasRejections ||
      this._feeSchedule.hasRejections ||
      this._clientPolicyDocuments.hasRejections ||
      this._vendorPolicyDocuments.hasRejections ||
      this._conflictsCheckWaived.isRejected ||
      this._teamRestricted.isRejected ||
      this._conflictsDocuments.hasRejections ||
      this._discount.isRejected
    )
  }

  public get hasRevisions(): boolean {
    return (
      this._name.isRevised ||
      this._description.isRevised ||
      this._responseDueBy.isRevised ||
      this._startDate.isRevised ||
      this._endDate.isRevised ||
      this._team.isRevised ||
      this._feeSchedule.isRevised ||
      this._clientPolicyDocuments.isRevised ||
      this._vendorPolicyDocuments.isRevised ||
      this._conflictsCheckWaived.isRevised ||
      this._teamRestricted.isRevised ||
      this._conflictsDocuments.isRevised ||
      this._discount.isRevised
    )
  }

  public get hasChanges(): boolean {
    return (
      this._name.hasChanges ||
      this._description.hasChanges ||
      this._responseDueBy.hasChanges ||
      this._startDate.hasChanges ||
      this._endDate.hasChanges ||
      this._team.hasChanges ||
      this._feeSchedule.hasChanges ||
      this._clientPolicyDocuments.hasChanges ||
      this._vendorPolicyDocuments.hasChanges ||
      this._conflictsCheckWaived.hasChanges ||
      this._teamRestricted.hasChanges ||
      this._conflictsDocuments.hasChanges ||
      this._discount.hasChanges
    )
  }

  public get sessionHistory(): RedlineChange[] {
    return [
      ...this._name.sessionHistory,
      ...this._description.sessionHistory,
      ...this._responseDueBy.sessionHistory,
      ...this._startDate.sessionHistory,
      ...this._endDate.sessionHistory,
      ...this._team.sessionHistory,
      ...this._feeSchedule.sessionHistory,
      ...this._clientPolicyDocuments.sessionHistory,
      ...this._vendorPolicyDocuments.sessionHistory,
      ...this._conflictsCheckWaived.sessionHistory,
      ...this._teamRestricted.sessionHistory,
      ...this._conflictsDocuments.sessionHistory,
      ...this._discount.sessionHistory,
    ];
  }

  public get isResolved(): boolean {
    return (
      this._name.isResolved &&
      this._description.isResolved &&
      this._responseDueBy.isResolved &&
      this._startDate.isResolved &&
      this._endDate.isResolved &&
      this._team.isResolved &&
      this._feeSchedule.isResolved &&
      this._clientPolicyDocuments.isResolved &&
      this._vendorPolicyDocuments.isResolved &&
      this._conflictsCheckWaived.isResolved &&
      this._teamRestricted.isResolved &&
      this._conflictsDocuments.isResolved &&
      this._discount.isResolved
    );
  }

  public get revisionsAccepted(): boolean {
    return (
      this._name.isAccepted &&
      this._description.isAccepted &&
      this._responseDueBy.isAccepted &&
      this._startDate.isAccepted &&
      this._endDate.isAccepted &&
      this._team.isAccepted &&
      this._feeSchedule.isAccepted &&
      this._clientPolicyDocuments.isAccepted &&
      this._vendorPolicyDocuments.isAccepted &&
      this._conflictsCheckWaived.isAccepted &&
      this._teamRestricted.isAccepted &&
      this._conflictsDocuments.isAccepted &&
      this._discount.isAccepted
    );
  }

  public get canBeUndone(): boolean {
    return (
      this._name.canBeUndone ||
      this._description.canBeUndone ||
      this._responseDueBy.canBeUndone ||
      this._startDate.canBeUndone ||
      this._endDate.canBeUndone ||
      this._team.canBeUndone ||
      this._feeSchedule.canBeUndone ||
      this._clientPolicyDocuments.canBeUndone ||
      this._vendorPolicyDocuments.canBeUndone ||
      this._conflictsCheckWaived.canBeUndone ||
      this._teamRestricted.canBeUndone ||
      this._conflictsDocuments.canBeUndone ||
      this._discount.canBeUndone
    );
  }

  public get allReviewableFields(): ProposalField[] {
    const fields: ProposalField[] = [];
    fields.push(ProposalField.Name);
    fields.push(ProposalField.Description);
    fields.push(ProposalField.ResponseDueBy);
    fields.push(ProposalField.StartDate);
    fields.push(ProposalField.EndDate);
    fields.push(ProposalField.TeamRestriction);
    for (const teamMemberRedline of this._team.redlines) {
      fields.push(teamMemberRedline.field);
    }
    for (const feeCategoryRedline of this._feeSchedule.redlines) {
      fields.push(feeCategoryRedline.field);
    }
    fields.push(ProposalField.WaiveConflictsCheck);
    for (const documentRedline of this._conflictsDocuments.redlines) {
      fields.push(documentRedline.field);
    }
    for (const documentRedline of this._clientPolicyDocuments.redlines) {
      fields.push(documentRedline.field);
    }
    for (const documentRedline of this._vendorPolicyDocuments.redlines) {
      fields.push(documentRedline.field);
    }
    fields.push(ProposalField.Discount);
    return fields;
  }

  public get fieldsPendingReview(): ProposalField[] {
    return this._fieldsPendingReview;
  }

  public clearSessionHistory() {
    this._name.clearSessionHistory();
    this._description.clearSessionHistory();
    this._responseDueBy.clearSessionHistory();
    this._startDate.clearSessionHistory();
    this._endDate.clearSessionHistory();
    this._team.clearSessionHistory();
    this._feeSchedule.clearSessionHistory();
    this._clientPolicyDocuments.clearSessionHistory();
    this._vendorPolicyDocuments.clearSessionHistory();
    this._conflictsCheckWaived.clearSessionHistory();
    this._teamRestricted.clearSessionHistory();
    this._conflictsDocuments.clearSessionHistory();
    this._discount.clearSessionHistory();
  }

  public accept() {
    this._name = this._name.accept();
    this._description = this._description.accept();
    this._responseDueBy = this._responseDueBy.accept();
    this._startDate = this._startDate.accept();
    this._endDate = this._endDate.accept();
    this._team = this._team.acceptAll();
    this._feeSchedule = this._feeSchedule.acceptAll();
    this._clientPolicyDocuments = this._clientPolicyDocuments.acceptAll();
    this._vendorPolicyDocuments = this._vendorPolicyDocuments.acceptAll();
    this._conflictsCheckWaived = this._conflictsCheckWaived.accept();
    this._teamRestricted = this._teamRestricted.accept();
    this._conflictsDocuments = this._conflictsDocuments.acceptAll();
    this._discount = this._discount.accept();
    this.registerFieldsPendingReview();
  }

  public reject() {
    this._name = this._name.reject();
    this._description = this._description.reject();
    this._responseDueBy = this._responseDueBy.reject();
    this._startDate = this._startDate.reject();
    this._endDate = this._endDate.reject();
    this._team = this._team.rejectAll();
    this._feeSchedule = this._feeSchedule.rejectAll();
    this._clientPolicyDocuments = this._clientPolicyDocuments.rejectAll();
    this._vendorPolicyDocuments = this._vendorPolicyDocuments.rejectAll();
    this._conflictsCheckWaived = this._conflictsCheckWaived.reject();
    this._teamRestricted = this._teamRestricted.reject();
    this._conflictsDocuments = this._conflictsDocuments.rejectAll();
    this._discount = this._discount.reject();
    this.registerFieldsPendingReview();
  }

  public undo() {
    this._name = this._name.undo();
    this._description = this._description.undo();
    this._responseDueBy = this._responseDueBy.undo();
    this._startDate = this._startDate.undo();
    this._endDate = this._endDate.undo();
    this._team = this._team.undoAll();
    this._feeSchedule = this._feeSchedule.undoAll();
    this._clientPolicyDocuments = this._clientPolicyDocuments.undoAll();
    this._vendorPolicyDocuments = this._vendorPolicyDocuments.undoAll();
    this._conflictsCheckWaived = this._conflictsCheckWaived.undo();
    this._teamRestricted = this._teamRestricted.undo();
    this._conflictsDocuments = this._conflictsDocuments.undoAll();
    this._discount = this._discount.undo();
    this.registerFieldsPendingReview();
  }

  public updateNameRedline(
    newNameRedline: FieldRedline<ProjectName>
  ): ProposalRedline {
    const newRedline = this.clone();
    newRedline._name = newNameRedline;
    newRedline.registerFieldsPendingReview();
    newRedline._lastFieldUpdated = ProposalField.Name;
    return newRedline;
  }

  public updateDescriptionRedline(
    newDescriptionRedline: FieldRedline<ProjectDescription>
  ): ProposalRedline {
    const newRedline = this.clone();
    newRedline._description = newDescriptionRedline;
    newRedline.registerFieldsPendingReview();
    newRedline._lastFieldUpdated = ProposalField.Description;
    return newRedline;
  }

  public updateResponseDueByRedline(
    newResponseDueByRedline: FieldRedline<Date>
  ): ProposalRedline {
    const newRedline = this.clone();
    newRedline._responseDueBy = newResponseDueByRedline;
    newRedline.registerFieldsPendingReview();
    newRedline._lastFieldUpdated = ProposalField.ResponseDueBy;
    return newRedline;
  }

  public updateStartDateRedline(
    newStartDateRedline: FieldRedline<Date>
  ): ProposalRedline {
    const newRedline = this.clone();
    newRedline._startDate = newStartDateRedline;
    newRedline.registerFieldsPendingReview();
    newRedline._lastFieldUpdated = ProposalField.StartDate;
    return newRedline;
  }

  public updateEndDateRedline(
    newEndDateRedline: FieldRedline<Date>
  ): ProposalRedline {
    const newRedline = this.clone();
    newRedline._endDate = newEndDateRedline;
    newRedline.registerFieldsPendingReview();
    newRedline._lastFieldUpdated = ProposalField.EndDate;
    return newRedline;
  }

  public updateTeamRestrictedRedline(
    newTeamRestrictedRedline: FieldRedline<AHBoolean>
  ): ProposalRedline {
    const newRedline = this.clone();
    newRedline._teamRestricted = newTeamRestrictedRedline;
    newRedline.registerFieldsPendingReview();
    newRedline._lastFieldUpdated = ProposalField.TeamRestriction;
    return newRedline;
  }

  public updateTeamMembersRedline(
    newTeamRedline: FieldRedlineArray<Individual>
  ): ProposalRedline {
    const newRedline = this.clone();
    newRedline._team = newTeamRedline;
    newRedline.registerFieldsPendingReview();
    if (newTeamRedline.lastEntryUpdated) {
      newRedline._lastFieldUpdated = ProposalField.TeamMember(newTeamRedline.lastEntryUpdated);
    }
    return newRedline;
  }

  public updateFeeScheduleRedline(
    newFeeScheduleRedline: FeeScheduleRedline
  ): ProposalRedline {
    const newRedline = this.clone();
    newRedline._feeSchedule = newFeeScheduleRedline;
    newRedline.registerFieldsPendingReview();
    if (newFeeScheduleRedline.lastEntryUpdated) {
      newRedline._lastFieldUpdated = ProposalField.FeeScheduleCategory(newFeeScheduleRedline.lastEntryUpdated);
    }
    return newRedline;
  }

  public updateConflictsCheckWaivedRedline(
    newConflictsCheckWaivedRedline: FieldRedline<AHBoolean>
  ): ProposalRedline {
    const newRedline = this.clone();
    newRedline._conflictsDocuments = newRedline._conflictsDocuments.removeAll();
    newRedline._conflictsCheckWaived = newConflictsCheckWaivedRedline;
    newRedline.registerFieldsPendingReview();
    newRedline._lastFieldUpdated = ProposalField.WaiveConflictsCheck;
    return newRedline;
  }

  public updateConflictsDocumentsRedline(
    newConflictsDocumentsRedline: FieldRedlineArray<WorkDocument>
  ): ProposalRedline {
    const newRedline = this.clone();
    newRedline._conflictsDocuments = newConflictsDocumentsRedline;
    newRedline.registerFieldsPendingReview();
    if (newConflictsDocumentsRedline.lastEntryUpdated) {
      newRedline._lastFieldUpdated = ProposalField.ConflictsDocument(newConflictsDocumentsRedline.lastEntryUpdated);
    }
    return newRedline;
  }

  public updateClientPolicyDocumentsRedline(
    newClientPolicyDocumentsRedline: FieldRedlineArray<WorkDocument>
  ): ProposalRedline {
    const newRedline = this.clone();
    newRedline._clientPolicyDocuments = newClientPolicyDocumentsRedline;
    newRedline.registerFieldsPendingReview();
    if (newClientPolicyDocumentsRedline.lastEntryUpdated) {
      newRedline._lastFieldUpdated =
        ProposalField.ClientPolicyDocument(newClientPolicyDocumentsRedline.lastEntryUpdated);
    }
    return newRedline;
  }

  public updateVendorPolicyDocumentsRedline(
    newVendorPolicyDocumentsRedline: FieldRedlineArray<WorkDocument>
  ): ProposalRedline {
    const newRedline = this.clone();
    newRedline._vendorPolicyDocuments = newVendorPolicyDocumentsRedline;
    newRedline.registerFieldsPendingReview();
    if (newVendorPolicyDocumentsRedline.lastEntryUpdated) {
      newRedline._lastFieldUpdated =
        ProposalField.VendorPolicyDocument(newVendorPolicyDocumentsRedline.lastEntryUpdated);
    }
    return newRedline;
  }

  public updateDiscountRedline(
    newDiscountRedline: FieldRedline<Percent>
  ): ProposalRedline {
    const newRedline = this.clone();
    newRedline._discount = newDiscountRedline;
    newRedline.registerFieldsPendingReview();
    newRedline._lastFieldUpdated = ProposalField.Discount;
    return newRedline;
  }

  public clone() {
    const newFeeScheduleRedline = this.feeSchedule.clone();
    return new ProposalRedline(
      this._name.clone(this._name.originalRedline),
      this._description.clone(this._description.originalRedline),
      {
        startDate: this._startDate.clone(this._startDate.originalRedline),
        endDate: this._endDate.clone(this._endDate.originalRedline),
        responseDueBy: this._responseDueBy.clone(this._responseDueBy.originalRedline),
      },
      this._team.clone(),
      newFeeScheduleRedline,
      this._conflictsCheckWaived.clone(),
      this._teamRestricted.clone(),
      {
        clientPolicyDocuments: this._clientPolicyDocuments.clone(),
        vendorPolicyDocuments: this._vendorPolicyDocuments.clone(),
        conflictsDocuments: this._conflictsDocuments.clone(),
      },
      this._discount.clone()
    );
  }

  public get previousFieldPendingReview(): ProposalField | undefined {
    const orderedReviewableFields = this.allReviewableFields.reverse();
    let startingIndex = this.getStartingIndexFromLastFieldUpdated(orderedReviewableFields);

    return this.getFirstFieldPendingReviewFromList(
      orderedReviewableFields,
      this.fieldsPendingReview,
      startingIndex
    );
  }

  public get nextFieldPendingReview(): ProposalField | undefined {
    const orderedReviewableFields = this.allReviewableFields;
    let startingIndex = this.getStartingIndexFromLastFieldUpdated(orderedReviewableFields);

    return this.getFirstFieldPendingReviewFromList(
      orderedReviewableFields,
      this.fieldsPendingReview,
      startingIndex
    );
  }

  public getPrecedingFieldPendingReview(
    fieldToStartSearchFrom: ProposalField
  ): ProposalField | undefined {
    const fieldsPendingReview = this.fieldsPendingReview;
    if (fieldsPendingReview.length < 2) return undefined;

    const allReviewableFields = this.allReviewableFields;
    const indexOfFieldToStartSearchFrom = allReviewableFields.findIndex(
      (field) =>
        field.name === fieldToStartSearchFrom.name &&
        (field.id ? field.id?.isEqualTo(fieldToStartSearchFrom.id) : true)
    );
    const orderedReviewableFields = [
      ...allReviewableFields.slice(
        0,
        indexOfFieldToStartSearchFrom
      ).reverse(),
      ...allReviewableFields.slice(indexOfFieldToStartSearchFrom).reverse(),
    ];

    return this.getFirstFieldPendingReviewFromList(
      orderedReviewableFields,
      fieldsPendingReview,
      0
    );
  }

  public getFollowingFieldPendingReview(
    fieldToStartSearchFrom: ProposalField
  ): ProposalField | undefined {
    const fieldsPendingReview = this.fieldsPendingReview;
    if (fieldsPendingReview.length < 1) return undefined;

    const allReviewableFields = this.allReviewableFields;
    const indexOfFieldToStartSearchFrom = allReviewableFields.findIndex(
      (field) =>
        field.name === fieldToStartSearchFrom.name &&
        (field.id ? field.id?.isEqualTo(fieldToStartSearchFrom.id) : true)
    );
    const orderedReviewableFields = [
      ...allReviewableFields.slice(indexOfFieldToStartSearchFrom),
      ...allReviewableFields.slice(
        0,
        indexOfFieldToStartSearchFrom
      ),
    ];
    return this.getFirstFieldPendingReviewFromList(
      orderedReviewableFields,
      fieldsPendingReview,
      1
    );
  }

  public isEqualTo(other?: ProposalRedline): boolean {
    if (!other) return false;
    return (
      this._name.isEqualTo(other.name) &&
      this._description.isEqualTo(other.description) &&
      this._responseDueBy.isEqualTo(other.responseDueBy) &&
      this._startDate.isEqualTo(other.startDate) &&
      this._endDate.isEqualTo(other.endDate) &&
      this._team.isEqualTo(other.team) &&
      this._feeSchedule.isEqualTo(other.feeSchedule) &&
      this._clientPolicyDocuments.isEqualTo(other.clientPolicyDocuments) &&
      this._vendorPolicyDocuments.isEqualTo(other.vendorPolicyDocuments) &&
      this._conflictsCheckWaived.isEqualTo(other.conflictsCheckWaived) &&
      this._teamRestricted.isEqualTo(other.teamRestricted) &&
      this._conflictsDocuments.isEqualTo(other.conflictsDocuments) &&
      this._discount.isEqualTo(other.discount)
    );
  }

  toJSON(): object {
    return {
      name: this._name.toJSON(),
      description: this._description.toJSON(),
      responseDueBy: this._responseDueBy.toJSON(),
      startDate: this._startDate.toJSON(),
      endDate: this._endDate.toJSON(),
      team: this._team.toJSON(),
      feeSchedule: this._feeSchedule.toJSON(),
      clientPolicyDocuments: this._clientPolicyDocuments.toJSON(),
      vendorPolicyDocuments: this._vendorPolicyDocuments.toJSON(),
      conflictsCheckWaived: this._conflictsCheckWaived.toJSON(),
      teamRestricted: this._teamRestricted.toJSON(),
      conflictsDocuments: this._conflictsDocuments.toJSON(),
      discount: this._discount.toJSON(),
    };
  }

  static fromJSON(redliningJson: any) {
    let redline = JSON.parse(redliningJson);
    const dates = {
      startDate: FieldRedline.fromJSON<Date>(
        new Date(),
        redline.startDate
      ),
      endDate: FieldRedline.fromJSON<Date>(
        new Date(),
        redline.endDate
      ),
      responseDueBy: FieldRedline.fromJSON<Date>(
        new Date(),
        redline.responseDueBy
      ),
    };
    const documents = {
      clientPolicyDocuments: FieldRedlineArray.fromArrayObject<WorkDocument>(
        WorkDocument.Prototype,
        ProposalField.ClientPolicies,
        ProposalField.ClientPolicyDocument,
        redline.clientPolicyDocuments
      ),
      vendorPolicyDocuments: FieldRedlineArray.fromArrayObject<WorkDocument>(
        WorkDocument.Prototype,
        ProposalField.VendorPolicies,
        ProposalField.VendorPolicyDocument,
        redline.vendorPolicyDocuments
      ),
      conflictsDocuments: FieldRedlineArray.fromArrayObject<WorkDocument>(
        WorkDocument.Prototype,
        ProposalField.Conflicts,
        ProposalField.ConflictsDocument,
        redline.conflictsDocuments
      ),
    };

    return new ProposalRedline(
      FieldRedline.fromJSON<ProjectName>(
        ProjectName.Prototype,
        redline.name
      ),
      FieldRedline.fromJSON<ProjectDescription>(
        ProjectDescription.Prototype,
        redline.description
      ),
      dates,
      FieldRedlineArray.fromArrayObject<Individual>(
        Individual.Prototype,
        ProposalField.Team,
        ProposalField.TeamMember,
        redline.team
      ),
      FeeScheduleRedline.fromArrayObject(redline.feeSchedule),
      FieldRedline.fromJSON<AHBoolean>(
        AHBoolean.Prototype,
        redline.conflictsCheckWaived
      ),
      FieldRedline.fromJSON<AHBoolean>(
        AHBoolean.Prototype,
        redline.teamRestricted
      ),
      documents,
      FieldRedline.fromJSON<Percent>(
        Percent.Prototype,
        redline.discount
      )
    );
  }

  private registerFieldsPendingReview() {
    const fields: ProposalField[] = [];

    if (this._name.wasRedlined && !this._name.isResolved)
      fields.push(ProposalField.Name);
    if (this._description.wasRedlined && !this._description.isResolved)
      fields.push(ProposalField.Description);
    if (this._responseDueBy.wasRedlined && !this._responseDueBy.isResolved)
      fields.push(ProposalField.ResponseDueBy);
    if (this._startDate.wasRedlined && !this._startDate.isResolved)
      fields.push(ProposalField.StartDate);
    if (this._endDate.wasRedlined && !this._endDate.isResolved)
      fields.push(ProposalField.EndDate);
    if (
      this._teamRestricted.wasRedlined &&
      !this._teamRestricted.isResolved
    ) {
      fields.push(ProposalField.TeamRestriction);
    }
    for (const teamMemberRedline of this._team.redlines) {
      if (teamMemberRedline.wasRedlined && !teamMemberRedline.isResolved) {
        fields.push(teamMemberRedline.field);
      }
    }
    for (const feeCategoryRedline of this._feeSchedule.redlines) {
      if (feeCategoryRedline.wasRedlined && !feeCategoryRedline.isResolved) {
        fields.push(feeCategoryRedline.field);
      }
    }
    if (
      this._conflictsCheckWaived.wasRedlined &&
      !this._conflictsCheckWaived.isResolved
    ) {
      fields.push(ProposalField.WaiveConflictsCheck);
    }
    for (const documentRedline of this._conflictsDocuments.redlines) {
      if (documentRedline.wasRedlined && !documentRedline.isResolved) {
        fields.push(documentRedline.field);
      }

    }
    for (const documentRedline of this._clientPolicyDocuments.redlines) {
      if (documentRedline.wasRedlined && !documentRedline.isResolved) {
        fields.push(documentRedline.field);
      }
    }
    for (const documentRedline of this._vendorPolicyDocuments.redlines) {
      if (documentRedline.wasRedlined && !documentRedline.isResolved) {
        fields.push(documentRedline.field);
      }

    }
    if (this._discount.wasRedlined && !this._discount.isResolved)
      fields.push(ProposalField.Discount);

    this._fieldsPendingReview = fields;
  }

  private getFirstFieldPendingReviewFromList(
    orderedReviewableFieldsList: ProposalField[],
    fieldsPendingReview: ProposalField[],
    startingIndex: number
  ) {
    let nextIndexToCheck: number = startingIndex;
    do {
      const nextField = fieldsPendingReview.find(
        (field) =>
          field.name === orderedReviewableFieldsList[nextIndexToCheck].name &&
          (field.id
            ? field.id?.isEqualTo(orderedReviewableFieldsList[nextIndexToCheck].id)
            : true)
      );

      if (nextField !== undefined) return nextField;

      nextIndexToCheck++;
      if (nextIndexToCheck > orderedReviewableFieldsList.length - 1) {
        nextIndexToCheck = 0;
      }
    } while (nextIndexToCheck !== startingIndex);
  }

  private getStartingIndexFromLastFieldUpdated(orderedReviewableFields: ProposalField[]) {
    let lastFieldUpdatedNameIndex = 0;
    if (this._lastFieldUpdated) {
      lastFieldUpdatedNameIndex = orderedReviewableFields.findIndex(
        (field) => field.name === this._lastFieldUpdated?.name &&
          (field.id ? field.id?.isEqualTo(this._lastFieldUpdated?.id) : true)
      );
      if (lastFieldUpdatedNameIndex === -1) {
        console.warn(
          `Last updated field not found in list of all reviewable fields - 
          name: ${this._lastFieldUpdated.name}, id: ${this._lastFieldUpdated.id}`,
          this
        );
        return 0;
      }
    }
    let startingIndex = lastFieldUpdatedNameIndex + 1;
    if (startingIndex > orderedReviewableFields.length - 1) {
      startingIndex = 0;
    }
    return startingIndex;
  }
}

export enum ResolutionType {
  Approved,
  Rejected,
  Edited,
  Unchanged,
}

export type RedlinedDocuments = {
  clientPolicyDocuments: FieldRedlineArray<WorkDocument>;
  vendorPolicyDocuments: FieldRedlineArray<WorkDocument>;
  conflictsDocuments: FieldRedlineArray<WorkDocument>;
};

export type RedlinedDates = {
  startDate: FieldRedline<Date>;
  endDate: FieldRedline<Date>;
  responseDueBy: FieldRedline<Date>;
};
