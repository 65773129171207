import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import CommentIcon from "@mui/icons-material/Comment";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import RateReviewIcon from "@mui/icons-material/RateReview";
import SaveIcon from "@mui/icons-material/Save";
import SendIcon from "@mui/icons-material/Send";
import {
  Badge,
  Button,
  ButtonGroup,
  Drawer,
  IconButton,
  Portal,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import {styled} from "@mui/material/styles";
import {ConfirmResponse, useConfirmDialog,} from "app/providers/confirm-dialog";
import {useDialog} from "app/providers/dialog";
import {CanceledError} from "axios";
import LoadingButton from "common/components/loading-button";
import {AccountType} from "common/values/account-type/account-type";
import Guid from "common/values/guid/guid";
import Individual from "marketplace/entities/individual/individual";
import MarketplaceTeamAPIService from "marketplace/entities/marketplace-team/api/marketplace-team-api-service";
import {enqueueSnackbar} from "notistack";
import React, {useEffect} from "react";
import {useSession} from "users/session/session-context";
import EntityClientRepresentative from "work/entities/entity-client-representative/entity-client-representative";
import ProposalAPIService, {ProposalUpdateError,} from "work/entities/proposal/api/proposal-api-service";
import ConflictsTab from "work/entities/proposal/draft/view/tabs/conflicts-tab";
import DetailsTab from "work/entities/proposal/draft/view/tabs/details-tab";
import DiscountTab from "work/entities/proposal/draft/view/tabs/discount-tab";
import FeeScheduleTab from "work/entities/proposal/draft/view/tabs/fee-schedule-tab";
import PoliciesTab from "work/entities/proposal/draft/view/tabs/policies-tab";
import TeamTab from "work/entities/proposal/draft/view/tabs/team-tab";
import Proposal, {ProposalField, ProposalFieldCategory,} from "work/entities/proposal/proposal";

import {useAttorneyHubDispatch} from "app/realtime-store/redux-store";
import IndividualProfile from "marketplace/values/individual-profile/individual-profile";
import {getCommentThreadsByProposal} from "work/entities/comment-thread/store/comment-thread-redux-slice";
import AutoCommentGenerator from "work/entities/comment/auto-comment-generator";
import {
  getArePendingComments,
  savePendingComments,
  updateAutoGeneratedComments,
} from "work/entities/comment/store/comments-redux-slice";
import Comments from "work/entities/comment/view/comments";
import {
  addProposal,
  addProposalBuilder,
  getProposalBuilderByProposalId,
  getProposalById, removeBuilderByProposalId,
  removeProposal,
  replaceProposalBuilder,
} from "work/entities/proposal/store/proposals-redux-slice";
import ProposalBuilder from "work/entities/proposal/utils/proposal-builder";
import {HumanReadableProposalFieldName} from "work/values/constants";
import ProjectName from "work/values/project-name/project-name";
import ProposalIssues from "work/values/proposal-issues/proposal-issues";
import ProposalIssuesBadge from "work/values/proposal-issues/view/proposal-issues-badge";
import ProposalReviewer from "work/values/proposal-reviewer";
import SelectedRepresentative from "work/values/selected-representative/selected-representative";
import SelectedTeam from "work/values/selected-team/selected-team";
import DetailedTeam from "work/values/team/detailed-team";
import ReviewerSelector from "work/view/components/reviewer-selector";

const Header = styled("section")(
  ({theme}) => ({
    backgroundColor: theme.palette.background.default,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingBottom: theme.spacing(1),
    position: "sticky",
    top: "0px",
    zIndex: 10,
  }));
const TabsContainer = styled(Tabs)(
  ({theme}) => ({
    "&.MuiTabs-root": {
      overflow: "hidden",
      "& .MuiTabs-scroller": {
        paddingTop: theme.spacing(0.5),
      },
    },
  }));
const Content = styled("section")(
  ({theme}) => ({
    display: "flex",
    flex: 1,
    flexDirection: "row",
  }));
const TabContent = styled("section")(
  ({theme}) => ({
    alignItems: "stretch",
    flexDirection: "column",
    display: "flex",
    flex: 1,
    margin: theme.spacing(
      2,
      0
    ),
    overflow: "visible",
    width: "100%",
  }));
const ActionsContainer = styled("section")(
  ({theme}) => ({
    [theme.breakpoints.down("md")]: {
      paddingBottom: theme.spacing(1),
      paddingTop: theme.spacing(1),
    },
    backgroundColor: theme.palette.background.default,
    bottom: "0px",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    paddingBottom: theme.spacing(2.5),
    position: "sticky",
    width: "100%",
    zIndex: 10,
  }));
const SecondaryActions = styled("section")(
  ({theme}) => ({
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(2),
    width: "100%",
  }));
const ProposalActions = styled("section")(
  ({theme}) => ({
    [theme.breakpoints.down("md")]: {
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
      flexWrap: "nowrap",
    },
    alignContent: "end",
    alignItems: "center",
    display: "grid",
    flex: 1,
    gridTemplateColumns: "repeat(auto-fit, minmax(15rem, 1fr))",
    gap: theme.spacing(1),
    minHeight: "64px",
  }));
const NavButtons = styled(ButtonGroup)(
  ({theme}) => ({
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
    width: "initial",
    "& > Button": {
      width: "100%",
    },
  }));
const ProposalActionSpan = styled("span")(
  ({theme}) => ({
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
    minWidth: theme.spacing(24),
  }));
const ProposalActionButton = styled(LoadingButton)(
  ({theme}) => ({
    width: "100%",
    whiteSpace: "nowrap",
    "&.Mui-disabled": {
      color: "rgba(0, 0, 0, 0.26) !important",
      backgroundColor: "rgba(0, 0, 0, 0.12) !important",
    },
  }));
const ReviewerBadge = styled(Badge)(
  ({theme}) => ({
    marginLeft: '1rem'
  }));
const DialogTab = styled(Tab)(
  ({theme}) => ({
    "&.MuiTab-root": {
      overflow: "visible",
    },
  }));
const MessageButtons = styled("section")(
  ({theme}) => ({
    alignContent: "center",
    marginLeft: theme.spacing(2),
  }));
const ButtonContainer = styled("div")(
  ({theme}) => ({
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
  }));
const MessageButton = styled(IconButton)(
  ({theme}) => ({
    paddingBottom: 0,
  }));
const MessageButtonLabel = styled(Typography)(
  ({theme}) => ({
    fontSize: "0.6em",
  }));
const SidePanel = styled(Drawer)(
  ({theme}) => ({
    position: "fixed",
    zIndex: theme.zIndex.modal + 1,
  }));
const SidePanelContainer = styled("div")(
  ({theme}) => ({
    flex: 1,
    height: "100%",
  }));
const SidePanelContent = styled("div")(
  ({theme}) => ({
    display: "flex",
    flexDirection: "column",
    height: "100%",
    padding: theme.spacing(2),
    width: "400px",
  }));
const TitleBar = styled("div")(
  ({theme}) => ({
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    paddingBottom: theme.spacing(1),
  }));

type ProposalDraftDialogProps = {
  proposalId?: Guid;
  disableEditing?: boolean;
  preSelectedRep?: SelectedRepresentative;
  preSelectedTeam?: SelectedTeam;
  isDirtyRef: React.MutableRefObject<boolean>;
  navigate: (url: string) => void;
  onProposalSaved?: (newProposal?: Proposal) => void;
  onProposalSubmitted?: (submittedProposal: Proposal) => void;
};

export default function ProposalDraftDialog(
  props: Readonly<ProposalDraftDialogProps>
) {
  const {
    proposalId,
    disableEditing,
    preSelectedTeam,
    preSelectedRep,
    isDirtyRef,
    navigate,
    onProposalSaved,
    onProposalSubmitted,
  } = props;
  const session = useSession();
  const {closeAllDialogs} = useDialog();
  const confirm = useConfirmDialog();

  const [activeTab, setActiveTab] = React.useState<ProposalFieldCategory>(
    ProposalFieldCategory.Details
  );
  const [currentCommentField, setCurrentCommentField] = React.useState<
    ProposalField | undefined
  >();
  const [sidePanelTitle, setSidePanelTitle] = React.useState<
    string | undefined
  >();
  const [isSaving, setIsSaving] = React.useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
  const [reviewerSelectorAnchor, setReviewerSelectorAnchor] =
    React.useState<HTMLButtonElement>();
  const [isManagingReviewers, setIsManagingReviewers] =
    React.useState<boolean>(false);
  const [isApprovingReview, setIsApprovingReview] =
    React.useState<boolean>(false);
  const [isSidePanelOpen, setIsSidePanelOpen] = React.useState<boolean>(false);
  const [isSelectingClient, setIsSelectingClient] =
    React.useState<boolean>(false);

  const proposal = getProposalById(proposalId);

  const proposalBuilder = getProposalBuilderByProposalId(
    proposalId ?? Guid.empty
  );
  const issues = ProposalIssues?.fromBuilder(proposalBuilder);

  const commentThreads = getCommentThreadsByProposal(proposal);
  const arePendingComments = getArePendingComments(proposal);

  const dispatch = useAttorneyHubDispatch();

  isDirtyRef.current = proposalBuilder?.isModified ?? false;
  const disableCommenting = !proposal?.id;
  let isReviewing = false;
  if (session.context?.viewingAsVendor) {
    isReviewing =
      proposal?.vendorReviewers.some((reviewer) =>
        reviewer.userId.isEqualTo(session.user?.id)
      ) ?? false;
  } else {
    isReviewing =
      proposal?.clientReviewers.some((reviewer) =>
        reviewer.userId.isEqualTo(session.user?.id)
      ) ?? false;
  }

  useEffect(
    () => {
      const abortController = new AbortController();
      if (!proposalBuilder) {
        initProposalBuilder(abortController).then((newProposalBuilder) => {
          dispatch(
            addProposalBuilder({
              proposalId: proposalId ?? Guid.empty,
              builder: newProposalBuilder,
            })
          );
        });
      }

      return () => {
        abortController.abort();
      };
    },
    []
  );

  useEffect(
    () => {
      if (!proposalBuilder) return;
      generateChangeAutoComments(proposalBuilder);
    },
    [proposalBuilder]
  );

  async function initProposalBuilder(
    abortController?: AbortController
  ): Promise<ProposalBuilder> {
    if (!session.user?.id) {
      throw new Error("No user to initialize proposal builder");
    }
    let newProposalBuilder = new ProposalBuilder(
      session.user,
      proposal?.spec
    );

    if(preSelectedRep) {
        //if target rep is a client only
        if (preSelectedRep.isClientRep && !preSelectedRep.isVendorRep) {
            newProposalBuilder = handlePreselectedClient(
                newProposalBuilder,
                preSelectedRep
            );
        } else if (preSelectedRep.isVendorRep) {
            newProposalBuilder = handlePreselectedTeamLeader(
                newProposalBuilder,
                preSelectedRep
            );
        }
    } else if (!session.context?.viewingAsVendor) {
      newProposalBuilder = newProposalBuilder.setClient(
        new EntityClientRepresentative(
          session.user.id,
          session.currentEntity.id,
          session.user.name
        )
      );
    } else if (preSelectedTeam) {
      try {
        newProposalBuilder = await handlePreselectedTeam(
          newProposalBuilder,
          preSelectedTeam,
          abortController
        );
      } catch (error: any) {
        if (error instanceof CanceledError) return newProposalBuilder;
        console.error(error);
      }
    }

    return newProposalBuilder;
  }

  useEffect(
    () => {
      if (!session?.canSwitchContext || !proposal) {
        return;
      }

      if (
        session.context?.viewingAsVendor &&
        proposal.client?.userId.isEqualTo(session.user?.id)
      ) {
        session.setAccountViewType(AccountType.Client);
      } else if (
        proposal.team?.leader?.userId.isEqualTo(session.user?.id) ||
        proposal.team?.memberUserIds?.some((member) =>
          member.isEqualTo(session.user?.id)
        )
      ) {
        session.setAccountViewType(AccountType.Vendor);
      }
    },
    [proposal]
  );

  function handlePreselectedClient(
    builder: ProposalBuilder,
    client: SelectedRepresentative
  ): ProposalBuilder {
    const clientRep = new EntityClientRepresentative(
      client.userId,
      client.entityId,
      client.name
    );
    return builder.setClient(clientRep);
  }

  function handlePreselectedTeamLeader(
    builder: ProposalBuilder,
    leader: SelectedRepresentative
  ): ProposalBuilder {
    const leaderIndividual = new Individual(
      leader.userId,
      leader.entityId,
      new IndividualProfile(
        leader.userId,
        leader.name.firstName ?? "",
        leader.name.lastName ?? ""
      )
    );
    return builder
      .setName(new ProjectName("New Proposal"))
      .setTeam(new DetailedTeam(
        leaderIndividual,
        []
      ));
  }

  async function handlePreselectedTeam(
    proposalBuilder: ProposalBuilder,
    preSelectedTeam: SelectedTeam,
    abortController?: AbortController
  ): Promise<ProposalBuilder> {
    const marketplaceTeamAPIService = new MarketplaceTeamAPIService(session);
    const team = await marketplaceTeamAPIService.getTeamById(
      preSelectedTeam.targetTeamId,
      abortController
    );

    return proposalBuilder
      .setName(new ProjectName("New Proposal"))
      .setTeam(team.toDetailedTeam());
  }

  async function saveProposal(): Promise<Proposal> {
    let draftProposal: Proposal | undefined;
    if (!proposalBuilder) {
      throw new Error("No proposal builder to save");
    }

    setIsSaving(true);

    try {
      if (!proposal?.id) {
        draftProposal = proposalBuilder.buildDraft(session);
        draftProposal = await draftProposal?.save(session);
        dispatch(addProposal(draftProposal));
      } else {
        draftProposal = proposalBuilder.updateProposal(
          proposal,
          session
        );
        await draftProposal.save(session);
      }
    } catch (error: any) {
      if (error instanceof ProposalUpdateError) {
        enqueueSnackbar(
          error.message,
          {variant: "error"}
        );
      } else {
        enqueueSnackbar(
          "Failed to save draft",
          {variant: "error"}
        );
      }
      console.error(
        "Failed to save proposal draft: ",
        error
      );
    } finally {
      setIsSaving(false);
      if (proposal?.id) {
        const updatedBuilder = proposalBuilder.resetHistory();
        dispatch(replaceProposalBuilder({proposalId: proposal.id, builder: updatedBuilder}));
      }
      dispatch(removeBuilderByProposalId(Guid.empty));
    }
    if (!draftProposal) {
      throw new Error("Failed to save draft proposal");
    }
    return draftProposal;
  }

  function handleTabChange(
    _event: React.ChangeEvent<{}> | null,
    newTab: ProposalFieldCategory
  ) {
    setActiveTab(newTab);
  }

  async function handlePrevNextClicked(direction: "previous" | "next") {
    const tabKeys = Object.keys(ProposalFieldCategory);
    const activeTabIndex = tabKeys.indexOf(activeTab);
    const targetTabKey =
      direction === "previous"
        ? tabKeys[activeTabIndex - 1]
        : tabKeys[activeTabIndex + 1];
    handleTabChange(
      null,
      ProposalFieldCategory[
        targetTabKey as keyof typeof ProposalFieldCategory
        ] ?? activeTab
    );
  }

  async function handleSave(): Promise<void> {
    try {
      dispatch(savePendingComments(session));
      if (proposal?.isArchived) {
        return;
      }
      const isNewProposal = !proposal?.id;

      if (isNewProposal) {
        const newProposal = await saveProposal();
        onProposalSaved?.(newProposal);
      } else {
        await saveProposal();
        onProposalSaved?.();
      }
    } catch (error: any) {
      console.error(error);
      enqueueSnackbar(
        "Failed to save draft",
        {variant: "error"}
      );
    }
  }

  function handleManageReviewersClicked(
    event: React.MouseEvent<HTMLButtonElement>
  ) {
    setReviewerSelectorAnchor(event.currentTarget);
    setIsManagingReviewers(true);
  }

  async function handleSubmitClicked() {
    if (proposal?.isArchived) {
      return;
    }
    try {
      let message: string = "";
      const clientReviewers = proposalBuilder?.currentSpec.clientReviewers ?? proposal?.clientReviewers ?? [];
      const vendorReviewers = proposalBuilder?.currentSpec.vendorReviewers ?? proposal?.vendorReviewers ?? [];
      if ((
        session.context?.viewingAsVendor &&
        vendorReviewers.length &&
        vendorReviewers.length !== vendorReviewers.filter(reviewer => reviewer.dateApproved).length
      ) || (
        !session.context?.viewingAsVendor &&
        clientReviewers.length &&
        clientReviewers.length !== clientReviewers.filter(reviewer => reviewer.dateApproved).length
      )) {
        message = "Not all reviewers have approved the proposal. Do you still want to submit the proposal?";
      } else {
        message = "Submit the proposal now?";
      }

      const response = await confirm({
        title: "Submit Proposal",
        message: message,
        okButtonText: "Submit",
      });

      if (response === ConfirmResponse.Cancel) return;

      setIsSubmitting(true);

      const draftProposal = await saveProposal();
      dispatch(savePendingComments(session));
      const submittedProposal = await draftProposal.submit(session.user?.id);
      dispatch(addProposal(submittedProposal));

      onProposalSubmitted?.(submittedProposal);
    } catch (error: any) {
      console.error(error);
      enqueueSnackbar(
        "Failed to submit proposal",
        {variant: "error"}
      );
    } finally {
      setIsSubmitting(false);
    }
  }

  function shouldDisableTabs(): boolean {
    return isSaving || isManagingReviewers || isSelectingClient;
  }

  async function handleDeleteClicked() {
    if (proposal?.isArchived) {
      return;
    }
    if (!proposal?.id) {
      console.warn("Proposal not found");
      return;
    }

    try {
      const rejectResponse = await confirm({
        title: "Delete Draft Proposal?",
        message: "This cannot be undone.",
      });

      if (rejectResponse === ConfirmResponse.Cancel) return;

      setIsSubmitting(true);
      navigate(`/proposals/drafts/`);
      await proposal.delete(session.user?.id);
      dispatch(removeProposal(proposal.id));
      closeAllDialogs();
    } catch (error: any) {
      console.error(error);
      enqueueSnackbar(
        "Failed to cancel proposal",
        {variant: "error"}
      );
    } finally {
      setIsSubmitting(false);
    }
  }

  async function handleProposalBuilderUpdated(
    updatedProposalBuilder: ProposalBuilder
  ) {
    if (proposal?.isArchived) {
      return;
    }
    dispatch(
      replaceProposalBuilder({
        proposalId: proposal?.id ?? Guid.empty,
        builder: updatedProposalBuilder,
      })
    );

    isDirtyRef.current = updatedProposalBuilder.isModified;
  }

  async function generateChangeAutoComments(proposalBuilder: ProposalBuilder) {
    if (proposal?.isArchived) {
      return;
    }
    if (!proposal?.id) return;
    const autoComments = new AutoCommentGenerator(
      session,
      proposal,
      proposalBuilder,
      commentThreads ?? []
    ).generateAutoCommentsFromBuilder();
    dispatch(updateAutoGeneratedComments(autoComments));
  }

  function handleCloseCommentsPanel() {
    setIsSidePanelOpen(false);
    adjustDialogPosition(true);
  }

  async function handleOpenCommentsPanel(
    field: ProposalField,
    sidePanelTitle?: string
  ) {
    if (disableCommenting) return;
    setCurrentCommentField(field);
    setSidePanelTitle(sidePanelTitle);
    setIsSidePanelOpen(true);
    adjustDialogPosition(false);
  }

  function adjustDialogPosition(panelOpen: boolean) {
    for (const dialog of document.getElementsByClassName("MuiDialog-root")) {
      dialog.setAttribute(
        "style",
        `padding-right: ${
          panelOpen ? "0px" : "400px"
        }; transition: padding-right 225ms;`
      );
    }
  }

  async function handleApproveReviewClicked() {
    if (!proposal) throw new Error("No proposal to approve");

    try {
      const response = await confirm({
        title: "Approve Review",
        message: "Are you sure you want to approve this review?",
        okButtonText: "Approve",
      });

      if (response === ConfirmResponse.Cancel) return;

      setIsApprovingReview(true);
      const proposalService = new ProposalAPIService(session);
      await proposalService.giveReviewerApproval(proposal);
    } catch (error: any) {
      console.error(error);
      enqueueSnackbar(
        "Failed to approve review",
        {variant: "error"}
      );
    } finally {
      setIsApprovingReview(false);
    }
  }

  async function handleProposalReviewersChanged(reviewers: ProposalReviewer[]) {
    if (!proposalBuilder) {
      throw new Error("No proposal builder to update");
    }

    let updatedProposalBuilder = proposalBuilder;
    if (session.context?.viewingAsVendor) {
      updatedProposalBuilder =
        updatedProposalBuilder.setVendorReviewers(reviewers);
    } else {
      updatedProposalBuilder =
        updatedProposalBuilder.setClientReviewers(reviewers);
    }
    handleProposalBuilderUpdated(updatedProposalBuilder);
  }

  function hasUserApprovedReview(): boolean {
    if (!proposalBuilder) {
      throw new Error("No proposal builder to check");
    }

    let builderReviewers: ProposalReviewer[] = [];
    let proposalReviewers: ProposalReviewer[] = [];
    let reviewers: ProposalReviewer[] = [];

    if (session.context?.viewingAsVendor) {
      builderReviewers = proposalBuilder.currentSpec.vendorReviewers ?? [];
      proposalReviewers = proposal?.vendorReviewers ?? [];
    } else {
      builderReviewers = proposalBuilder.currentSpec.clientReviewers ?? [];
      proposalReviewers = proposal?.clientReviewers ?? [];
    }

    reviewers = builderReviewers.concat(proposalReviewers);

    return reviewers.some(
      (reviewer) =>
        reviewer.userId.isEqualTo(session.user?.id) && reviewer.dateApproved
    );
  }

  function getSidePanelTitle(): string {
    if (sidePanelTitle) return `${sidePanelTitle} Comments`;
    return `${
      HumanReadableProposalFieldName[
      currentCommentField?.name ?? ProposalField.General.name
        ]
    } Comments`;
  }

  function renderComments() {
    if (proposal?.id && commentThreads) {
      return (
        <Comments
          proposal={proposal}
          field={currentCommentField ?? ProposalField.General}
          commentThreads={commentThreads}
          isSaving={isSaving}
        />
      );
    }
  }

  const manageReviewersDisabled = !proposalBuilder ||
    !commentThreads ||
    isSaving ||
    isSelectingClient ||
    isSelectingClient ||
    isManagingReviewers ||
    reviewerSelectorAnchor !== undefined ||
    !proposal?.id ||
    disableEditing ||
    isReviewing;
  const reviewers = (session.context?.viewingAsVendor ? proposalBuilder?.currentSpec.vendorReviewers
    : proposalBuilder?.currentSpec.clientReviewers) ?? [];
  const totalApprovals = reviewers.filter((reviewer) => reviewer.dateApproved).length;
  const totalReviewers = reviewers.length ?? 0;

  return (
    proposalBuilder && (
      <>
        <Header>
          <TabsContainer
            variant="scrollable"
            scrollButtons="auto"
            indicatorColor="primary"
            textColor="primary"
            value={activeTab}
            onChange={handleTabChange}
          >
            <DialogTab
              value={ProposalFieldCategory.Details}
              disabled={shouldDisableTabs()}
              label={
                <ProposalIssuesBadge
                  issues={issues}
                  category={ProposalFieldCategory.Details}
                />
              }
            />
            <DialogTab
              value={ProposalFieldCategory.Team}
              disabled={shouldDisableTabs()}
              label={
                <ProposalIssuesBadge
                  issues={issues}
                  category={ProposalFieldCategory.Team}
                />
              }
            />
            <DialogTab
              value={ProposalFieldCategory.FeeSchedule}
              disabled={shouldDisableTabs()}
              label={
                <ProposalIssuesBadge
                  issues={issues}
                  category={ProposalFieldCategory.FeeSchedule}
                />
              }
            />
            <DialogTab
              value={ProposalFieldCategory.Conflicts}
              disabled={shouldDisableTabs()}
              label={
                <ProposalIssuesBadge
                  issues={issues}
                  category={ProposalFieldCategory.Conflicts}
                />
              }
            />
            <DialogTab
              value={ProposalFieldCategory.Policies}
              disabled={shouldDisableTabs()}
              label={
                <ProposalIssuesBadge
                  issues={issues}
                  category={ProposalFieldCategory.Policies}
                />
              }
            />
            <DialogTab
              value={ProposalFieldCategory.Discount}
              disabled={shouldDisableTabs()}
              label={
                <ProposalIssuesBadge
                  issues={issues}
                  category={ProposalFieldCategory.Discount}
                />
              }
            />
          </TabsContainer>
        </Header>
        <Content>
          <TabContent>
            {proposalBuilder && (
              <>
                <DetailsTab
                  commentThreads={commentThreads ?? []}
                  issues={issues}
                  activeTab={activeTab}
                  proposalBuilder={proposalBuilder}
                  disableCommenting={!proposal?.id}
                  disableEditing={disableEditing}
                  disableValidate={proposal?.isArchived}
                  onSelectingClientChange={(isSelecting: boolean) =>
                    setIsSelectingClient(isSelecting)
                  }
                  onProposalBuilderUpdated={handleProposalBuilderUpdated}
                  onCommentsClicked={handleOpenCommentsPanel}
                />
                <TeamTab
                  entityId={session.currentEntity.entityId}
                  proposalBuilder={proposalBuilder}
                  issues={issues}
                  disableCommenting={!proposal?.id}
                  disableEditing={disableEditing}
                  activeTab={activeTab}
                  commentThreads={commentThreads ?? []}
                  onProposalBuilderUpdated={handleProposalBuilderUpdated}
                  onCommentsClicked={handleOpenCommentsPanel}
                />
                <FeeScheduleTab
                  entityId={session.currentEntity.entityId}
                  proposalBuilder={proposalBuilder}
                  issues={issues}
                  disableCommenting={!proposal?.id}
                  commentThreads={commentThreads}
                  disableEditing={disableEditing}
                  activeTab={activeTab}
                  onProposalBuilderUpdated={handleProposalBuilderUpdated}
                  onCommentsClicked={handleOpenCommentsPanel}
                />
                <ConflictsTab
                  proposalBuilder={proposalBuilder}
                  issues={issues}
                  disableCommenting={!proposal?.id}
                  commentThreads={commentThreads}
                  disableEditing={disableEditing}
                  activeTab={activeTab}
                  onProposalBuilderUpdated={handleProposalBuilderUpdated}
                  onCommentsClicked={handleOpenCommentsPanel}
                />
                <PoliciesTab
                  proposalBuilder={proposalBuilder}
                  issues={issues}
                  disableCommenting={!proposal?.id}
                  commentThreads={commentThreads}
                  disableEditing={disableEditing}
                  activeTab={activeTab}
                  onProposalBuilderUpdated={handleProposalBuilderUpdated}
                  onCommentsClicked={handleOpenCommentsPanel}
                />
                <DiscountTab
                  proposalBuilder={proposalBuilder}
                  issues={issues}
                  disableCommenting={!proposal?.id}
                  disableEditing={disableEditing}
                  activeTab={activeTab}
                  commentThreads={commentThreads ?? []}
                  onProposalBuilderUpdated={handleProposalBuilderUpdated}
                  onCommentsClicked={handleOpenCommentsPanel}
                />
              </>
            )}
          </TabContent>
        </Content>
        <ActionsContainer>
          <SecondaryActions>
            <NavButtons
              disabled={isSaving || isSelectingClient || isManagingReviewers}
            >
              <Button
                startIcon={<NavigateBeforeIcon/>}
                disabled={
                  shouldDisableTabs() ||
                  activeTab === ProposalFieldCategory.Details
                }
                onClick={async () => await handlePrevNextClicked("previous")}
              >
                prev
              </Button>
              <Button
                endIcon={<NavigateNextIcon/>}
                disabled={
                  shouldDisableTabs() ||
                  activeTab === ProposalFieldCategory.Discount
                }
                onClick={async () => await handlePrevNextClicked("next")}
              >
                next
              </Button>
            </NavButtons>
          </SecondaryActions>

          <ProposalActions>
            {!(disableEditing && !arePendingComments) && (
              <Tooltip
                title={
                  !issues?.canSave ? "Fill out required fields to save." : ""
                }
              >
                <ProposalActionSpan>
                  <ProposalActionButton
                    variant="contained"
                    color="primary"
                    startIcon={<SaveIcon/>}
                    loading={isSaving}
                    disabled={
                      !proposalBuilder ||
                      isSaving ||
                      isSelectingClient ||
                      isManagingReviewers ||
                      (!proposalBuilder.isModified && !arePendingComments) ||
                      (issues && !issues.canSave)
                    }
                    onClick={handleSave}
                  >
                    Save
                  </ProposalActionButton>
                </ProposalActionSpan>
              </Tooltip>
            )}
            {isReviewing && (
              <ProposalActionButton
                variant="contained"
                color="success"
                startIcon={<CheckIcon/>}
                loading={isSaving}
                disabled={
                  !proposalBuilder ||
                  !commentThreads ||
                  isSaving ||
                  isApprovingReview ||
                  isSelectingClient ||
                  isManagingReviewers ||
                  !issues?.canSubmit ||
                  hasUserApprovedReview()
                }
                onClick={handleApproveReviewClicked}
              >
                {hasUserApprovedReview() ? "Approved" : "Approve"}
              </ProposalActionButton>
            )}
            {!disableEditing && (
              <>
                <Tooltip
                  title={!proposal?.id ? "Save proposal to add reviewers." : ""}
                >
                  <ProposalActionSpan>
                    <ProposalActionButton
                      variant="contained"
                      color="primary"
                      startIcon={
                        <RateReviewIcon/>
                      }
                      endIcon={
                        <ReviewerBadge
                          invisible={manageReviewersDisabled || !reviewers.length}
                          badgeContent={
                            <Tooltip
                              title={totalApprovals === totalReviewers ? "All reviewers approved"
                                : "Awaiting additional approvals"}>
                          <span>
                            <sup>{totalApprovals}</sup>&frasl;<sub>{totalReviewers}</sub>
                          </span>
                            </Tooltip>
                          }
                          color={totalReviewers === totalApprovals ? "success" : "error"}
                        />
                      }
                      loading={false}
                      disabled={manageReviewersDisabled}
                      onClick={handleManageReviewersClicked}
                    >
                      Manage Reviewers
                    </ProposalActionButton>
                  </ProposalActionSpan>
                </Tooltip>
                <ReviewerSelector
                  proposalBuilder={proposalBuilder}
                  popoverAnchor={reviewerSelectorAnchor}
                  onPopoverClose={() => {
                    setReviewerSelectorAnchor(undefined);
                    setIsManagingReviewers(false);
                  }}
                  onChange={handleProposalReviewersChanged}
                />
                {!disableEditing && (
                  <Tooltip
                    title={
                      !issues?.canSubmit
                        ? "Fill out required fields to submit."
                        : "Submit proposal to other party"
                    }
                  >
                    <ProposalActionSpan>
                      <ProposalActionButton
                        variant="contained"
                        color="primary"
                        startIcon={<SendIcon/>}
                        loading={isSubmitting}
                        disabled={
                          !proposalBuilder ||
                          isSaving ||
                          isSelectingClient ||
                          isManagingReviewers ||
                          !issues?.canSubmit ||
                          disableEditing ||
                          isReviewing
                        }
                        onClick={handleSubmitClicked}
                      >
                        Submit
                      </ProposalActionButton>
                    </ProposalActionSpan>
                  </Tooltip>
                )}
                {proposal?.creator?.userId.isEqualTo(session.user?.id) && (
                  <ProposalActionButton
                    variant="contained"
                    color="error"
                    startIcon={<CloseIcon/>}
                    loading={false}
                    disabled={
                      !proposalBuilder ||
                      !commentThreads ||
                      isSubmitting ||
                      isSaving ||
                      isManagingReviewers ||
                      reviewerSelectorAnchor !== undefined ||
                      !proposal?.id ||
                      isReviewing
                    }
                    onClick={handleDeleteClicked}
                  >
                    Delete Proposal
                  </ProposalActionButton>
                )}
              </>
            )}
          </ProposalActions>
          <MessageButtons>
            <Tooltip
              title={!proposal?.id ? "Save proposal to enable commenting" : ""}
            >
              <ButtonContainer>
                <MessageButton
                  size="medium"
                  color="primary"
                  disabled={
                    !proposal?.id || !proposalBuilder || !commentThreads
                  }
                  onClick={() =>
                    handleOpenCommentsPanel(ProposalField.General)
                  }
                >
                  <Badge
                    variant="dot"
                    color="secondary"
                    overlap="rectangular"
                    invisible={
                      !commentThreads?.some((thread) =>
                        thread.field.isEqualTo(ProposalField.General)
                      )
                    }
                  >
                    <CommentIcon fontSize="medium"/>
                  </Badge>
                </MessageButton>
                <MessageButtonLabel
                  variant="button"
                  color={proposal?.id ? "primary" : "darkgray"}
                >
                  Comments
                </MessageButtonLabel>
              </ButtonContainer>
            </Tooltip>
          </MessageButtons>
          <Portal>
            <SidePanel
              open={isSidePanelOpen}
              anchor="right"
              variant="persistent"
            >
              <SidePanelContainer>
                <SidePanelContent>
                  <TitleBar>
                    <Typography variant="h5">{getSidePanelTitle()}</Typography>
                    <IconButton onClick={handleCloseCommentsPanel}>
                      <CloseIcon/>
                    </IconButton>
                  </TitleBar>
                  {renderComments()}
                </SidePanelContent>
              </SidePanelContainer>
            </SidePanel>
          </Portal>
        </ActionsContainer>
      </>
    )
  );
}
