import axios, {AxiosHeaders, CanceledError} from "axios";
import AttorneyHubAPIService from "common/services/api/attorney-hub-api-service";
import Guid from "common/values/guid/guid";
import {enqueueSnackbar} from "notistack";
import User from "users/entities/user/user";
import Session from "users/session/session";
import ProposalsParameters from "work/entities/proposal/api/request-contracts/proposal-parameters";
import Proposal, {IProposalStore} from "work/entities/proposal/proposal";
import {ProposalStatus} from "work/values/constants";
import UpdateProposalAPIRequest from "./request-contracts/update-proposal-api-request";
import WorkProposalAPIRequest from "./request-contracts/work-proposal-api-request";
import DetailedWorkProposalAPIResponse from "./response-contracts/detailed-work-proposal-api-response";
import SimpleWorkProposalAPIResponse from "./response-contracts/simple-work-proposal-api-response";
import SimpleWorkTeamAPIResponse from "../../../values/team/api/response-contracts/simple-work-team-api-response";

export default class ProposalAPIService implements IProposalStore {

  private readonly authHeaders: AxiosHeaders = new AxiosHeaders();
  private readonly currentUser: User;


  private authHeadersWithJson(): AxiosHeaders {
    return this.authHeaders.concat({'Content-Type': 'application/json'});
  }

  constructor(session: Readonly<Session>) {
    if (!session.authToken?.value)
      throw new Error("Session must have an authToken to create a ProposalAPIService");
    this.authHeaders.set(
      "Authorization",
      `Bearer ${session.authToken.value}`
    );
    if (!session.user) {
      throw new Error("Session must have a user to create a ProposalAPIService");
    }
    this.currentUser = session.user;
  }

  async getProposals(query: ProposalsParameters, abortController?: AbortController): Promise<Proposal[]> {
    try {
      const url = new URL(
        `/work/proposals?entityId=${query.entityId.value}&viewingAs=${query.viewingAs}&context=${query.context}`,
        AttorneyHubAPIService.apiBaseUrl
      );
      const response = await axios.get(
        url.toString(),
        {
          headers: this.authHeaders,
          signal: abortController?.signal
        }
      );
      const responseData: SimpleWorkProposalAPIResponse[] = response.data;
      return responseData.map((proposalData) => {

          const team = Object.assign(
            new SimpleWorkTeamAPIResponse(),
            proposalData.team
          ).deserialize();
          return Object.assign(
            new SimpleWorkProposalAPIResponse(),
            proposalData
          ).deserialize(
            this,
            this.currentUser,
            team
          )
        }
      );
    } catch (error: any) {
      if (error.response?.status === 400) {
        throw new InvalidProposalContextError(query.context);
      }
      if (error instanceof CanceledError)
        throw error;
      throw new ProposalAPIServiceError(
        "getProposals",
        error
      );
    }
  }

  async getProposalById(id: Guid, abortController?: AbortController): Promise<Proposal> {
    try {
      const url = new URL(
        `/work/proposals/${id.value}`,
        AttorneyHubAPIService.apiBaseUrl
      );
      const response = await axios.get(
        url.toString(),
        {
          headers: this.authHeaders,
          signal: abortController?.signal
        }
      );
      const responseData: DetailedWorkProposalAPIResponse = Object.assign(
        new DetailedWorkProposalAPIResponse(),
        response.data
      );
      const responseProposal = responseData.deserialize(
        this,
        this.currentUser
      );
      return responseProposal;
    } catch (error: any) {
      if (error.response?.status === 404) {
        throw new ProposalNotFoundError(id);
      }
      if (error instanceof CanceledError)
        throw error;
      throw new ProposalAPIServiceError(
        "getProposalById",
        error
      );
    }
  }

  async getProposalRevisionsById(id: Guid, abortController?: AbortController): Promise<Proposal[]> {
    try {
      const url = new URL(
        `/work/proposals/${id.value}/revisions`,
        AttorneyHubAPIService.apiBaseUrl
      );
      const response = await axios.get(
        url.toString(),
        {
          headers: this.authHeaders,
          signal: abortController?.signal
        }
      );
      return response.data.map((proposalData: SimpleWorkProposalAPIResponse) =>
        Object.assign(
          new SimpleWorkProposalAPIResponse(),
          proposalData
        ).deserialize(
          this,
          this.currentUser
        )
      );
    } catch (error: any) {
      if (error.response?.status === 404) {
        throw new ProposalNotFoundError(id);
      }
      if (error instanceof CanceledError)
        throw error;
      throw new ProposalAPIServiceError(
        "getProposalRevisions",
        error
      );
    }
  }

  async createProposal(proposal: Proposal, session: Readonly<Session>): Promise<Proposal> {
    try {
      const request = new WorkProposalAPIRequest(
        proposal,
        session
      );
      const url = new URL(
        "/work/proposals",
        AttorneyHubAPIService.apiBaseUrl
      );
      const response = await axios.post(
        url.toString(),
        request.payload,
        {
          headers: this.authHeadersWithJson()
        }
      );

      const responseData: DetailedWorkProposalAPIResponse = Object.assign(
        new DetailedWorkProposalAPIResponse(),
        response.data
      );
      return responseData.deserialize(
        this,
        this.currentUser
      );
    } catch (error) {
      console.error(error);
      return Promise.reject(
        new Error(
          "Unable to create proposal",
          {cause: error}
        )
      );
    }
  }

  async updateProposal(originalProposal: Proposal, updatedProposal: Proposal): Promise<Proposal> {
    if (!originalProposal.id)
      throw new Error("Cannot update proposal without id.");

    const request = new UpdateProposalAPIRequest(
      originalProposal,
      updatedProposal
    );

    if (request.payload.length === 0) {
      return originalProposal;
    }

    try {
      const url = new URL(
        `/work/proposals/${originalProposal.id.value}`,
        AttorneyHubAPIService.apiBaseUrl
      );
      const response = await axios.patch(
        url.toString(),
        request.payload,
        {
          headers: this.authHeadersWithJson()
        }
      );
      const responseData: DetailedWorkProposalAPIResponse = Object.assign(
        new DetailedWorkProposalAPIResponse(),
        response.data
      );
      const responseProposal = responseData.deserialize(
        this,
        this.currentUser
      );
      return responseProposal;
    } catch (error: any) {
      if (error.response?.status === 404) {
        throw new ProposalNotFoundError(originalProposal.id);
      }
      if (error.response?.status === 400) {
        throw new ProposalUpdateError(
          originalProposal.id,
          error.response?.data
        );
      }
      throw new ProposalAPIServiceError(
        "updateProposal",
        error
      );
    }
  }

  async deleteProposal(proposal: Proposal): Promise<void> {
    if (!proposal.id) {
      throw new Error("Cannot delete proposal without id.");
    }

    if (proposal.status !== ProposalStatus.AwaitingSubmission) {
      throw new Error("Cannot delete proposal that is not in draft status.");
    }

    try {
      const url = new URL(
        `/work/proposals/${proposal.id.value}`,
        AttorneyHubAPIService.apiBaseUrl
      );
      await axios.delete(
        url.toString(),
        {
          headers: this.authHeaders
        }
      );
    } catch (error: any) {
      if (error.response?.status === 404) {
        throw new ProposalNotFoundError(proposal.id);
      }
      throw new ProposalAPIServiceError(
        "deleteProposal",
        error
      );
    }
  }

  async removeDraftProposals(proposalIds: Guid[]): Promise<void> {
    if (proposalIds.length === 0) {
      return;
    }

    try {
      const url = new URL(
        `/work/draft-proposals`,
        AttorneyHubAPIService.apiBaseUrl
      );
      const response = await axios.delete(
        url.toString(),
        {
          headers: this.authHeaders,
          data: {proposalIds: proposalIds.map(id => id.value)}
        }
      );
      if (response?.status === 207) {
        enqueueSnackbar(
          "Some drafts could not be removed",
          {variant: "warning"}
        );
      }
    } catch (error: any) {
      if (error.response?.status === 404) {
        throw new ProposalNotFoundError();
      }
      throw new ProposalAPIServiceError(
        "removeDraftProposals",
        error
      );
    }
  }

  async cancelProposal(proposal: Proposal): Promise<Proposal> {
    if (!proposal.id) {
      throw new Error("Cannot cancel proposal without id.");
    }

    try {
      const url = new URL(
        `/work/proposals/${proposal.id.value}/cancel`,
        AttorneyHubAPIService.apiBaseUrl
      );
      const response = await axios.post(
        url.toString(),
        {},
        {
          headers: this.authHeaders
        }
      );

      return Object.assign(
        new DetailedWorkProposalAPIResponse(),
        response.data
      ).deserialize(
        this,
        this.currentUser
      );
    } catch (error: any) {
      throw new ProposalAPIServiceError(
        "cancelProposal",
        error
      );
    }
  }

  async hireProposal(proposal: Proposal): Promise<Proposal> {
    if (!proposal.id) {
      throw new Error("Cannot hire proposal without id.");
    }

    try {
      const url = new URL(
        `/work/proposals/${proposal.id.value}/hire`,
        AttorneyHubAPIService.apiBaseUrl
      );
      const response = await axios.post(
        url.toString(),
        {},
        {
          headers: this.authHeaders
        }
      );

      return Object.assign(
        new DetailedWorkProposalAPIResponse(),
        response.data
      ).deserialize(
        this,
        this.currentUser
      );
    } catch (error: any) {
      throw new ProposalAPIServiceError(
        "hireProposal",
        error
      );
    }
  }

  async submitProposal(proposal: Proposal): Promise<Proposal> {
    if (!proposal.id) {
      throw new Error("Cannot submit proposal without id.");
    }

    try {
      const url = new URL(
        `/work/proposals/${proposal.id.value}/submit`,
        AttorneyHubAPIService.apiBaseUrl
      );
      const response = await axios.post(
        url.toString(),
        {},
        {
          headers: this.authHeaders
        }
      );
      return Object.assign(
        new DetailedWorkProposalAPIResponse(),
        response.data
      ).deserialize(
        this,
        this.currentUser
      );
    } catch (error: any) {
      if (error.response?.status === 404) {
        throw new ProposalNotFoundError(proposal.id);
      }
      throw new ProposalAPIServiceError(
        "submitProposal",
        error
      );
    }
  }

  async approveProposal(proposal: Proposal): Promise<Proposal> {
    if (!proposal.id) {
      throw new Error("Cannot approve proposal without id.");
    }

    try {
      const url = new URL(
        `/work/proposals/${proposal.id.value}/approve`,
        AttorneyHubAPIService.apiBaseUrl
      );
      const response = await axios.post(
        url.toString(),
        {},
        {
          headers: this.authHeaders
        }
      );
      return Object.assign(
        new DetailedWorkProposalAPIResponse(),
        response.data
      ).deserialize(
        this,
        this.currentUser
      );
    } catch (error: any) {
      if (error.response?.status === 404) {
        throw new ProposalNotFoundError(proposal.id);
      }
      throw new ProposalAPIServiceError(
        "approveProposal",
        error
      );
    }
  }

  async rejectProposal(proposal: Proposal): Promise<Proposal> {
    if (!proposal.id) {
      throw new Error("Cannot reject proposal without id.");
    }

    try {
      const url = new URL(
        `/work/proposals/${proposal.id.value}/reject`,
        AttorneyHubAPIService.apiBaseUrl
      );
      const response = await axios.post(
        url.toString(),
        {},
        {
          headers: this.authHeaders
        }
      );
      return Object.assign(
        new DetailedWorkProposalAPIResponse(),
        response.data
      ).deserialize(
        this,
        this.currentUser
      );
    } catch (error: any) {
      if (error.response?.status === 404) {
        throw new ProposalNotFoundError(proposal.id);
      }
      throw new ProposalAPIServiceError(
        "rejectProposal",
        error
      );
    }
  }

  async requestRevision(proposal: Proposal, session: Readonly<Session>): Promise<Proposal> {
    if (!proposal.id) {
      throw new Error("Cannot request revision for proposal without id.");
    }
    const redline = proposal.redline?.clone()

    try {
      const request = new WorkProposalAPIRequest(
        proposal.redlinedRevision,
        session
      ).payload;

      const url = new URL(
        `/work/proposals/${proposal.id.value}/request-revision`,
        AttorneyHubAPIService.apiBaseUrl
      );

      const response = await axios.post(
        url.toString(),
        request,
        {
          headers: this.authHeadersWithJson()
        }
      );
      return Object.assign(
        new DetailedWorkProposalAPIResponse(),
        response.data
      ).deserialize(
        this,
        this.currentUser
      );
    } catch (error: any) {
      throw new ProposalAPIServiceError(
        "requestRevision",
        error
      );
    }
  }

  async giveReviewerApproval(proposal: Proposal): Promise<void> {
    if (!proposal.id) {
      throw new Error("Cannot approve a proposal without id.");
    }
    try {
      const url = new URL(
        `/work/proposals/${proposal.id.value}/reviewer-approve`,
        AttorneyHubAPIService.apiBaseUrl
      );
      await axios.post(
        url.toString(),
        {},
        {
          headers: this.authHeaders
        }
      );
    } catch (error: any) {
      if (error.response?.status === 404) {
        throw new ProposalNotFoundError(proposal.id);
      }
      throw new ProposalAPIServiceError(
        "giveReviewerApproval",
        error
      );
    }
  }

  async quitProposalTeam(proposalId: Guid): Promise<void> {
    try {
      const url = new URL(
        `/work/proposals/${proposalId.value}/quit-team`,
        AttorneyHubAPIService.apiBaseUrl
      );
      await axios.post(
        url.toString(),
        {},
        {
          headers: this.authHeaders
        }
      );
    } catch (error: any) {
      if (error.response?.status === 404) {
        throw new ProposalNotFoundError(proposalId);
      } else if (error.response?.status === 400) {
        throw new ProposalTeamMemberQuitError(proposalId);
      }
      throw new ProposalAPIServiceError(
        "quitProposalTeam",
        error
      );
    }
  }
}

export class ProposalAPIServiceError extends Error {
  constructor(method: string, error: any) {
    super(`Error calling ProposalAPIService.${method}: Error: ${error}`);
  }
}

export class InvalidProposalContextError extends Error {
  constructor(context: string) {
    super(`Invalid viewing context ${context} for user.`);
  }
}

export class ProposalNotFoundError extends Error {
  constructor(proposalId?: Guid) {
    if (!proposalId)
      super("One or more proposals not found.");
    else
      super(`Proposal with id ${proposalId} not found.`);
  }
}

export class ProposalTeamMemberQuitError extends Error {
  constructor(proposalId: Guid) {
    super(`Error quitting proposal team for proposal ${proposalId}`);
  }
}

export class ProposalUpdateError extends Error {
  proposalId: Guid;

  constructor(proposalId: Guid, errorData?: any) {
    if (errorData) {
      super("Unable to update proposal: " + errorData);
      if (typeof errorData === "string") {
        this.message = errorData;
      } else if (typeof errorData === "object") {
        this.message = Object.keys(errorData).map((key) => `${key}: ${errorData[key]}`).join(", ");
      } else {
        this.message = "Unknown error."
      }
    } else {
      super("Unable to update proposal.");
    }
    this.name = "ProposalUpdateError";
    this.proposalId = proposalId;
  }
}
