import AHBoolean from "common/values/boolean/boolean";
import Date from "common/values/date/date";
import Guid from "common/values/guid/guid";
import Percent from "common/values/percent/percent";
import _ from "lodash";
import Individual from "marketplace/entities/individual/individual";
import moment, {Moment} from "moment";
import User from "users/entities/user/user";
import Session from "users/session/session";
import EntityClientRepresentative from "work/entities/entity-client-representative/entity-client-representative";
import EntityVendorRepresentative from "work/entities/entity-vendor-representative/entity-vendor-representative";
import Proposal, {
  CompleteProposalSpec,
  ProposalField,
  ProposalFieldCategory,
} from "work/entities/proposal/proposal";
import {ProposalFieldName} from "work/values/constants";
import FeeScheduleCategory from "work/values/fee-schedule-category/fee-schedule-category";
import ProjectDescription from "work/values/project-description/project-description";
import ProjectName from "work/values/project-name/project-name";
import ProposalReviewer from "work/values/proposal-reviewer";
import DetailedTeam from "work/values/team/detailed-team";
import WorkDocument from "work/values/work-document/work-document";
import ProposalFactory from "./proposal-factory";
import {ReviewsRounded} from "@mui/icons-material";

export class ProposalSpec {
  client?: EntityClientRepresentative;
  vendors: EntityVendorRepresentative[] = [];
  name?: ProjectName;
  description?: ProjectDescription;
  negotiable?: boolean;
  responseDueBy?: Date;
  startDate?: Date;
  endDate?: Date;
  supersededBy?: Proposal;
  supersedesId?: Guid;
  clientReviewers?: ProposalReviewer[];
  vendorReviewers?: ProposalReviewer[];
  discount: Percent = new Percent(0);
  feeSchedule: FeeScheduleCategory[] = [];
  conflictsDocuments: WorkDocument[] = [];
  clientPolicyDocuments: WorkDocument[] = [];
  vendorPolicyDocuments: WorkDocument[] = [];
  team?: DetailedTeam;
  clientTeamTemplateIds: Guid[] = [];
  vendorTeamTemplateIds: Guid[] = [];
  clientFeeScheduleTemplateIds: Guid[] = [];
  vendorFeeScheduleTemplateIds: Guid[] = [];
  teamRestricted: AHBoolean = new AHBoolean(false);
  conflictsCheckWaived: AHBoolean = new AHBoolean(false);

  clone(): ProposalSpec {
    const clone = new ProposalSpec();
    clone.client = this.client?.clone();
    clone.name = this.name?.clone();
    clone.description = this.description?.clone();
    clone.negotiable = this.negotiable;
    clone.responseDueBy = this.responseDueBy?.clone();
    clone.startDate = this.startDate?.clone();
    clone.endDate = this.endDate?.clone();
    clone.supersededBy = this.supersededBy;
    clone.supersedesId = this.supersedesId?.clone();
    clone.clientReviewers = this.clientReviewers?.concat();
    clone.vendorReviewers = this.vendorReviewers?.concat();
    clone.feeSchedule = this.feeSchedule.map((category) => category.clone());
    clone.discount = this.discount.clone();
    clone.conflictsDocuments = this.conflictsDocuments.map((doc) =>
      doc.clone()
    );
    clone.clientPolicyDocuments = this.clientPolicyDocuments.map((doc) =>
      doc.clone()
    );
    clone.vendorPolicyDocuments = this.vendorPolicyDocuments.map((doc) =>
      doc.clone()
    );
    clone.team = this.team?.clone();
    clone.clientTeamTemplateIds = this.clientTeamTemplateIds.map((id) =>
      id.clone()
    );
    clone.vendorTeamTemplateIds = this.vendorTeamTemplateIds.map((id) =>
      id.clone()
    );
    clone.clientFeeScheduleTemplateIds = this.clientFeeScheduleTemplateIds.map(
      (id) => id.clone()
    );
    clone.vendorFeeScheduleTemplateIds = this.vendorFeeScheduleTemplateIds.map(
      (id) => id.clone()
    );
    clone.teamRestricted = this.teamRestricted.clone();
    clone.conflictsCheckWaived = this.conflictsCheckWaived.clone();
    return clone;
  }

  isEqualTo(other: ProposalSpec): boolean {
    if (
      (this.client && !this.client?.isEqualTo(other.client)) ||
      (!this.client && other.client)
    )
      return false;
    if (
      (this.name && !this.name?.isEqualTo(other.name)) ||
      (!this.name && other.name)
    )
      return false;
    if (
      (this.description && !this.description?.isEqualTo(other.description)) ||
      (!this.description && other.description)
    )
      return false;
    if (this.negotiable !== other.negotiable) return false;
    if (
      (this.responseDueBy &&
        !this.responseDueBy?.isEqualTo(other.responseDueBy)) ||
      (!this.responseDueBy && other.responseDueBy)
    )
      return false;
    if (
      (this.startDate && !this.startDate?.isEqualTo(other.startDate)) ||
      (!this.startDate && other.startDate)
    )
      return false;
    if (
      (this.endDate && !this.endDate?.isEqualTo(other.endDate)) ||
      (!this.endDate && other.endDate)
    )
      return false;
    if (
      (this.discount && !this.discount.isEqualTo(other.discount)) ||
      (!this.discount && other.discount)
    )
      return false;
    if (
      (this.team && !this.team?.isEqualTo(other.team)) ||
      (!this.team && other.team)
    )
      return false;
    if (!this.teamRestricted.isEqualTo(other.teamRestricted)) return false;
    if (!this.conflictsCheckWaived.isEqualTo(other.conflictsCheckWaived))
      return false;

    if (!this.feeSchedule && other.feeSchedule) return false;
    if (this.feeSchedule.length !== other.feeSchedule.length) return false;
    this.feeSchedule.forEach((thisCategory) => {
      if (
        !other.feeSchedule.some((otherCategory) =>
          otherCategory.isEqualTo(thisCategory)
        )
      )
        return false;
    });
    other.feeSchedule.forEach((otherCategory) => {
      if (
        !this.feeSchedule.some((thisCategory) =>
          thisCategory.isEqualTo(otherCategory)
        )
      )
        return false;
    });

    if (!this.conflictsDocuments && other.conflictsDocuments) return false;
    if (this.conflictsDocuments.length !== other.conflictsDocuments.length)
      return false;
    this.conflictsDocuments.forEach((doc) => {
      if (!other.conflictsDocuments.some((otherDoc) => otherDoc.isEqualTo(doc)))
        return false;
    });

    if (!this.clientPolicyDocuments && other.clientPolicyDocuments)
      return false;
    if (
      this.clientPolicyDocuments.length !== other.clientPolicyDocuments.length
    )
      return false;
    this.clientPolicyDocuments.forEach((doc) => {
      if (
        !other.clientPolicyDocuments.some((otherDoc) => otherDoc.isEqualTo(doc))
      )
        return false;
    });

    if (!this.vendorPolicyDocuments && other.vendorPolicyDocuments)
      return false;
    if (
      this.vendorPolicyDocuments.length !== other.vendorPolicyDocuments.length
    )
      return false;
    this.vendorPolicyDocuments.forEach((doc) => {
      if (
        !other.vendorPolicyDocuments.some((otherDoc) => otherDoc.isEqualTo(doc))
      )
        return false;
    });

    if (!this.clientTeamTemplateIds && other.clientTeamTemplateIds)
      return false;
    if (
      this.clientTeamTemplateIds.length !== other.clientTeamTemplateIds.length
    )
      return false;
    this.clientTeamTemplateIds.forEach((id) => {
      if (!other.clientTeamTemplateIds.some((otherId) => otherId.isEqualTo(id)))
        return false;
    });

    if (!this.vendorTeamTemplateIds && other.vendorTeamTemplateIds)
      return false;
    if (
      this.vendorTeamTemplateIds.length !== other.vendorTeamTemplateIds.length
    )
      return false;
    this.vendorTeamTemplateIds.forEach((id) => {
      if (!other.vendorTeamTemplateIds.some((otherId) => otherId.isEqualTo(id)))
        return false;
    });

    if (
      !this.clientFeeScheduleTemplateIds &&
      other.clientFeeScheduleTemplateIds
    )
      return false;
    if (
      this.clientFeeScheduleTemplateIds.length !==
      other.clientFeeScheduleTemplateIds.length
    )
      return false;
    this.clientFeeScheduleTemplateIds.forEach((id) => {
      if (
        !other.clientFeeScheduleTemplateIds.some((otherId) =>
          otherId.isEqualTo(id)
        )
      )
        return false;
    });

    if (
      !this.vendorFeeScheduleTemplateIds &&
      other.vendorFeeScheduleTemplateIds
    )
      return false;
    if (
      this.vendorFeeScheduleTemplateIds.length !==
      other.vendorFeeScheduleTemplateIds.length
    )
      return false;
    this.vendorFeeScheduleTemplateIds.forEach((id) => {
      if (
        !other.vendorFeeScheduleTemplateIds.some((otherId) =>
          otherId.isEqualTo(id)
        )
      )
        return false;
    });

    if (!this.clientReviewers && other.clientReviewers) {
      return false;
    }
    if (this.clientReviewers?.length !== other.clientReviewers?.length) {
      return false;
    }
    const clientReviewersAreEqual = !this.clientReviewers?.some(
      (thisReviewer) => {
        return !other.clientReviewers?.some((otherReviewer) => {
          return otherReviewer.isEqualTo(thisReviewer);
        });
      }
    );
    if (!clientReviewersAreEqual) return false;

    if (!this.vendorReviewers && other.vendorReviewers) {
      return false;
    }
    if (this.vendorReviewers?.length !== other.vendorReviewers?.length) {
      return false;
    }
    const vendorReviewersAreEqual = !this.vendorReviewers?.some(
      (thisReviewer) => {
        return !other.vendorReviewers?.some((otherReviewer) => {
          return otherReviewer.isEqualTo(thisReviewer);
        });
      }
    );
    if (!vendorReviewersAreEqual) return false;

    return true;
  }
}

export class ProposalSpecChange {
  field: ProposalField | null;
  action: ProposalChangeAction;
  value: string;
  timeStamp: Moment = moment();

  constructor(
    field: ProposalField,
    action: ProposalChangeAction,
    value: string
  ) {
    this.field = field ?? null;
    this.action = action ?? ProposalChangeAction.Edit;
    this.value = value ?? "";
  }

  static fromJSON(object: any): ProposalSpecChange | undefined {
    const field = ProposalField.fromJSON(object.field);
    if (!field) return undefined;
    const action = ProposalChangeAction.fromJSON(object.action);
    const value = object.value;
    return new ProposalSpecChange(
      field,
      action,
      value
    );
  }

  public toJSON(): object {
    return {
      field: this.field?.toJSON(),
      action: this.action.toString(),
      value: this.value,
    };
  }

  clone(): ProposalSpecChange {
    const clone = ProposalSpecChange.fromJSON(this.toJSON());
    if (!clone) throw new Error("Failed to clone ProposalSpecChange");
    return clone;
  }
}

export class ProposalChangeAction {
  // The present tense of the action MUST match the readonly property name
  static readonly Edit: ProposalChangeAction = new ProposalChangeAction(
    "Edit",
    "Edited"
  );
  static readonly ReEdit: ProposalChangeAction = new ProposalChangeAction(
    "ReEdit",
    "Re-edited"
  );
  static readonly Add: ProposalChangeAction = new ProposalChangeAction(
    "Add",
    "Added"
  );
  static readonly Remove: ProposalChangeAction = new ProposalChangeAction(
    "Remove",
    "Removed"
  );

  private constructor(
    private readonly presentTense: string,
    public readonly actionDescription: string
  ) {
  }

  toString() {
    return this.presentTense;
  }

  public static fromJSON(
    obj: keyof typeof ProposalChangeAction
  ): ProposalChangeAction {
    const newAction = ProposalChangeAction[obj] as ProposalChangeAction;
    return newAction;
  }
}

export default class ProposalBuilder {
  private _specStack: ProposalSpec[] = [new ProposalSpec()];
  private _currentIndex: number = 0;
  private _sessionHistory: ProposalSpecChange[] = [];
  private readonly _currentUser: User;

  constructor(currentUser: User | null, startingSpec?: CompleteProposalSpec) {
    if (!currentUser) throw new Error("currentUser is required");
    this._currentUser = currentUser;
    if (startingSpec) {
      const spec = new ProposalSpec();
      spec.client = startingSpec.client;
      spec.name = startingSpec.name;
      spec.description = startingSpec.description;
      spec.negotiable = startingSpec.negotiable;
      spec.responseDueBy = startingSpec.responseDueBy;
      spec.startDate = startingSpec.startDate;
      spec.endDate = startingSpec.endDate;
      spec.clientReviewers = startingSpec.clientReviewers;
      spec.vendorReviewers = startingSpec.vendorReviewers;
      spec.discount = startingSpec.discount;
      spec.feeSchedule = startingSpec.feeSchedule;
      spec.conflictsDocuments = startingSpec.conflictsDocuments;
      spec.clientPolicyDocuments = startingSpec.clientPolicyDocuments;
      spec.vendorPolicyDocuments = startingSpec.vendorPolicyDocuments;
      spec.team = startingSpec.team;
      spec.clientTeamTemplateIds = startingSpec.clientTeamTemplateIds;
      spec.vendorTeamTemplateIds = startingSpec.vendorTeamTemplateIds;
      spec.clientFeeScheduleTemplateIds =
        startingSpec.clientFeeScheduleTemplateIds;
      spec.vendorFeeScheduleTemplateIds =
        startingSpec.vendorFeeScheduleTemplateIds;
      spec.teamRestricted = startingSpec.teamRestricted;
      spec.conflictsCheckWaived = startingSpec.conflictsCheckWaived;
      this._specStack = [spec];
      this._currentIndex = 0;
    }
  }

  public get currentSpec(): ProposalSpec {
    if (this._currentIndex >= this._specStack.length || this._currentIndex < 0)
      throw new Error("Invalid index");
    return this._specStack[this._currentIndex];
  }

  public get sessionHistory(): ProposalSpecChange[] {
    return this._sessionHistory;
  }

  public get canUndo(): boolean {
    return this._currentIndex > 0;
  }

  public get canRedo(): boolean {
    return this._currentIndex < this._specStack.length - 1;
  }

  public get isModified(): boolean {
    if (this._currentIndex === 0) return false;
    return !this.currentSpec.isEqualTo(this._specStack[0]);
  }

  public undo(): ProposalBuilder {
    if (!this.canUndo) return this;
    const newBuilder = this.clone();
    newBuilder._currentIndex--;
    return newBuilder;
  }

  public redo(): ProposalBuilder {
    if (!this.canRedo) return this;
    const newBuilder = this.clone();
    newBuilder._currentIndex++;
    return newBuilder;
  }

  public setClient(client?: EntityClientRepresentative): ProposalBuilder {
    const newBuilder = this.clone();
    newBuilder.resetStack();

    const newSpec = this.currentSpec.clone();
    newSpec.client = client;

    newBuilder._specStack.push(newSpec);
    newBuilder._currentIndex = newBuilder._specStack.length - 1;
    return newBuilder;
  }

  public setName(name: ProjectName): ProposalBuilder {
    if (this.currentSpec.name?.value === name.value) {
      return this;
    }
    const newBuilder = this.clone();
    newBuilder.resetStack();
    const newSpec = newBuilder.currentSpec.clone();
    newSpec.name = name;
    newBuilder._specStack.push(newSpec);
    newBuilder._currentIndex = newBuilder._specStack.length - 1;

    newBuilder._sessionHistory = newBuilder._sessionHistory.filter(
      (change) => !change.field?.isEqualTo(ProposalField.Name)
    );
    if (
      (!newBuilder._specStack[0].name?.value && !name.value) ||
      newBuilder._specStack[0].name?.isEqualTo(name)
    ) {
      return newBuilder;
    }
    let action: ProposalChangeAction = ProposalChangeAction.Edit;
    let value = newBuilder._specStack[0].name?.value ?? "";
    if (!newBuilder._specStack[0].name && name) {
      action = ProposalChangeAction.Add;
      value = name.value;
    } else if (newBuilder._specStack[0].name?.value && !name.value) {
      action = ProposalChangeAction.Remove;
    }
    const specChange = new ProposalSpecChange(
      ProposalField.Name,
      action,
      value
    );
    newBuilder._sessionHistory.push(specChange);

    return newBuilder;
  }

  public setDescription(description: ProjectDescription): ProposalBuilder {
    if (
      (!description && !this.currentSpec.description) ||
      this.currentSpec.description?.isEqualTo(description)
    ) {
      return this;
    }
    const newBuilder = this.clone();

    newBuilder.resetStack();
    const newSpec = newBuilder.currentSpec.clone();
    newSpec.description = description;
    newBuilder._specStack.push(newSpec);
    newBuilder._currentIndex = newBuilder._specStack.length - 1;

    newBuilder._sessionHistory = newBuilder._sessionHistory.filter(
      (change) => !change.field?.isEqualTo(ProposalField.Description)
    );

    if (
      (!newBuilder._specStack[0].description?.value && !description?.value) ||
      newBuilder._specStack[0].description?.isEqualTo(description)
    ) {
      return newBuilder;
    }
    let action: ProposalChangeAction = ProposalChangeAction.Edit;
    let value = newBuilder._specStack[0].description?.value ?? "";
    if (!newBuilder._specStack[0].description?.value && description?.value) {
      action = ProposalChangeAction.Add;
      value = description.value;
    } else if (
      newBuilder._specStack[0].description?.value &&
      !description?.value
    ) {
      action = ProposalChangeAction.Remove;
    }
    const specChange = new ProposalSpecChange(
      ProposalField.Description,
      action,
      value
    );
    newBuilder._sessionHistory.push(specChange);

    return newBuilder;
  }

  public setNegotiable(negotiable: boolean): ProposalBuilder {
    if (this.currentSpec.negotiable === negotiable) {
      return this;
    }
    const newBuilder = this.clone();

    this._sessionHistory = newBuilder._sessionHistory.filter(
      (change) => !change.field?.isEqualTo(ProposalField.Negotiable)
    );

    newBuilder.resetStack();
    const newSpec = newBuilder.currentSpec.clone();
    newSpec.negotiable = negotiable;
    newBuilder._specStack.push(newSpec);
    newBuilder._currentIndex = newBuilder._specStack.length - 1;

    if (newBuilder._specStack[0].negotiable === negotiable) {
      return newBuilder;
    }
    const action = ProposalChangeAction.Edit;
    const specChange = new ProposalSpecChange(
      ProposalField.Negotiable,
      action,
      newBuilder._specStack[0].negotiable ? "Negotiable" : "Not Negotiable"
    );
    newBuilder._sessionHistory.push(specChange);

    return newBuilder;
  }

  public setResponseDueBy(responseDueBy: Date | undefined): ProposalBuilder {
    if (
      (!this.currentSpec.responseDueBy && !responseDueBy) ||
      this.currentSpec.responseDueBy?.isEqualTo(responseDueBy)
    ) {
      return this;
    }

    const newBuilder = this.clone();
    newBuilder.resetStack();
    const newSpec = newBuilder.currentSpec.clone();
    newSpec.responseDueBy = responseDueBy;
    newBuilder._specStack.push(newSpec);
    newBuilder._currentIndex = newBuilder._specStack.length - 1;

    newBuilder._sessionHistory = newBuilder._sessionHistory.filter(
      (change) => !change.field?.isEqualTo(ProposalField.ResponseDueBy)
    );

    if (
      (!newBuilder._specStack[0].responseDueBy && !responseDueBy) ||
      newBuilder._specStack[0].responseDueBy?.isEqualTo(responseDueBy)
    ) {
      return newBuilder;
    }
    let action: ProposalChangeAction = ProposalChangeAction.Edit;
    let value =
      newBuilder._specStack[0].responseDueBy?.format("MM/DD/YY") ?? "";
    if (!newBuilder._specStack[0].responseDueBy && responseDueBy) {
      action = ProposalChangeAction.Add;
      value = responseDueBy.format("MM/DD/YY");
    } else if (newBuilder._specStack[0].responseDueBy && !responseDueBy) {
      action = ProposalChangeAction.Remove;
    }
    const specChange = new ProposalSpecChange(
      ProposalField.ResponseDueBy,
      action,
      value
    );
    newBuilder._sessionHistory.push(specChange);

    return newBuilder;
  }

  public setStartDate(startDate: Date | undefined): ProposalBuilder {
    if (
      (!this.currentSpec.startDate && !startDate) ||
      this.currentSpec.startDate?.isEqualTo(startDate)
    ) {
      return this;
    }

    const newBuilder = this.clone();
    newBuilder.resetStack();
    const newSpec = newBuilder.currentSpec.clone();
    newSpec.startDate = startDate;
    newBuilder._specStack.push(newSpec);
    newBuilder._currentIndex = newBuilder._specStack.length - 1;

    newBuilder._sessionHistory = newBuilder._sessionHistory.filter(
      (change) => !change.field?.isEqualTo(ProposalField.StartDate)
    );
    if (
      (!newBuilder._specStack[0].startDate && !startDate) ||
      newBuilder._specStack[0].startDate?.isEqualTo(startDate)
    ) {
      return newBuilder;
    }
    let action: ProposalChangeAction = ProposalChangeAction.Edit;
    let value = newBuilder._specStack[0].startDate?.format("MM/DD/YY") ?? "";
    if (!newBuilder._specStack[0].startDate && startDate) {
      action = ProposalChangeAction.Add;
      value = startDate.format("MM/DD/YY");
    } else if (newBuilder._specStack[0].startDate && !startDate) {
      action = ProposalChangeAction.Remove;
    }
    const specChange = new ProposalSpecChange(
      ProposalField.StartDate,
      action,
      value
    );
    newBuilder._sessionHistory.push(specChange);

    return newBuilder;
  }

  public setEndDate(endDate: Date | undefined): ProposalBuilder {
    if (
      (!this.currentSpec.endDate && !endDate) ||
      this.currentSpec.endDate?.isEqualTo(endDate)
    ) {
      return this;
    }

    const newBuilder = this.clone();
    newBuilder.resetStack();
    const newSpec = newBuilder.currentSpec.clone();
    newSpec.endDate = endDate;
    newBuilder._specStack.push(newSpec);
    newBuilder._currentIndex = newBuilder._specStack.length - 1;

    newBuilder._sessionHistory = newBuilder._sessionHistory.filter(
      (change) => !change.field?.isEqualTo(ProposalField.EndDate)
    );

    if (
      (!newBuilder._specStack[0].endDate && !endDate) ||
      newBuilder._specStack[0].endDate?.isEqualTo(endDate)
    ) {
      return newBuilder;
    }
    let action: ProposalChangeAction = ProposalChangeAction.Edit;
    let value = newBuilder._specStack[0].endDate?.format("MM/DD/YY") ?? "";
    if (!newBuilder._specStack[0].endDate && endDate) {
      action = ProposalChangeAction.Add;
      value = endDate.format("MM/DD/YY");
    } else if (newBuilder._specStack[0].endDate && !endDate) {
      action = ProposalChangeAction.Remove;
    }
    const specChange = new ProposalSpecChange(
      ProposalField.EndDate,
      action,
      value
    );
    newBuilder._sessionHistory.push(specChange);

    return newBuilder;
  }

  public setTeam(newTeam: DetailedTeam | undefined): ProposalBuilder {
    if (
      (!newTeam && !this.currentSpec.team) ||
      this.currentSpec.team?.isEqualTo(newTeam)
    ) {
      return this;
    }

    const newBuilder = this.clone();
    newBuilder.resetStack();
    const newSpec = newBuilder.currentSpec.clone();
    newSpec.team = newTeam;

    newBuilder._specStack.push(newSpec);
    newBuilder._currentIndex = newBuilder._specStack.length - 1;

    newBuilder._sessionHistory = newBuilder._sessionHistory.filter(
      (change) => change.field?.category !== ProposalFieldCategory.Team
    );

    const addedLeader =
      newTeam?.leader?.userId.value &&
      !newBuilder._specStack[0].team?.leader?.userId.value;
    const removedLeader =
      !newTeam?.leader?.userId.value &&
      newBuilder._specStack[0].team?.leader?.userId.value;

    if (
      addedLeader &&
      newTeam.leader &&
      !newTeam.leader?.userId.isEqualTo(
        newBuilder._specStack[0].team?.leader?.userId
      )
    ) {
      const specChange = new ProposalSpecChange(
        ProposalField.TeamLeader,
        ProposalChangeAction.Add,
        newTeam?.leader?.getFullName()
      );
      newBuilder._sessionHistory.push(specChange);
    }

    if (removedLeader && newBuilder._specStack[0].team?.leader) {
      const specChange = new ProposalSpecChange(
        ProposalField.TeamLeader,
        ProposalChangeAction.Remove,
        newBuilder._specStack[0].team?.leader.getFullName()
      );
      newBuilder._sessionHistory.push(specChange);
    }

    for (const newMember of newTeam?.members ?? []) {
      const existingMember = newBuilder._specStack[0].team?.members.some(
        (originalMember) => originalMember.userId.isEqualTo(newMember.userId)
      );
      if (!existingMember) {
        const specChange = new ProposalSpecChange(
          ProposalField.TeamMember(newMember.userId),
          ProposalChangeAction.Add,
          newMember.getFullName()
        );
        newBuilder._sessionHistory.push(specChange);
      }
    }
    for (const originalMember of newBuilder._specStack[0].team?.members ?? []) {
      const removedMember = !newTeam?.members.some((newMember) =>
        newMember.userId.isEqualTo(originalMember.userId)
      );

      if (removedMember) {
        const specChange = new ProposalSpecChange(
          ProposalField.TeamMember(originalMember.userId),
          ProposalChangeAction.Remove,
          originalMember.getFullName()
        );
        newBuilder._sessionHistory.push(specChange);
      }
    }

    if (
      newBuilder._specStack[0]?.teamRestricted?.value !==
      newSpec.teamRestricted?.value
    ) {
      const specChange = new ProposalSpecChange(
        ProposalField.TeamRestriction,
        newSpec.teamRestricted?.value
          ? ProposalChangeAction.Add
          : ProposalChangeAction.Remove,
        "Team Restriction"
      );
      newBuilder._sessionHistory.push(specChange);
    }
    return newBuilder;
  }

  public setFeeSchedule(
    categories: FeeScheduleCategory[] | undefined
  ): ProposalBuilder {
    const newBuilder = this.clone();
    newBuilder.resetStack();
    const newSpec = newBuilder.currentSpec.clone();
    newSpec.feeSchedule = categories ?? [];
    newBuilder._specStack.push(newSpec);
    newBuilder._currentIndex = newBuilder._specStack.length - 1;

    newBuilder._sessionHistory = newBuilder._sessionHistory.filter(
      (change) => change.field?.category !== ProposalFieldCategory.FeeSchedule
    );
    for (const category of categories ?? []) {
      const existingCategory = newBuilder._specStack[0].feeSchedule?.find(
        (cat) => cat.id.isEqualTo(category.id)
      );
      if (!existingCategory) {
        const specChange = new ProposalSpecChange(
          ProposalField.FeeScheduleCategory(category.id),
          ProposalChangeAction.Add,
          category.toString()
        );
        newBuilder._sessionHistory.push(specChange);
      } else if (!category.isEqualTo(existingCategory)) {
        const specChange = new ProposalSpecChange(
          ProposalField.FeeScheduleCategory(category.id),
          ProposalChangeAction.Edit,
          existingCategory.toString()
        );
        newBuilder._sessionHistory.push(specChange);
      }
    }
    for (const category of newBuilder._specStack[0].feeSchedule ?? []) {
      const removedCategory = !categories?.find((cat) =>
        cat.id.isEqualTo(category.id)
      );
      if (removedCategory) {
        const specChange = new ProposalSpecChange(
          ProposalField.FeeScheduleCategory(category.id),
          ProposalChangeAction.Remove,
          category.toString()
        );
        newBuilder._sessionHistory.push(specChange);
      }
    }

    return newBuilder;
  }

  public setDiscount(discount: Percent): ProposalBuilder {
    if (
      (!this.currentSpec.discount && !discount) ||
      this.currentSpec.discount.isEqualTo(discount)
    ) {
      return this;
    }

    const newBuilder = this.clone();
    newBuilder.resetStack();
    const newSpec = newBuilder.currentSpec.clone();
    newSpec.discount = discount ?? 0;
    newBuilder._specStack.push(newSpec);
    newBuilder._currentIndex = newBuilder._specStack.length - 1;

    newBuilder._sessionHistory = newBuilder._sessionHistory.filter(
      (change) => !change.field?.isEqualTo(ProposalField.Discount)
    );

    if (
      (!newBuilder._specStack[0].discount && !discount) ||
      newBuilder._specStack[0].discount?.isEqualTo(discount)
    ) {
      return newBuilder;
    }
    let action: ProposalChangeAction = ProposalChangeAction.Edit;
    let value = newBuilder._specStack[0].discount?.toString() ?? "";
    if (!newBuilder._specStack[0].discount.value && discount) {
      action = ProposalChangeAction.Add;
      value = discount.toString();
    } else if (newBuilder._specStack[0].discount.value && !discount.value) {
      action = ProposalChangeAction.Remove;
    }
    const specChange = new ProposalSpecChange(
      ProposalField.Discount,
      action,
      value
    );
    newBuilder._sessionHistory.push(specChange);

    return newBuilder;
  }

  public setConflictsDocuments(documents: WorkDocument[]): ProposalBuilder {
    const newBuilder = this.clone();
    newBuilder.resetStack();
    const newSpec = newBuilder.currentSpec.clone();
    newSpec.conflictsDocuments = _.uniqBy(
      documents,
      (document) => document.id.value
    );
    newBuilder._specStack.push(newSpec);
    newBuilder._currentIndex = newBuilder._specStack.length - 1;

    newBuilder._sessionHistory = newBuilder._sessionHistory.filter(
      (change) => change.field?.category !== ProposalFieldCategory.Conflicts
    );
    for (const document of documents) {
      const existingDocument = newBuilder._specStack[0].conflictsDocuments.find(
        (doc) => doc.id.isEqualTo(document.id)
      );
      if (!existingDocument) {
        const specChange = new ProposalSpecChange(
          ProposalField.ConflictsDocument(document.id),
          ProposalChangeAction.Add,
          document.name?.value ?? ""
        );
        newBuilder._sessionHistory.push(specChange);
      }
    }
    for (const document of newBuilder._specStack[0]?.conflictsDocuments ?? []) {
      const removedDocument = !documents.find((doc) =>
        doc.id.isEqualTo(document.id)
      );
      if (removedDocument) {
        const specChange = new ProposalSpecChange(
          ProposalField.ConflictsDocument(document.id),
          ProposalChangeAction.Remove,
          document.name?.value ?? ""
        );
        newBuilder._sessionHistory.push(specChange);
      }
    }

    if (
      newBuilder._specStack[0]?.conflictsCheckWaived?.value !==
      newBuilder.currentSpec?.conflictsCheckWaived?.value
    ) {
      const specChange = new ProposalSpecChange(
        ProposalField.WaiveConflictsCheck,
        newBuilder.currentSpec?.conflictsCheckWaived?.value
          ? ProposalChangeAction.Add
          : ProposalChangeAction.Remove,
        "Conflicts Check Waiver"
      );
      newBuilder._sessionHistory.push(specChange);
    }
    return newBuilder;
  }

  public setClientPolicyDocuments(documents: WorkDocument[]): ProposalBuilder {
    const newBuilder = this.clone();
    newBuilder.resetStack();
    const newSpec = newBuilder.currentSpec.clone();
    newSpec.clientPolicyDocuments = _.uniqBy(
      documents,
      (document) => document.id.value
    );
    newBuilder._specStack.push(newSpec);
    newBuilder._currentIndex = newBuilder._specStack.length - 1;

    newBuilder._sessionHistory = newBuilder._sessionHistory.filter(
      (change) => change.field?.name !== ProposalFieldName.ClientPolicies
    );
    for (const document of documents) {
      const existingDocument =
        newBuilder._specStack[0].clientPolicyDocuments.find((doc) =>
          doc.id.isEqualTo(document.id)
        );
      if (!existingDocument) {
        const specChange = new ProposalSpecChange(
          ProposalField.ClientPolicyDocument(document.id),
          ProposalChangeAction.Add,
          document.name?.value ?? ""
        );
        newBuilder._sessionHistory.push(specChange);
      }
    }
    for (const document of newBuilder._specStack[0].clientPolicyDocuments ??
    []) {
      const removedDocument = !documents.find((doc) =>
        doc.id.isEqualTo(document.id)
      );
      if (removedDocument) {
        const specChange = new ProposalSpecChange(
          ProposalField.ClientPolicyDocument(document.id),
          ProposalChangeAction.Remove,
          document.name?.value ?? ""
        );
        newBuilder._sessionHistory.push(specChange);
      }
    }

    return newBuilder;
  }

  public setVendorPolicyDocuments(documents: WorkDocument[]): ProposalBuilder {
    const newBuilder = this.clone();
    newBuilder.resetStack();
    const newSpec = newBuilder.currentSpec.clone();
    newSpec.vendorPolicyDocuments = _.uniqBy(
      documents,
      (document) => document.id.value
    );
    newBuilder._specStack.push(newSpec);
    newBuilder._currentIndex = newBuilder._specStack.length - 1;

    newBuilder._sessionHistory = newBuilder._sessionHistory.filter(
      (change) => change.field?.name !== ProposalFieldName.VendorPolicies
    );
    for (const document of documents) {
      const existingDocument =
        newBuilder._specStack[0]?.vendorPolicyDocuments.find((doc) =>
          doc.id.isEqualTo(document.id)
        );
      if (!existingDocument) {
        const specChange = new ProposalSpecChange(
          ProposalField.VendorPolicyDocument(document.id),
          ProposalChangeAction.Add,
          document.name?.value ?? ""
        );
        newBuilder._sessionHistory.push(specChange);
      }
    }
    for (const document of newBuilder._specStack[0]?.vendorPolicyDocuments ??
    []) {
      const removedDocument = !documents.find((doc) =>
        doc.id.isEqualTo(document.id)
      );
      if (removedDocument) {
        const specChange = new ProposalSpecChange(
          ProposalField.VendorPolicyDocument(document.id),
          ProposalChangeAction.Remove,
          document.name?.value ?? ""
        );
        newBuilder._sessionHistory.push(specChange);
      }
    }
    return newBuilder;
  }

  public setClientTeamTemplateIds(templateIds: Guid[]): ProposalBuilder {
    const newBuilder = this.clone();
    newBuilder.resetStack();
    const newSpec = newBuilder.currentSpec.clone();
    newSpec.clientTeamTemplateIds = _.uniqBy(
      templateIds,
      (id) => id.value
    );
    newBuilder._specStack.push(newSpec);
    newBuilder._currentIndex = newBuilder._specStack.length - 1;
    return newBuilder;
  }

  public setVendorTeamTemplateIds(templateIds: Guid[]): ProposalBuilder {
    const newBuilder = this.clone();
    newBuilder.resetStack();
    const newSpec = newBuilder.currentSpec.clone();
    newSpec.vendorTeamTemplateIds = _.uniqBy(
      templateIds,
      (id) => id.value
    );
    newBuilder._specStack.push(newSpec);
    newBuilder._currentIndex = newBuilder._specStack.length - 1;
    return newBuilder;
  }

  public setClientFeeScheduleTemplateIds(templateIds: Guid[]): ProposalBuilder {
    const newBuilder = this.clone();
    newBuilder.resetStack();
    const newSpec = newBuilder.currentSpec.clone();
    newSpec.clientFeeScheduleTemplateIds = _.uniqBy(
      templateIds,
      (id) => id.value
    );
    newBuilder._specStack.push(newSpec);
    newBuilder._currentIndex = newBuilder._specStack.length - 1;
    return newBuilder;
  }

  public setVendorFeeScheduleTemplateIds(templateIds: Guid[]): ProposalBuilder {
    const newBuilder = this.clone();
    newBuilder.resetStack();
    const newSpec = newBuilder.currentSpec.clone();
    newSpec.vendorFeeScheduleTemplateIds = _.uniqBy(
      templateIds,
      (id) => id.value
    );
    newBuilder._specStack.push(newSpec);
    newBuilder._currentIndex = newBuilder._specStack.length - 1;
    return newBuilder;
  }

  public setConflictsCheckWaived(waived: AHBoolean): ProposalBuilder {
    if (this.currentSpec?.conflictsCheckWaived?.value === waived?.value) {
      return this;
    }
    const newBuilder = this.clone();
    newBuilder.resetStack();
    const newSpec = newBuilder.currentSpec.clone();
    newSpec.conflictsCheckWaived = waived;
    newBuilder._specStack.push(newSpec);
    newBuilder._currentIndex = newBuilder._specStack.length - 1;

    newBuilder._sessionHistory = newBuilder._sessionHistory.filter(
      (change) => !change.field?.isEqualTo(ProposalField.WaiveConflictsCheck)
    );
    if (
      newBuilder._specStack[0]?.conflictsCheckWaived?.value === waived?.value
    ) {
      return newBuilder;
    }
    const specChange = new ProposalSpecChange(
      ProposalField.WaiveConflictsCheck,
      waived.value ? ProposalChangeAction.Add : ProposalChangeAction.Remove,
      "Conflicts Check Waiver"
    );
    newBuilder._sessionHistory.push(specChange);

    return newBuilder;
  }

  public setTeamRestricted(teamRestricted: AHBoolean): ProposalBuilder {
    if (this.currentSpec?.teamRestricted?.value === teamRestricted?.value) {
      return this;
    }

    const newBuilder = this.clone();
    newBuilder.resetStack();
    const newSpec = newBuilder.currentSpec.clone();
    newSpec.teamRestricted = new AHBoolean(teamRestricted.value);
    newBuilder._specStack.push(newSpec);
    newBuilder._currentIndex = newBuilder._specStack.length - 1;

    newBuilder._sessionHistory = newBuilder._sessionHistory.filter(
      (change) => !change.field?.isEqualTo(ProposalField.TeamRestriction)
    );
    if (
      newBuilder._specStack[0]?.teamRestricted?.value === teamRestricted?.value
    ) {
      return newBuilder;
    }
    const specChange = new ProposalSpecChange(
      ProposalField.TeamRestriction,
      teamRestricted.value
        ? ProposalChangeAction.Add
        : ProposalChangeAction.Remove,
      "Team Restriction"
    );
    newBuilder._sessionHistory.push(specChange);

    return newBuilder;
  }

  public setClientReviewers(newReviewers: ProposalReviewer[]): ProposalBuilder {
    if (
      this.currentSpec.clientReviewers?.length === newReviewers.length &&
      this.currentSpec.clientReviewers.every((currentReviewer) =>
        newReviewers.some(r => r.isEqualTo(currentReviewer))
      )
    ) {
      return this;
    }
    const newBuilder = this.clone();
    newBuilder.resetStack();
    const newSpec = newBuilder.currentSpec.clone();
    newSpec.clientReviewers = newReviewers;
    newBuilder._specStack.push(newSpec);
    newBuilder._currentIndex = newBuilder._specStack.length - 1;
    return newBuilder;
  }

  public setVendorReviewers(reviewers: ProposalReviewer[]): ProposalBuilder {
    const newBuilder = this.clone();
    newBuilder.resetStack();
    const newSpec = newBuilder.currentSpec.clone();
    newSpec.vendorReviewers = reviewers;
    newBuilder._specStack.push(newSpec);
    newBuilder._currentIndex = newBuilder._specStack.length - 1;
    return newBuilder;
  }

  public buildDraft(session: Readonly<Session>): Proposal {
    return ProposalFactory.createProposal(
      this.completedSpec,
      this._currentUser,
      session
    );
  }

  public updateProposal(
    oldProposal: Proposal,
    session: Readonly<Session>
  ): Proposal {
    return ProposalFactory.updateProposal(
      oldProposal,
      this._currentUser,
      this.completedSpec,
      session
    );
  }

  public clone(): ProposalBuilder {
    const clone = new ProposalBuilder(this._currentUser);
    clone._specStack = this._specStack.map((spec) => spec.clone());
    clone._sessionHistory = this._sessionHistory.map((change) =>
      change.clone()
    );
    clone._currentIndex = this._currentIndex;
    return clone;
  }

  public resetHistory(): ProposalBuilder {
    const newBuilder = this.clone();
    newBuilder._specStack = [];
    newBuilder._specStack.push(this.currentSpec);
    newBuilder._currentIndex = 0;
    return newBuilder;
  }

  public mergeUpdatedSpec(updatedSpec: CompleteProposalSpec): ProposalBuilder {
    let newBuilder = new ProposalBuilder(
      this._currentUser,
      updatedSpec
    );

    const originalName = this._specStack[0].name;
    const nameUnchanged =
      (!updatedSpec.name && !originalName) ||
      updatedSpec.name?.isEqualTo(originalName);
    if (nameUnchanged && this.currentSpec.name) {
      newBuilder = newBuilder.setName(this.currentSpec.name);
    }

    const originalDescription = this._specStack[0].description;
    const descriptionUnchanged =
      (!updatedSpec.description && !originalDescription) ||
      updatedSpec.description?.isEqualTo(originalDescription);
    if (descriptionUnchanged && this.currentSpec.description) {
      newBuilder = newBuilder.setDescription(this.currentSpec.description);
    }

    const originalNegotiable = this._specStack[0].negotiable;
    const negotiableUnchanged =
      (!updatedSpec.negotiable && !originalNegotiable) ||
      updatedSpec.negotiable === originalNegotiable;
    if (negotiableUnchanged && this.currentSpec.negotiable !== undefined) {
      newBuilder = newBuilder.setNegotiable(this.currentSpec.negotiable);
    }

    const originalResponseDueBy = this._specStack[0].responseDueBy;
    const responseDueByUnchanged =
      (!updatedSpec.responseDueBy && !originalResponseDueBy) ||
      updatedSpec.responseDueBy?.isEqualTo(originalResponseDueBy);
    if (responseDueByUnchanged) {
      newBuilder = newBuilder.setResponseDueBy(this.currentSpec.responseDueBy);
    }

    const originalStartDate = this._specStack[0].startDate;
    const startDateUnchanged =
      (!updatedSpec.startDate && !originalStartDate) ||
      updatedSpec.startDate?.isEqualTo(originalStartDate);
    if (startDateUnchanged) {
      newBuilder = newBuilder.setStartDate(this.currentSpec.startDate);
    }

    const originalEndDate = this._specStack[0].endDate;
    const endDateUnchanged =
      (!updatedSpec.endDate && !originalEndDate) ||
      updatedSpec.endDate?.isEqualTo(originalEndDate);
    if (endDateUnchanged) {
      newBuilder = newBuilder.setEndDate(this.currentSpec.endDate);
    }

    const originalTeam = this._specStack[0].team;
    const teamUnchanged =
      (!updatedSpec.team && !originalTeam) ||
      updatedSpec.team?.isEqualTo(originalTeam);
    if (teamUnchanged) {
      newBuilder = newBuilder.setTeam(this.currentSpec.team);
    } else {
      newBuilder = newBuilder.setTeam(
        ProposalBuilder.mergeTeams(
          originalTeam,
          this.currentSpec.team,
          updatedSpec.team
        )
      );
    }

    const originalFeeSchedule = this._specStack[0].feeSchedule;
    const feeScheduleUnchanged =
      (!updatedSpec.feeSchedule && !originalFeeSchedule) ||
      (updatedSpec.feeSchedule?.every((cat) =>
          originalFeeSchedule?.some((originalCat) => originalCat.isEqualTo(cat))
        ) &&
        originalFeeSchedule?.every((cat) =>
          updatedSpec.feeSchedule?.some((newCat) => newCat.isEqualTo(cat))
        ));

    if (feeScheduleUnchanged) {
      newBuilder = newBuilder.setFeeSchedule(this.currentSpec.feeSchedule);
    } else {
      newBuilder = newBuilder.setFeeSchedule(
        ProposalBuilder.mergeFeeSchedules(
          originalFeeSchedule,
          this.currentSpec.feeSchedule,
          updatedSpec.feeSchedule
        )
      );
    }

    const originalDiscount = this._specStack[0].discount;
    const discountUnchanged =
      (!updatedSpec.discount && !originalDiscount) ||
      updatedSpec.discount?.isEqualTo(originalDiscount);
    if (discountUnchanged) {
      newBuilder = newBuilder.setDiscount(this.currentSpec.discount);
    }

    const originalConflictsDocuments = this._specStack[0].conflictsDocuments;
    const conflictsDocumentsUnchanged =
      (!updatedSpec.conflictsDocuments && !originalConflictsDocuments) ||
      (updatedSpec.conflictsDocuments?.every((doc) =>
          originalConflictsDocuments?.some((originalDoc) =>
            originalDoc.isEqualTo(doc)
          )
        ) &&
        originalConflictsDocuments?.every((doc) =>
          updatedSpec.conflictsDocuments?.some((newDoc) =>
            newDoc.isEqualTo(doc)
          )
        ));
    if (conflictsDocumentsUnchanged) {
      newBuilder = newBuilder.setConflictsDocuments(
        this.currentSpec.conflictsDocuments
      );
    } else {
      newBuilder = newBuilder.setConflictsDocuments(
        ProposalBuilder.mergeDocuments(
          originalConflictsDocuments,
          this.currentSpec.conflictsDocuments,
          updatedSpec.conflictsDocuments
        )
      );
    }

    const originalClientPolicyDocuments =
      this._specStack[0].clientPolicyDocuments;
    const clientPolicyDocumentsUnchanged =
      (!updatedSpec.clientPolicyDocuments && !originalClientPolicyDocuments) ||
      (updatedSpec.clientPolicyDocuments?.every((doc) =>
          originalClientPolicyDocuments?.some((originalDoc) =>
            originalDoc.isEqualTo(doc)
          )
        ) &&
        originalClientPolicyDocuments?.every((doc) =>
          updatedSpec.clientPolicyDocuments?.some((newDoc) =>
            newDoc.isEqualTo(doc)
          )
        ));
    if (clientPolicyDocumentsUnchanged) {
      newBuilder = newBuilder.setClientPolicyDocuments(
        this.currentSpec.clientPolicyDocuments
      );
    } else {
      newBuilder = newBuilder.setClientPolicyDocuments(
        ProposalBuilder.mergeDocuments(
          originalClientPolicyDocuments,
          this.currentSpec.clientPolicyDocuments,
          updatedSpec.clientPolicyDocuments
        )
      );
    }

    const originalVendorPolicyDocuments =
      this._specStack[0].vendorPolicyDocuments;
    const vendorPolicyDocumentsUnchanged =
      (!updatedSpec.vendorPolicyDocuments && !originalVendorPolicyDocuments) ||
      (updatedSpec.vendorPolicyDocuments?.every((doc) =>
          originalVendorPolicyDocuments?.some((originalDoc) =>
            originalDoc.isEqualTo(doc)
          )
        ) &&
        originalVendorPolicyDocuments?.every((doc) =>
          updatedSpec.vendorPolicyDocuments?.some((newDoc) =>
            newDoc.isEqualTo(doc)
          )
        ));
    if (vendorPolicyDocumentsUnchanged) {
      newBuilder = newBuilder.setVendorPolicyDocuments(
        ProposalBuilder.mergeDocuments(
          originalVendorPolicyDocuments,
          this.currentSpec.vendorPolicyDocuments,
          updatedSpec.vendorPolicyDocuments
        )
      );
    }

    return newBuilder;
  }

  private static mergeDocuments(
    originalDocuments: WorkDocument[],
    currentDocuments: WorkDocument[],
    newDocuments: WorkDocument[]
  ): WorkDocument[] {
    const removedDocuments: WorkDocument[] = [];
    for (const originalDocument of originalDocuments ?? []) {
      const documentRemoved =
        !newDocuments.find((newDocument) =>
          newDocument.isEqualTo(originalDocument)
        ) ||
        !currentDocuments.find((currentDocument) =>
          currentDocument.isEqualTo(originalDocument)
        );
      if (documentRemoved) {
        removedDocuments.push(originalDocument);
      }
    }

    const mergedDocuments = newDocuments.filter(
      (updatedDocument) =>
        !removedDocuments.some((removedDocument) =>
          removedDocument.isEqualTo(updatedDocument)
        )
    );
    for (const document of currentDocuments ?? []) {
      if (
        !mergedDocuments?.find((newDocument) =>
          newDocument.isEqualTo(document)
        ) &&
        !removedDocuments.find((removedDocument) =>
          removedDocument.isEqualTo(document)
        )
      ) {
        mergedDocuments?.push(document);
      }
    }
    return mergedDocuments;
  }

  private static mergeTeams(
    originalTeam?: DetailedTeam,
    currentTeam?: DetailedTeam,
    newTeam?: DetailedTeam
  ): DetailedTeam {
    let leader = newTeam?.leader;
    const leaderUnchanged =
      (!newTeam?.leader && !originalTeam?.leader) ||
      newTeam?.leader?.isEqualTo(originalTeam?.leader);
    if (leaderUnchanged) {
      leader = currentTeam?.leader;
    }

    const removedMembers: Individual[] = [];
    for (const originalMember of originalTeam?.members ?? []) {
      const memberRemoved =
        !newTeam?.members.find((newMember) =>
          newMember.isEqualTo(originalMember)
        ) ||
        !currentTeam?.members.find((currentMember) =>
          currentMember.isEqualTo(originalMember)
        );
      if (memberRemoved) {
        removedMembers.push(originalMember);
      }
    }

    const mergedMembers = newTeam?.members.filter(
      (newMember) =>
        !removedMembers.some((removedMember) =>
          removedMember.isEqualTo(newMember)
        )
    );
    for (const member of currentTeam?.members ?? []) {
      if (
        !mergedMembers?.find((newMember) => newMember.isEqualTo(member)) &&
        !removedMembers.find((removedMember) => removedMember.isEqualTo(member))
      ) {
        mergedMembers?.push(member);
      }
    }
    return new DetailedTeam(
      leader,
      mergedMembers ?? []
    );
  }

  private static mergeFeeSchedules(
    originalFeeSchedule: FeeScheduleCategory[],
    currentFeeSchedule: FeeScheduleCategory[],
    newFeeSchedule: FeeScheduleCategory[]
  ): FeeScheduleCategory[] {
    const removedCategories: FeeScheduleCategory[] = [];
    for (const originalCategory of originalFeeSchedule ?? []) {
      const categoryRemoved =
        !newFeeSchedule?.find((newCategory) =>
          newCategory.id.isEqualTo(originalCategory.id)
        ) ||
        !currentFeeSchedule?.find((currentCategory) =>
          currentCategory.id.isEqualTo(originalCategory.id)
        );

      const currentCategory = currentFeeSchedule?.find((currentCategory) =>
        currentCategory.id.isEqualTo(originalCategory.id)
      );
      const modifiedLocaly =
        currentCategory && !currentCategory.isEqualTo(originalCategory);
      if (categoryRemoved && !modifiedLocaly) {
        removedCategories.push(originalCategory);
      }
    }

    const mergedCategories = newFeeSchedule.filter(
      (updatedCategory) =>
        !removedCategories.some((removedCategory) =>
          removedCategory.id.isEqualTo(updatedCategory.id)
        )
    );
    for (const category of currentFeeSchedule ?? []) {
      if (
        !mergedCategories?.find((newCategory) =>
          newCategory.id.isEqualTo(category.id)
        ) &&
        !removedCategories.find((removedCategory) =>
          removedCategory.id.isEqualTo(category.id)
        )
      ) {
        mergedCategories?.push(category);
      }
    }
    return mergedCategories;
  }

  private resetStack() {
    if (this._currentIndex < this._specStack.length - 1) {
      this._specStack.push(this._specStack[this._currentIndex]);
    }
    this._currentIndex = this._specStack.length - 1;
  }

  private get completedSpec(): CompleteProposalSpec {
    const completedSpec: CompleteProposalSpec = {
      name: this.currentSpec.name,
      client: this.currentSpec.client,
      vendors: this.currentSpec.vendors,
      description: this.currentSpec.description,
      negotiable: this.currentSpec.negotiable ?? true,
      responseDueBy: this.currentSpec.responseDueBy,
      startDate: this.currentSpec.startDate,
      endDate: this.currentSpec.endDate,
      clientReviewers: this.currentSpec.clientReviewers ?? [],
      vendorReviewers: this.currentSpec.vendorReviewers ?? [],
      vendorFeeScheduleTemplateIds:
        this.currentSpec.vendorFeeScheduleTemplateIds ?? [],
      clientFeeScheduleTemplateIds:
        this.currentSpec.clientFeeScheduleTemplateIds ?? [],
      vendorTeamTemplateIds: this.currentSpec.vendorTeamTemplateIds ?? [],
      clientTeamTemplateIds: this.currentSpec.clientTeamTemplateIds ?? [],
      vendorPolicyDocuments: this.currentSpec.vendorPolicyDocuments ?? [],
      clientPolicyDocuments: this.currentSpec.clientPolicyDocuments ?? [],
      conflictsDocuments: this.currentSpec.conflictsDocuments ?? [],
      conflictsCheckWaived:
        this.currentSpec.conflictsCheckWaived ?? new AHBoolean(false),
      teamRestricted: this.currentSpec.teamRestricted ?? new AHBoolean(false),
      team: this.currentSpec.team,
      feeSchedule: this.currentSpec.feeSchedule ?? [],
      discount: this.currentSpec.discount ?? new Percent(0),
    };

    return completedSpec;
  }
}
