import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Checkbox,
  Chip,
  CircularProgress,
  Collapse,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  styled,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import {CanceledError} from "axios";
import {AccountType} from "common/values/account-type/account-type";
import Guid from "common/values/guid/guid";
import _ from "lodash";
import {enqueueSnackbar} from "notistack";
import React, {useEffect, useState} from "react";
import {useSession} from "users/session/session-context";
import CommentThread from "work/entities/comment-thread/comment-thread";
import FeeScheduleTemplateAPIService from "work/entities/fee-schedule-template/api/fee-schedule-template-api-service";
import FeeScheduleTemplate from "work/entities/fee-schedule-template/fee-schedule-template";
import {
  ProposalField,
  ProposalFieldCategory,
} from "work/entities/proposal/proposal";
import ProposalBuilder from "work/entities/proposal/utils/proposal-builder";
import FeeScheduleCategoryName from "work/values/fee-schedule-category-name/fee-schedule-category-name";
import FeeScheduleCategory from "work/values/fee-schedule-category/fee-schedule-category";
import FeeScheduleCategories from "work/values/fee-schedule-category/view/fee-schedule-categories";
import FeeScheduleTag from "work/values/fee-schedule-tag/fee-schedule-tag";
import ProposalIssues from "work/values/proposal-issues/proposal-issues";

const ListContainer = styled(Grid)(
  ({theme}) => ({
    minHeight: "20rem",
  }));
const TemplateContainer = styled(Grid)(
  ({theme}) => ({
    [theme.breakpoints.down("lg")]: {
      width: "100%",
    },
  }));
const TemplateList = styled(List)(
  ({theme}) => ({
    marginRight: theme.spacing(2),
    maxHeight: "25rem",
    minWidth: "20rem",
    overflowY: "auto",
  }));
const TemplateLoader = styled(CircularProgress)(
  ({theme}) => ({
    color: theme.palette.primary.main,
    margin: theme.spacing(2),
  }));
const FeeScheduleTagChip = styled(Chip)(
  ({theme}) => ({
    color: "#777",
    marginRight: theme.spacing(0.25),
  }));
const CategorySelection = styled(FeeScheduleCategories)(
  ({theme}) => ({
    [theme.breakpoints.down("lg")]: {
      marginTop: theme.spacing(2),
    },
    height: "100%",
    marginLeft: theme.spacing(2),
  }));

type SelectFeeScheduleProps = {
  entityId: Guid;
  activeTab: ProposalFieldCategory;
  proposalBuilder: ProposalBuilder;
  issues?: ProposalIssues;
  disableCommenting: boolean;
  commentThreads?: CommentThread[];
  disableEditing?: boolean;
  onCommentsClicked: (field: ProposalField, name?: string) => void;
  onProposalBuilderUpdated: (updatedProposalBuilder: ProposalBuilder) => void;
};

export default function FeeScheduleTab(
  props: Readonly<SelectFeeScheduleProps>
) {
  const {
    entityId,
    activeTab,
    proposalBuilder,
    disableCommenting,
    commentThreads,
    disableEditing,
    onCommentsClicked,
    onProposalBuilderUpdated,
  } = props;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [templateFeeSchedules, setTemplateFeeSchedules] = useState<
    FeeScheduleTemplate[]
  >([]);
  const [selectedCategories, setSelectedCategories] = useState<
    FeeScheduleCategory[]
  >([]);

  const isLargeDownDisplay = useMediaQuery((theme: any) =>
    theme.breakpoints.down("lg")
  );
  const session = useSession();

  useEffect(
    () => {
      if (activeTab !== ProposalFieldCategory.FeeSchedule) return;

      initializeFeeSchedule();
      let abortController = new AbortController();
      getTemplateFeeSchedules(abortController);

      return () => {
        abortController.abort();
        abortController = new AbortController();
      };
    },
    [
      proposalBuilder,
      activeTab
    ]
  );

  function initializeFeeSchedule() {
    setSelectedCategories(proposalBuilder.currentSpec.feeSchedule ?? []);
  }

  async function getTemplateFeeSchedules(
    abortController: AbortController
  ): Promise<void> {
    if (!entityId || !session.user?.isCompanyManager) return;

    try {
      setIsLoading(true);

      const feeScheduleService = new FeeScheduleTemplateAPIService(session);
      const templates = await feeScheduleService.getFeeScheduleTemplates(
        entityId,
        session.context?.viewingAsVendor
          ? AccountType.Vendor
          : AccountType.Client,
        abortController
      );
      setTemplateFeeSchedules(templates);
    } catch (error) {
      if (error instanceof CanceledError) return;
      console.error(error);
    }
    setIsLoading(false);
  }

  function handleTemplateExpanded(template: FeeScheduleTemplate) {
    let templates = [...templateFeeSchedules];
    let selectedTemplate = templates.find((feeSchedule) =>
      feeSchedule.id?.isEqualTo(template.id)
    );
    template.isExpanded = !selectedTemplate?.isExpanded;

    setTemplateFeeSchedules(templates);
  }

  function handleTemplateSelectionToggled(template: FeeScheduleTemplate) {
    let updatedSelectedTemplateIds = session.context?.viewingAsVendor
      ? [...proposalBuilder.currentSpec.vendorFeeScheduleTemplateIds]
      : [...proposalBuilder.currentSpec.clientFeeScheduleTemplateIds];
    try {
      if (!template.id) throw new Error("No template selected");


      let updatedProposalBuilder = proposalBuilder;
      if (updatedSelectedTemplateIds.some((id) => id.isEqualTo(template.id))) {
        updatedSelectedTemplateIds = updatedSelectedTemplateIds.filter(
          (id) => !id.isEqualTo(template.id)
        );
      } else {
        updatedSelectedTemplateIds.push(template.id);
        const categories = _.uniqWith(
          [
            ...selectedCategories,
            ...template.categories
          ],
          (a, b) => a.name?.isEqualTo(b.name) ?? false
        );
        updatedProposalBuilder = proposalBuilder.setFeeSchedule(categories);
      }
      updatedProposalBuilder = session.context?.viewingAsVendor
        ? updatedProposalBuilder.setVendorFeeScheduleTemplateIds(
          updatedSelectedTemplateIds
        )
        : updatedProposalBuilder.setClientFeeScheduleTemplateIds(
          updatedSelectedTemplateIds
        );

      onProposalBuilderUpdated(updatedProposalBuilder);
      onCommentsClicked(
        ProposalField.FeeSchedule,
        undefined
      );
    } catch (error) {
      console.error(error);
      enqueueSnackbar(
        "Failed to apply template. Please try again",
        {
          variant: "error",
        }
      );
    }
  }

  function handleTemplateCategoryToggled(
    event: React.MouseEvent,
    selectedCategory: FeeScheduleCategory
  ) {
    event.stopPropagation();
    const selectedTemplateIds = session.context?.viewingAsVendor
      ? proposalBuilder.currentSpec.vendorFeeScheduleTemplateIds
      : proposalBuilder.currentSpec.clientFeeScheduleTemplateIds;

    let updatedTemplateIds = session.context?.viewingAsVendor
      ? [...proposalBuilder.currentSpec.vendorFeeScheduleTemplateIds]
      : [...proposalBuilder.currentSpec.clientFeeScheduleTemplateIds];

    if (selectedTemplateIds.some((id) => id.isEqualTo(selectedCategory.id))) {
      updatedTemplateIds = updatedTemplateIds.filter(
        (id) => !id.isEqualTo(selectedCategory.id)
      );
    } else {
      updatedTemplateIds.push(selectedCategory.id);
    }
    let updatedProposalBuilder = session.context?.viewingAsVendor
      ? proposalBuilder.setVendorFeeScheduleTemplateIds(updatedTemplateIds)
      : proposalBuilder.setClientFeeScheduleTemplateIds(updatedTemplateIds);


    let updatedCategories = [...selectedCategories];
    if (
      selectedCategories.some((category) =>
        category.id.isEqualTo(selectedCategory.id)
      )
    ) {
      updatedCategories = updatedCategories.filter(
        (category) => !category.id.isEqualTo(selectedCategory.id)
      );
    } else {
      updatedCategories.push(selectedCategory);
    }
    updatedProposalBuilder = updatedProposalBuilder.setFeeSchedule(
      updatedCategories
    );


    onProposalBuilderUpdated(updatedProposalBuilder);
    onCommentsClicked(
      ProposalField.FeeSchedule,
      undefined
    );

  }

  function handleCategoryAdded(category: FeeScheduleCategory) {
    if (selectedCategories.some((c) => c.id?.isEqualTo(category.id))) {
      enqueueSnackbar(
        "Fee category with the same name is already in the fee schedule",
        {variant: "warning"}
      );
      return;
    }

    const updatedCategories = [
      ...selectedCategories,
      new FeeScheduleCategory(
        category.name,
        category.description ?? null,
        category.fee,
        category.billingCode ?? null,
        Guid.generate()
      ),
    ];

    onProposalBuilderUpdated(proposalBuilder.setFeeSchedule(updatedCategories));
    onCommentsClicked(
      ProposalField.FeeSchedule,
      undefined
    );
  }

  function handleCategoryRemoved(categoryName: FeeScheduleCategoryName) {
    const updatedCategories = selectedCategories.filter(
      (category) => !category.name?.isEqualTo(categoryName)
    );

    onProposalBuilderUpdated(proposalBuilder.setFeeSchedule(updatedCategories));
    onCommentsClicked(
      ProposalField.FeeSchedule,
      undefined
    );
  }

  function handleCategoryUpdated(
    originalName: FeeScheduleCategoryName | null,
    updatedCategory: FeeScheduleCategory
  ) {
    if (originalName === null) return;

    if (
      selectedCategories.some((c) => c.id?.isEqualTo(updatedCategory.id)) &&
      originalName?.toString() !== updatedCategory.name?.toString()
    ) {
      enqueueSnackbar(
        "Fee category with the same name is already in the fee schedule",
        {variant: "warning"}
      );
      return;
    }

    const updatedCategories = selectedCategories.map((category) => {
      if (category.name?.isEqualTo(originalName)) return updatedCategory;
      return category;
    });

    onProposalBuilderUpdated(proposalBuilder.setFeeSchedule(updatedCategories));
    onCommentsClicked(
      ProposalField.FeeSchedule,
      undefined
    );
  }

  function renderTemplates() {
    const selectedTemplateIds = session.context?.viewingAsVendor
      ? proposalBuilder.currentSpec.vendorFeeScheduleTemplateIds
      : proposalBuilder.currentSpec.clientFeeScheduleTemplateIds;
    return (
      <TemplateList subheader={<ListSubheader>Templates</ListSubheader>}>
        {templateFeeSchedules.length === 0 && (
          <>
            {isLoading ? (
              <TemplateLoader size={32} thickness={4}/>
            ) : (
              <ListItem key="noTemplates">
                <ListItemText>No Templates</ListItemText>
              </ListItem>
            )}
          </>
        )}
        {templateFeeSchedules.map((template: FeeScheduleTemplate) => {
          const templateIsSelected = selectedTemplateIds.some((id) =>
            id.isEqualTo(template.id)
          );

          return (
            <div key={template.id?.toString()}>
              <ListItem
                secondaryAction={
                  <Tooltip
                    title={`${
                      template.isExpanded ? "Hide" : "Show"
                    } Categories`}
                  >
                  <span>
                    <IconButton
                      edge="end"
                      onClick={() => handleTemplateExpanded(template)}
                      size="medium"
                    >
                      {template.isExpanded ? (
                        <ExpandLessIcon/>
                      ) : (
                        <ExpandMoreIcon/>
                      )}
                    </IconButton>
                  </span>
                  </Tooltip>

                }>
                <ListItemIcon>
                  <Tooltip
                    title={
                      !templateIsSelected
                        ? "Select Template"
                        : "Deselect Template"
                    }
                  >
                    <span>
                      <Checkbox
                        edge="start"
                        color="primary"
                        checked={templateIsSelected}
                        indeterminate={
                          !templateIsSelected &&
                          template.isUsing(selectedCategories)
                        }
                        tabIndex={-1}
                        disableRipple
                        onChange={() =>
                          handleTemplateSelectionToggled(template)
                        }
                      />
                    </span>
                  </Tooltip>
                </ListItemIcon>
                <ListItemText
                  primary={template.name}
                  secondary={template.tags.map((tag: FeeScheduleTag) => (
                    <FeeScheduleTagChip
                      key={tag.value}
                      size="small"
                      label={tag.value}
                    />
                  ))}
                />
              </ListItem>
              <Collapse
                in={template.isExpanded}
                timeout="auto"
                style={{backgroundColor: "#FAFAFA"}}
              >
                <Divider/>
                <List disablePadding dense>
                  {renderTemplateCategories(template)}
                </List>
                <Divider/>
              </Collapse>
            </div>
          );
        })}
      </TemplateList>
    );
  }

  function renderTemplateCategories(template: FeeScheduleTemplate) {
    return template.categories.map((category: FeeScheduleCategory) => {
      const categoryIsSelected = selectedCategories.some((selectedCategory) =>
        selectedCategory.id.isEqualTo(category.id)
      );
      return (
        <ListItem
          key={category.name.value}
          onClick={(event) => handleTemplateCategoryToggled(
            event,
            category
          )}
          secondaryAction={
            <Checkbox
              edge="end"
              color="primary"
              checked={categoryIsSelected}
              tabIndex={-1}
              disableRipple
              onClick={(event) =>
                handleTemplateCategoryToggled(
                  event,
                  category
                )
              }
            />
          }
        >
          <ListItemText
            primary={category.name.value}
            secondary={
              <>
                {category.description?.value}
                <br/>
                {category.feeString}
              </>
            }
          />
        </ListItem>
      );
    });
  }

  if (activeTab !== ProposalFieldCategory.FeeSchedule) return null;

  return (
    <ListContainer container direction="row">
      {!disableEditing && (
        <>
          <TemplateContainer>
            {renderTemplates()}
            {isLargeDownDisplay && <Divider/>}
          </TemplateContainer>
          {!isLargeDownDisplay && (
            <Grid>
              <Divider orientation="vertical"/>
            </Grid>
          )}
        </>
      )}
      <Grid size="grow">
        <CategorySelection
          categories={selectedCategories}
          hideFromTemplateButton={true}
          disableCommenting={disableCommenting}
          commentThreads={commentThreads}
          disableEditing={disableEditing}
          onCommentsClicked={(categoryId?: Guid, name?: string) => {
            if (!categoryId) {
              onCommentsClicked(
                ProposalField.FeeSchedule,
                name
              );
            } else {
              onCommentsClicked(
                ProposalField.FeeScheduleCategory(categoryId),
                name
              );
            }
          }}
          onCategoryAdded={handleCategoryAdded}
          onCategoryRemoved={handleCategoryRemoved}
          onCategoryUpdated={handleCategoryUpdated}
        />
      </Grid>
    </ListContainer>
  );
}
