import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import CommentIcon from "@mui/icons-material/Comment";
import EditNoteIcon from "@mui/icons-material/EditNote";
import UndoIcon from "@mui/icons-material/Undo";
import {
  Badge,
  BaseTextFieldProps,
  FormControl,
  Icon,
  IconButton,
  Input,
  InputBaseComponentProps,
  InputProps,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import {lightBlue} from "@mui/material/colors";
import {styled} from "@mui/material/styles";
import Text from "common/values/text/text";
import {enqueueSnackbar} from "notistack";
import React, {forwardRef, useEffect} from "react";
import CommentThread from "work/entities/comment-thread/comment-thread";
import {ProposalField} from "work/entities/proposal/proposal";
import TextChange from "work/entities/proposal/redlining/_diff/text-change";
import DiffText from "work/entities/proposal/redlining/_diff/view/diff-text";
import FieldRedline from "work/entities/proposal/redlining/field-redline";
import {ProposalIssue} from "work/values/proposal-issues/proposal-issues";

const RedlineTextBox = styled(
  TextField,
  {
    shouldForwardProp: (prop) => prop !== "traversable",
  }
)<{ traversable?: boolean }>(
  ({theme, traversable}) => ({
    minWidth: "20rem",
    "& .MuiTypography-root": {
      height: "unset",
    },
    width: "min-content",
    "&.MuiFormControl-root:not(:hover) > :not(.Mui-focused)": {
      // opacity: traversable ? 0.5 : "initial",
    },
    "& .Mui-disabled div span": {
      webkitTextFillColor: "revert",
      opacity: "0.75",
    },
  }));
const ActionButton = styled(IconButton)(
  ({theme}) => ({
    padding: theme.spacing(0.5),
  }));
const TextContainer = styled("div")<{ showTextInput: boolean; bold?: boolean }>(
  ({theme, showTextInput, bold}) => ({
    pointerEvents: showTextInput ? "none" : "auto",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    paddingRight: theme.spacing(2),
    width: "100%",
    fontWeight: bold ? "500" : "normal",
  })
);
const InputContainer = styled("span")(
  ({theme}) => ({
    "&.MuiInputBase-input": {
      display: "flex",
      flexDirection: "column",
      height: "unset",
    },
  }));
const ChangeContainer = styled("div")(
  ({theme}) => ({
    alignItems: "center",
    display: "flex",
    fontSize: "0.875rem",
    width: "100%",
  }));
const DiffHeader = styled(Typography)(
  ({theme}) => ({
    color: lightBlue[500],
    display: "inline-block",
    fontSize: "0.75rem",
    lineHeight: 1,
    minWidth: theme.spacing(13.5),
  }));
const InputField = styled(
  Input,
  {
    shouldForwardProp: (prop) => prop !== "show",
  }
)<{ show: boolean }>(
  ({show, theme}) => ({
    height: show ? "unset" : 0,
    margin: theme.spacing(
      2,
      0,
      1
    ),
    width: show ? "100%" : 0,
    overflow: show ? "visible" : "hidden",
    padding: 0,
    pointerEvents: show ? "auto" : "none",
    position: show ? "relative" : "absolute",
    "& .MuiInputBase-input": {
      padding: 0,
    },
  }));

interface InputSubComponentProps extends InputBaseComponentProps {
  originalTextFieldRedline: FieldRedline<Text>;
  issues?: ProposalIssue[];
  hideCommentsButton: boolean;
  hideAcceptRejectButtons: boolean;
  hideUndoButton: boolean;
  readOnly?: boolean;
  commentThreads: CommentThread[];
  focused: boolean;
  showPlaceholder?: boolean;
  bold?: boolean;
  onTraverseIn?: () => void;
  onTextFieldRedlineChange?: (
    newRedline: FieldRedline<Text>,
    traversalFieldOverride?: ProposalField | null
  ) => void;
  onActionButtonsChange?: (actionButtons: JSX.Element) => void;
  onCommentsClicked?: (setToOpen?: boolean) => void;
}

const InputSubComponent = forwardRef<HTMLInputElement, InputSubComponentProps>(
  (props, forwardedRef) => {
    const {
      className,
      originalTextFieldRedline,
      issues,
      hideCommentsButton,
      hideAcceptRejectButtons,
      hideUndoButton,
      commentThreads,
      readOnly,
      required,
      focused,
      showPlaceholder,
      bold,
      onTraverseIn,
      onTextFieldRedlineChange,
      onCommentsClicked,
      onActionButtonsChange,
      onFocus,
      onBlur,
    } = props;

    const [currentTextValue, setCurrentTextValue] = React.useState<string>("");
    const [currentRedline, setCurrentRedline] = React.useState<
      FieldRedline<Text>
    >(originalTextFieldRedline.clone());
    const [showTextInput, setShowTextInput] = React.useState<boolean>(false);

    useEffect(
      () => {
        setCurrentRedline(originalTextFieldRedline.clone());
        setCurrentTextValue(
          originalTextFieldRedline.currentEntry?.value ??
          originalTextFieldRedline.revisedEntry?.value ??
          ""
        );
      },
      []
    );

    useEffect(
      () => {
        onActionButtonsChange?.(renderActionButtons());
      },
      [
        showTextInput,
        originalTextFieldRedline,
        currentRedline
      ]
    );

    function renderAcceptRejectButtons() {
      return (
        <>
          <Tooltip title="Accept All Changes">
            <span>
              <ActionButton
                onClick={() => {
                  const newRedline = originalTextFieldRedline.accept();
                  setCurrentRedline(newRedline);
                  onTextFieldRedlineChange?.(newRedline);
                }}
              >
                <CheckIcon color="success"/>
              </ActionButton>
            </span>
          </Tooltip>
          <Tooltip title="Reject All Changes">
            <span>
              <ActionButton
                onClick={() => {
                  const newRedline = originalTextFieldRedline.reject();
                  setCurrentRedline(newRedline);
                  onTextFieldRedlineChange?.(newRedline);
                }}
              >
                <CloseIcon color="error"/>
              </ActionButton>
            </span>
          </Tooltip>
        </>
      );
    }

    function renderUndoButton() {
      return (
        <Tooltip title="Undo Changes">
          <span>
            <ActionButton
              onClick={() => {
                const newRedline = originalTextFieldRedline.undo();
                onTextFieldRedlineChange?.(
                  newRedline,
                  {
                    name: newRedline.field,
                    id: newRedline.fieldId,
                  }
                );
              }}
            >
              <UndoIcon/>
            </ActionButton>
          </span>
        </Tooltip>
      );
    }

    function renderEditButton() {
      return (
        <Tooltip title="Edit">
          <span>
            <ActionButton
              onClick={(event) => {
                setCurrentRedline(originalTextFieldRedline.clone());
                setCurrentTextValue(
                  originalTextFieldRedline.currentEntry?.value ??
                  originalTextFieldRedline.revisedEntry?.value ??
                  ""
                );
                setShowTextInput(true);
                onTraverseIn?.();
                onCommentsClicked?.(true);
              }}
            >
              <EditNoteIcon/>
            </ActionButton>
          </span>
        </Tooltip>
      );
    }

    function renderTextInputButtons() {
      let isChanged =
        currentTextValue !==
        (originalTextFieldRedline?.currentEntry?.value ??
          originalTextFieldRedline?.revisedEntry?.value ??
          "");
      if (currentRedline.isResolved) {
        isChanged =
          currentTextValue !== originalTextFieldRedline.currentEntry?.value;
      }
      return (
        <>
          {isChanged && (
            <Tooltip title="Save Change">
              <span>
                <IconButton
                  disabled={required && currentTextValue === ""}
                  onClick={() => {
                    onTextFieldRedlineChange?.(
                      originalTextFieldRedline.edit(new Text(currentTextValue)),
                      {
                        name: originalTextFieldRedline.field,
                        id: originalTextFieldRedline.fieldId,
                      }
                    );
                    setShowTextInput(false);
                  }}
                >
                  <CheckIcon color="primary" fontSize="medium"/>
                </IconButton>
              </span>
            </Tooltip>
          )}
          <Tooltip title="Cancel">
            <span>
              <IconButton
                onClick={() => {
                  setCurrentRedline(originalTextFieldRedline.clone());
                  setCurrentTextValue(
                    originalTextFieldRedline.currentEntry?.value ??
                    originalTextFieldRedline.revisedEntry?.value ??
                    ""
                  );
                  onActionButtonsChange?.(renderActionButtons());
                  setShowTextInput(false);
                  onTraverseIn?.();
                }}
              >
                <CloseIcon fontSize="medium"/>
              </IconButton>
            </span>
          </Tooltip>
        </>
      );
    }

    function renderCommentButton() {
      if (hideCommentsButton) return null;
      return (
        <Tooltip title="Comments">
          <span>
            <IconButton
              onClick={() => {
                onCommentsClicked?.();
                onTraverseIn?.();
              }}
            >
              <Badge
                variant="dot"
                color="secondary"
                overlap="circular"
                invisible={
                  !commentThreads.some((thread: CommentThread) => thread.field.isEqualTo(originalTextFieldRedline.field))
                }
              >
                <CommentIcon fontSize="medium"/>
              </Badge>
            </IconButton>
          </span>
        </Tooltip>
      );
    }

    function renderActionButtons() {
      return (
        <>
          {!showTextInput && !readOnly && (
            <>
              {!hideAcceptRejectButtons &&
                !originalTextFieldRedline.isResolved &&
                renderAcceptRejectButtons()}
              {originalTextFieldRedline.canBeUndone &&
                !hideUndoButton &&
                renderUndoButton()}
              {renderEditButton()}
            </>
          )}
          {showTextInput && renderTextInputButtons()}
          {renderCommentButton()}
        </>
      );
    }

    return (
      <TextContainer bold={bold} showTextInput={showTextInput}>
        <InputContainer className={className}>
          {!showTextInput && (
            <>
              {originalTextFieldRedline.changes.length === 0 &&
                showPlaceholder && <Icon>remove</Icon>}
              <DiffText
                onClick={() => {
                  if (readOnly) return;
                  setShowTextInput(true);
                  onTraverseIn?.();
                  onCommentsClicked?.(true);
                }}
                changes={originalTextFieldRedline.changes}
                allResolved={originalTextFieldRedline.isResolved}
                onAcceptChange={(change: TextChange) => {
                  try {
                    change.accept();
                    let newRedline = originalTextFieldRedline.clone();
                    if (
                      newRedline.changes.every(
                        (change: TextChange) => change.isResolved
                      )
                    ) {
                      const newEntry = new Text(
                        newRedline.changes
                          .map((change: TextChange) => change.diff.value)
                          .join("")
                      );
                      newRedline = newRedline.edit(newEntry);
                      onTextFieldRedlineChange?.(newRedline);
                      return;
                    }
                    onTextFieldRedlineChange?.(
                      newRedline,
                      {
                        name: originalTextFieldRedline.field,
                        id: originalTextFieldRedline.fieldId,
                      }
                    );
                  } catch (error) {
                    console.error(
                      "Error accepting change",
                      error
                    );
                    enqueueSnackbar(
                      "Error accepting change",
                      {
                        variant: "error",
                      }
                    );
                  }
                }}
                onRejectChange={(change: TextChange) => {
                  try {
                    change.reject();
                    let newRedline = originalTextFieldRedline.clone();
                    if (
                      newRedline.changes.every(
                        (change: TextChange) => change.isResolved
                      )
                    ) {
                      const newEntry = new Text(
                        newRedline.changes
                          .map((change: TextChange) => change.diff.value)
                          .join("")
                      );
                      newRedline = newRedline.edit(newEntry);
                      onTextFieldRedlineChange?.(newRedline);
                      return;
                    }
                    onTextFieldRedlineChange?.(
                      newRedline,
                      {
                        name: originalTextFieldRedline.field,
                        id: originalTextFieldRedline.fieldId,
                      }
                    );
                  } catch (error) {
                    console.error(
                      "Error rejecting change",
                      error
                    );
                    enqueueSnackbar(
                      "Error rejecting change",
                      {
                        variant: "error",
                      }
                    );
                  }
                }}
                onEditChange={(change: TextChange, newValue: string) => {
                  try {
                    change.editChange(newValue);
                    let newRedline = originalTextFieldRedline.clone();
                    if (
                      newRedline.changes.every(
                        (change: TextChange) => change.isResolved
                      )
                    ) {
                      const newEntry = new Text(
                        newRedline.changes
                          .map((change: TextChange) => change.diff.value)
                          .join("")
                      );
                      newRedline = newRedline.edit(newEntry);
                    }
                    onTextFieldRedlineChange?.(
                      newRedline,
                      {
                        name: originalTextFieldRedline.field,
                        id: originalTextFieldRedline.fieldId,
                      }
                    );
                  } catch (error) {
                    console.error(
                      "Error editing change",
                      error
                    );
                    enqueueSnackbar(
                      "Error editing change",
                      {
                        variant: "error",
                      }
                    );
                  }
                }}
                disablePopover={readOnly || hideAcceptRejectButtons || false}
              />
            </>
          )}
          {showTextInput && (
            <>
              <ChangeContainer>
                <DiffHeader variant="overline">previously:</DiffHeader>
                {currentRedline.originalEntry?.value}
              </ChangeContainer>
              <ChangeContainer>
                <DiffHeader variant="overline">your changes:</DiffHeader>
                <DiffText
                  disablePopover={true}
                  changes={currentRedline.changes}
                  allResolved={currentRedline.isResolved}
                />
              </ChangeContainer>
            </>
          )}
          <FormControl variant="standard" focused={focused}>
            <InputField
              inputRef={forwardedRef}
              show={Boolean(showTextInput ?? false)}
              placeholder="Enter new text"
              error={issues && issues.length > 0}
              value={currentTextValue}
              onChange={(event) => {
                try {
                  const updatedRedline = currentRedline.edit(
                    new Text(event.target.value)
                  );
                  setCurrentTextValue(event.target.value);
                  setCurrentRedline(updatedRedline);
                  onActionButtonsChange?.(renderActionButtons());
                } catch (error) {
                  console.error(
                    "Error updating text",
                    error
                  );
                  enqueueSnackbar(
                    "Error updating text",
                    {variant: "error"}
                  );
                }
              }}
              onBlurCapture={(event) => {
                try {
                  onTextFieldRedlineChange?.(
                    originalTextFieldRedline.edit(new Text(currentTextValue)),
                    {
                      name: originalTextFieldRedline.field,
                      id: originalTextFieldRedline.fieldId,
                    }
                  );
                  setShowTextInput(false);
                } catch (error) {
                  console.error(
                    "Error updating text",
                    error
                  );
                  enqueueSnackbar(
                    "Error updating text",
                    {
                      variant: "error",
                    }
                  );
                }
              }}
              onKeyDown={(event) => {
                if (
                  [
                    "Enter",
                    "Tab"
                  ].includes(event.key) &&
                  currentTextValue !== ""
                ) {
                  try {
                    onTextFieldRedlineChange?.(
                      originalTextFieldRedline.edit(new Text(currentTextValue)),
                      {
                        name: originalTextFieldRedline.field,
                        id: originalTextFieldRedline.fieldId,
                      }
                    );
                    setShowTextInput(false);
                  } catch (error) {
                    console.error(
                      "Error updating text",
                      error
                    );
                    enqueueSnackbar(
                      "Error updating text",
                      {
                        variant: "error",
                      }
                    );
                  }
                }
                if (event.key === "Escape") {
                  try {
                    setCurrentRedline(originalTextFieldRedline.clone());
                    setShowTextInput(false);
                  } catch (error) {
                    console.error(
                      "Error updating text",
                      error
                    );
                    enqueueSnackbar(
                      "Error updating text",
                      {
                        variant: "error",
                      }
                    );
                  }
                }
              }}
              onBlur={onBlur}
              onFocus={onFocus}
            />
          </FormControl>
        </InputContainer>
      </TextContainer>
    );
  }
);

export interface RedlineTextInputProps extends BaseTextFieldProps {
  originalTextFieldRedline: FieldRedline<Text>;
  issues?: ProposalIssue[];
  showTextInput?: boolean;
  readOnly?: boolean;
  hideUndoButton?: boolean;
  hideAcceptRejectButtons?: boolean;
  hideCommentsButton?: boolean;
  hideLabel?: boolean;
  commentThreads: CommentThread[];
  showPlaceholder?: boolean;
  bold?: boolean;
  onTraverseIn?: () => void;
  onTextFieldRedlineChange?: (
    newRedline: FieldRedline<Text>,
    traversalFieldOverride?: ProposalField | null
  ) => void;
  onCommentsClicked?: (setToOpen?: boolean) => void;
}

const TextRedlineInput = (props: Readonly<RedlineTextInputProps>) => {
  const {
    disabled,
    originalTextFieldRedline,
    issues,
    hideUndoButton,
    hideAcceptRejectButtons,
    readOnly,
    hideCommentsButton,
    hideLabel,
    variant,
    margin,
    commentThreads,
    showPlaceholder,
    bold,
    focused,
    onTextFieldRedlineChange,
    onCommentsClicked,
    onTraverseIn,
    onFocus,
    onBlur,
    ...otherProps
  } = props;

  const [actionButtons, setActionButtons] = React.useState<JSX.Element>();

  function getInputProps(variant: string | undefined): Partial<InputProps> {
    const inputProps: Partial<InputProps> = {
      inputComponent: InputSubComponent,
      inputProps: {
        readOnly,
        hideUndoButton,
        hideAcceptRejectButtons,
        hideCommentsButton,
        originalTextFieldRedline,
        issues,
        commentThreads,
        focused,
        showPlaceholder,
        bold,
        onTraverseIn,
        onTextFieldRedlineChange,
        onCommentsClicked,
        onActionButtonsChange: (actionButtons: JSX.Element) => {
          setActionButtons(actionButtons);
        },
        onFocus: onFocus,
        onBlur: onBlur,
      } as InputSubComponentProps,
      endAdornment: actionButtons,
    };
    if (variant && variant !== "outlined") {
      inputProps.disableUnderline = true;
    }

    return inputProps;
  }

  return (
    <RedlineTextBox
      {...otherProps}
      disabled={disabled}
      focused={focused}
      traversable={Boolean(onTraverseIn)}
      error={Boolean(issues?.length)}
      helperText={issues?.map((entry) => entry.description).join("\n")}
      label={hideLabel ? undefined : originalTextFieldRedline.label}
      variant={variant ?? "outlined"}
      margin={margin ?? "normal"}
      slotProps={{
        input: getInputProps(variant),
        inputLabel: {shrink: true},
      }}
      onFocus={() => {
        onCommentsClicked?.(true);
        onTraverseIn?.();
      }}
    />
  );
};

export default TextRedlineInput;
