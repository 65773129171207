import AccountTreeIcon from "@mui/icons-material/AccountTree";
import AddCommentIcon from "@mui/icons-material/AddComment";
import ArchiveIcon from "@mui/icons-material/Archive";
import AssignmentIcon from "@mui/icons-material/Assignment";
import FilterListIcon from "@mui/icons-material/FilterList";
import ForumIcon from "@mui/icons-material/Forum";
import GroupIcon from "@mui/icons-material/Group";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import MarkEmailUnreadIcon from "@mui/icons-material/MarkEmailUnread";
import MessageIcon from "@mui/icons-material/Message";
import PageviewIcon from "@mui/icons-material/Pageview";
import PersonIcon from "@mui/icons-material/Person";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import UnarchiveIcon from "@mui/icons-material/Unarchive";
import {
  Box,
  Button,
  Chip,
  Container,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  SxProps,
  Tab,
  Tabs,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {styled} from "@mui/material/styles";
import EmptyInboxPlaceholder from "app/assets/empty_inbox_placeholder.svg";
import {useDialog} from "app/providers/dialog";
import {useAttorneyHubDispatch} from "app/realtime-store/redux-store";
import {NotificationTag} from "app/routes/communications/style";
import {DialogProps} from "common/components/dialog";
import PaginationParameters from "common/contracts/pagination-parameters";
import * as Constants from "common/helpers/constants";
import Guid from "common/values/guid/guid";
import {
  createMRTColumnHelper,
  MaterialReactTable,
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_ShowHideColumnsButton,
  MRT_SortingState,
  MRT_ToggleDensePaddingButton,
  MRT_ToggleFiltersButton,
  MRT_ToggleFullScreenButton,
  MRT_ToggleGlobalFilterButton,
  useMaterialReactTable,
} from "material-react-table";
import {ChatDialog} from "messaging/components";
import Forum from "messaging/entities/forum/forum";
import CreateForumDialog from "messaging/entities/forum/view/components/create-forum-dialog";
import moment from "moment";
import NotificationsAPIService, {
  NotificationNotFoundError,
} from "notifications/entities/notification/api/notifications-api-service";
import NotificationParameters from "notifications/entities/notification/api/request-contracts/notification-parameters";
import Notification from "notifications/entities/notification/notification";
import {NotificationLink} from "notifications/entities/notification/notification-type";
import {
  addNotification,
  getIsLoadingNotificationsByQuery,
  getNotificationQueryByInterface,
  getNotificationsByQuery,
  NotificationQuery,
  populateNotificationsByQuery,
  updateNotificationInterfaceQuery,
} from "notifications/entities/notification/store/notifications-redux-slice";
import Action from "notifications/values/action/action";
import {BoxType} from "notifications/values/box-type/box-type";
import TopicCategory from "notifications/values/topic-category/topic-category";
import React, {useEffect, useState} from "react";
import {Params, useLoaderData, useLocation, useNavigate, useSearchParams,} from "react-router-dom";
import {useSession} from "users/session/session-context";

const NoRowsContainer = styled("div")(
  ({theme}) => ({
    display: "flex",
    justifyContent: "center",
  }));
const RowActionsContainer = styled(Box)(
  ({theme}) => ({
    display: "flex",
    flexWrap: "nowrap",
    width: "inherit",
  }));
const ToolbarButtonsContainer = styled("div")(
  ({theme}) => ({
    [theme.breakpoints.down("md")]: {
      flexDirection: "row",
      justifyContent: "space-between",
      width: "100%",
    },
    display: "flex",
    alignItems: "end",
    flexDirection: "column",
    gap: theme.spacing(1),
  }));
const ToolbarButtons = styled("div")(
  ({theme}) => ({
    display: "flex",
    alignItems: "end",
    flexWrap: "nowrap",
  }));
const ToolbarActionButtons = styled("div")(
  ({theme}) => ({
    textAlign: "right",
    whiteSpace: "nowrap",
  }));
const CategoryItem = styled(ListItem)(
  ({theme}) => ({
    margin: 0,
    padding: 0,
  }));
const AlertActions = styled("div")(
  ({theme}) => ({
    alignItems: "center",
    display: "flex",
    gap: theme.spacing(1),
    flexWrap: "nowrap",
    height: "100%",
    paddingRight: theme.spacing(2),
    whiteSpace: "nowrap",
  }));
const AllCaughtUp = styled("img")(
  ({theme}) => ({
    maxHeight: "100px",
    padding: theme.spacing(2),
  }));
const TableTopToolbarContainer = styled("div")(
  ({theme}) => ({
    display: "flex",
    flexDirection: "column",
    width: "100%",
  }));
const LowerTopToolbarContainer = styled("div")(
  ({theme}) => ({
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
    display: "flex",
    flexDirection: "row",
    alignItems: "left",
    width: "100%",
  }));
const TableTitle = styled(Typography)(
  ({theme}) => ({
    paddingLeft: theme.spacing(1),
  }));
const FilterButtons = styled("div")(
  ({theme}) => ({
    marginTop: theme.spacing(1),
    "& > .MuiChip-root": {
      [theme.breakpoints.down("xl")]: {
        marginBottom: theme.spacing(0.5),
      },
      marginLeft: theme.spacing(0.5),
    },
  }));
const TableTitleContainer = styled("div")(
  ({theme}) => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  }));
const NewForumButton = styled(IconButton)(
  ({theme}) => ({
    padding: 0,
    paddingRight: theme.spacing(1),
  }));
const ComposeMessageButton = styled(IconButton)(
  ({theme}) => ({
    padding: 0,
    paddingRight: theme.spacing(1),
  }));

type CommunicationsProps = {};

export default function Communications(_props: Readonly<CommunicationsProps>) {
  const [activeTab, setActiveTab] = React.useState(BoxType.Inbox);
  const [actionRequired, setActionRequired] = React.useState(false);
  const [unread, setUnread] = React.useState(false);

  const [wasErrorLoading, setWasErrorLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowSelection, setRowSelection] = React.useState<
    Record<string, boolean>
  >({});

  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    []
  );
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState<MRT_SortingState>([]);


  const routeParams = useLoaderData() as Params<string>;
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();
  const session = useSession();
  const {openDialog, closeAllDialogs} = useDialog();
  const isSmlDisplaySize = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const isMedDisplaySize = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  const dispatch = useAttorneyHubDispatch();
  const notificationsQuery = getNotificationQueryByInterface(
    "communicationsIndex"
  );
  const notificationsResponse = getNotificationsByQuery(notificationsQuery);

  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10
  });

  const queryIsLoading = getIsLoadingNotificationsByQuery(notificationsQuery);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(
    () => {
      if (notificationsResponse === undefined && notificationsQuery && !isLoading) {
        dispatch(
          populateNotificationsByQuery({
            session: session,
            query: notificationsQuery,
          })
        );
        return;
      } else if (
        notificationsResponse &&
        (
          notificationsResponse.pageIndex !== pagination.pageIndex ||
          notificationsResponse.pageSize !== pagination.pageSize)
      ) {
        // setPagination({
        //   pageIndex: notificationsResponse.elementIndex,
        //   pageSize: notificationsResponse.pageSize
        // })
      }
    },
    [
      notificationsResponse,
      notificationsQuery
    ]
  );

  useEffect(
    () => {
      closeAllDialogs();
    },
    [location]
  );

  useEffect(
    () => {
      setIsLoading(queryIsLoading ?? false);
    },
    [queryIsLoading]
  )

  // Handle URL changes
  useEffect(
    () => {
      if (!session.user?.companyEntityId) return;

      if (routeParams.tab) {
        setActiveTab(routeParams.tab as BoxType);
      } else {
        navigate(`/communications/${BoxType.Inbox}`);
      }

      if (routeParams.action === "compose") {
        openMessageDialog();
        return;
      }
      if (routeParams.tab === BoxType.Messages && routeParams.id) {
        openMessageDialog(new Guid(routeParams.id));
      }

      setUnread(searchParams.get("unread") === "true");
      setActionRequired(searchParams.get("actionRequired") === "true");

      const notificationParams = new NotificationParameters(
        activeTab,
        actionRequired,
        unread,
        false
      );
      const paginationParams = new PaginationParameters(
        pagination.pageIndex,
        pagination.pageSize
      );
      const newQuery = new NotificationQuery(
        notificationParams,
        paginationParams
      );
      if (newQuery.isEqualTo(notificationsQuery)) {
        return;
      }
      dispatch(
        updateNotificationInterfaceQuery({
          interfaceName: "communicationsIndex",
          query: newQuery,
        })
      );
    },
    [
      routeParams,
      searchParams,
      pagination,
      activeTab
    ]
  );

  async function navigateToTarget(notification: Notification) {
    try {
      if (activeTab === BoxType.Inbox) {
        await new NotificationsAPIService(session).markNotificationAsSeen(
          notification
        );
      }
      navigate(NotificationLink(
        notification,
        activeTab
      ));
    } catch (error) {
      console.error(error);
    }
  }

  async function markMultipleSeen() {
    const selectedRowIds: Guid[] = Object.keys(rowSelection)
      .filter((id) => rowSelection[id] === true)
      .map((id) => new Guid(id));

    try {
      for (const selectedRowId of selectedRowIds) {
        try {
          const updatedNotification = await new NotificationsAPIService(
            session
          ).markNotificationAsSeen(new Notification(selectedRowId));
          dispatch(addNotification(updatedNotification));
        } catch (error) {
          if (error instanceof NotificationNotFoundError) {
            console.log(
              "Unable to mark a deleted notification as read, refreshing to remove",
              error.id
            );
            continue;
          }
          throw error;
        }
      }
      setRowSelection({});
    } catch (error) {
      console.error(error);
    }
  }

  async function markMultipleUnseen() {
    const selectedRowIds: Guid[] = Object.keys(rowSelection)
      .filter((id) => rowSelection[id] === true)
      .map((id) => new Guid(id));

    try {
      for (const selectedRowId of selectedRowIds) {
        try {
          const updatedNotification = await new NotificationsAPIService(
            session
          ).markNotificationAsUnSeen(new Notification(selectedRowId));
          dispatch(addNotification(updatedNotification));
        } catch (error) {
          if (error instanceof NotificationNotFoundError) {
            console.log(
              "Unable to mark a deleted notification as unread, refreshing to remove",
              error.id
            );
            continue;
          }
          throw error;
        }
      }
      setRowSelection({});
    } catch (error) {
      console.error(error);
    }
  }

  async function archiveMultiple() {
    const selectedRowIds: Guid[] = Object.keys(rowSelection)
      .filter((id) => rowSelection[id] === true)
      .map((id) => new Guid(id));

    try {
      for (const selectedRowId of selectedRowIds) {
        try {
          await new NotificationsAPIService(session).archiveNotification(
            new Notification(selectedRowId)
          );
        } catch (error) {
          if (error instanceof NotificationNotFoundError) {
            console.log(
              "Unable to archive a deleted notification, refreshing to remove ",
              error.id
            );
            continue;
          }
          throw error;
        }
      }
      dispatch(
        updateNotificationInterfaceQuery({
          interfaceName: "communicationsIndex",
          query: notificationsQuery,
        })
      );
      setRowSelection({});
    } catch (error) {
      console.error(error);
    }
  }

  async function unArchiveMultiple() {
    const selectedRowIds: Guid[] = Object.keys(rowSelection)
      .filter((id) => rowSelection[id] === true)
      .map((id) => new Guid(id));

    try {
      for (const selectedRowId of selectedRowIds) {
        try {
          await new NotificationsAPIService(session).unArchiveNotification(
            new Notification(selectedRowId)
          );
        } catch (error) {
          if (error instanceof NotificationNotFoundError) {
            console.log(
              "Unable to un-archive a deleted notification, refreshing to remove ",
              error.id
            );
            continue;
          }
          throw error;
        }
      }

      setRowSelection({});
      dispatch(
        updateNotificationInterfaceQuery({
          interfaceName: "communicationsIndex",
          query: notificationsQuery,
        })
      );
    } catch (error) {
      console.error(error);
    }
  }

  function openMessageDialog(id?: Guid) {
    const parentPath = new URL(
      ".",
      window.location.origin + window.location.pathname
    );

    openDialog(
      {
        title: id ? "Message" : "Compose Message",
        MuiProps: {
          fullWidth: true,
          maxWidth: "md",
        },
        contentSxProps: {
          display: "flex",
          overflowX: "hidden",
          paddingBottom: 0,
        },
        component: <ChatDialog forumId={id}/>,
      },
      () => navigate(parentPath.pathname)
    );
  }

  function renderNameColumn(communication: Notification): React.ReactNode {
    const forum = communication as Forum;

    return (
      <CategoryItem dense>
        <ListItemIcon>
          <MessageIcon/>
        </ListItemIcon>
        <ListItemText
          primary={forum?.name}
          primaryTypographyProps={{fontWeight: "500"}}
        />
      </CategoryItem>
    );
  }

  function renderCreatedDateColumn(
    communication: Notification
  ): React.ReactNode {
    if (!communication.createdDate) return null;
    return (
      <time>
        {moment(communication.createdDate).format("MM/DD/YY hh:mm A")}
      </time>
    );
  }

  function renderCategoryColumn(notification: Notification) {
    const topicCategory: TopicCategory = notification?.topic?.category ?? "";

    return (
      <CategoryItem dense>
        <ListItemIcon>
          {(topicCategory === TopicCategory.Messaging.Message ||
            topicCategory === TopicCategory.Messaging.ForumInvitation) && (
            <MessageIcon/>
          )}
          {topicCategory === TopicCategory.Work.Proposal &&
            !notification.actionRequired && <PersonAddIcon/>}
          {topicCategory === TopicCategory.Users.NetworkConnection && (
            <AccountTreeIcon/>
          )}
          {topicCategory === TopicCategory.Users.NetworkInvitation && (
            <AccountTreeIcon/>
          )}
          {topicCategory === TopicCategory.Work.Proposal &&
            notification.actionRequired &&
            notification.actionRequired !== Action.Review && <AssignmentIcon/>}
          {topicCategory === TopicCategory.Work.Proposal &&
            notification.actionRequired === Action.Review && <PageviewIcon/>}
          {topicCategory === TopicCategory.Marketplace.Team && <GroupIcon/>}
          {topicCategory === TopicCategory.LegalEntities.EntityMember && (
            <PersonIcon/>
          )}
          {topicCategory === TopicCategory.Marketplace.TeamInvitation && (
            <GroupAddIcon/>
          )}
          {topicCategory === TopicCategory.LegalEntities.LegalEntity && (
            <SupervisorAccountIcon/>
          )}
        </ListItemIcon>
        <ListItemText
          primary={
            <strong
              style={{fontWeight: !notification.isSeen ? "bold" : "normal"}}
            >
              {topicCategory === TopicCategory.Messaging.Message
                ? "New Message"
                : Constants.notificationTypes[
                topicCategory as keyof typeof Constants.notificationTypes
                ] ?? "Notification"}
            </strong>
          }
          secondary={
            activeTab === BoxType.Inbox &&
            (notification.isSeen
              ? `Read at ${moment(notification.seenAt).format(
                "MM/DD/YY hh:mm A"
              )}`
              : "Unread notification")
          }
        />
      </CategoryItem>
    );
  }

  function renderDescriptionColumn(notification: Notification) {
    const tag = notification.recipientUserId?.isEqualTo(session.user?.id) ? (
      <NotificationTag size="small" label="Received"/>
    ) : (
      <NotificationTag size="small" label="Sent"/>
    );
    return (
      <>
        {activeTab === BoxType.Archived && tag}
        {notification.message?.value}
      </>
    );
  }

  const renderInboxRowActions = (communication: Notification) => {
    return (
      <RowActionsContainer>
        {!communication.isSeen ? (
          <Tooltip title="Mark as Read">
            <span>
              <IconButton
                onClick={(event) => {
                  event.stopPropagation();
                  markAsRead(communication);
                }}
              >
                <MarkEmailReadIcon/>
              </IconButton>
            </span>
          </Tooltip>
        ) : (
          <Tooltip title="Mark as Unread">
            <span>
              <IconButton
                onClick={(event) => {
                  event.stopPropagation();
                  markAsUnread(communication);
                }}
              >
                <MarkEmailReadIcon/>
              </IconButton>
            </span>
          </Tooltip>
        )}
        <Tooltip title="Archive">
          <span>
            <IconButton
              onClick={(event) => {
                event.stopPropagation();
                archive(communication);
              }}
            >
              <ArchiveIcon/>
            </IconButton>
          </span>
        </Tooltip>
      </RowActionsContainer>
    );
  };

  const renderArchiveRowActions = (communication: Notification) => {
    return (
      <RowActionsContainer>
        <Tooltip title="Restore from Archive">
          <span>
            <IconButton
              onClick={(event) => {
                event.stopPropagation();
                unArchive(communication);
              }}
            >
              <UnarchiveIcon/>
            </IconButton>
          </span>
        </Tooltip>
      </RowActionsContainer>
    );
  };

  async function markAsRead(communication: Notification) {
    if (!communication || communication?.seenAt) return;
    try {
      const updatedNotification = await new NotificationsAPIService(
        session
      ).markNotificationAsSeen(communication);
      dispatch(addNotification(updatedNotification));
    } catch (error) {
      if (error instanceof NotificationNotFoundError) {
        console.log(
          "Unable to mark a deleted notification as read ",
          error.id
        );
      } else {
        console.error(error);
      }
    }
  }

  async function markAsUnread(communication: Notification) {
    if (!communication || !communication?.seenAt) return;
    try {
      const updatedNotification = await new NotificationsAPIService(
        session
      ).markNotificationAsUnSeen(communication);
      dispatch(addNotification(updatedNotification));
    } catch (error) {
      if (error instanceof NotificationNotFoundError) {
        console.log(
          "Unable to mark a deleted notification as unread, refreshing to remove ",
          error.id
        );
      } else {
        console.error(error);
      }
    }
  }

  async function archive(communication: Notification) {
    if (!communication || communication instanceof Forum) return;
    try {
      await new NotificationsAPIService(session).archiveNotification(
        communication
      );
    } catch (error) {
      if (error instanceof NotificationNotFoundError) {
        console.log(
          "Unable to archive a deleted notification, refreshing to remove ",
          error.id
        );
      } else {
        console.error(error);
      }
      dispatch(
        updateNotificationInterfaceQuery({
          interfaceName: "communicationsIndex",
          query: notificationsQuery,
        })
      );
    }
  }

  async function unArchive(communication: Notification) {
    if (!communication || communication instanceof Forum) return;
    try {
      await new NotificationsAPIService(session).unArchiveNotification(
        communication
      );
    } catch (error) {
      if (error instanceof NotificationNotFoundError) {
        console.log(
          "Unable to un-archive a deleted notification, refreshing to remove ",
          error.id
        );
      } else {
        console.error(error);
      }
      dispatch(
        updateNotificationInterfaceQuery({
          interfaceName: "communicationsIndex",
          query: notificationsQuery,
        })
      );
    }
  }

  const columnHelper = createMRTColumnHelper<Notification>();
  const columns = [];
  if (activeTab !== BoxType.Messages) {
    columns.push(
      ...[
        columnHelper.accessor(
          renderCategoryColumn,
          {
            header: "Type",
            id: "type",
          }
        ),
        columnHelper.accessor(
          renderDescriptionColumn,
          {
            header: "Description",
            id: "description",
          }
        ),
      ]
    );
  }
  if (activeTab === BoxType.Messages) {
    columns.push(
      columnHelper.accessor(
        renderNameColumn,
        {
          header: "Conversation Name",
          id: "name",
        }
      )
    );
  }
  columns.push(
    columnHelper.accessor(
      renderCreatedDateColumn,
      {
        header: "Created",
        id: "createdDate",
      }
    )
  );

  const forumDialogProps: DialogProps = {
    component: (
      <CreateForumDialog
        onForumCreated={(forum: Forum) => {
          console.error("not implemented");
        }}
      />
    ),
    title: "Create Conversation",
    MuiProps: {
      maxWidth: "md",
    },
  };

  function getRowClickTheme(row: Notification): SxProps<Theme> | undefined {
    if (activeTab === BoxType.Archived) {
      return {
        cursor: "",
        backgroundColor: "rgba(0, 0, 0, 0.04)",
      };
    }
    return {
      cursor: "pointer",
      backgroundColor: !row.isSeen ? "inherit" : "rgba(0, 0, 0, 0.04)",
    };
  }

  const table = useMaterialReactTable({
    columns,
    data: notificationsResponse?.data ?? [],
    rowCount: notificationsResponse?.totalElements ?? 0,
    enableRowSelection: activeTab !== BoxType.Messages,
    enableRowActions: activeTab !== BoxType.Messages,
    enableTableHead: true,
    muiTableBodyRowProps: ({row}) => ({
      onClick: () => navigateToTarget(row.original),
      sx: getRowClickTheme(row.original),
    }),
    muiTableProps: ({table}) => ({
      sx: {
        height: notificationsResponse?.data?.length === 0 ? "100%" : "initial",
      },
    }),
    renderToolbarInternalActions: ({table}) => (
      <>
        {!isMedDisplaySize && (
          <ToolbarButtonsContainer>
            <ToolbarButtons>
              <MRT_ToggleGlobalFilterButton table={table}/>
              <MRT_ToggleFiltersButton table={table}/>
              <MRT_ShowHideColumnsButton table={table}/>
              <MRT_ToggleDensePaddingButton table={table}/>
              <MRT_ToggleFullScreenButton table={table}/>
            </ToolbarButtons>
            <ToolbarActionButtons>
              <Button
                color="primary"
                startIcon={<AddCommentIcon/>}
                onClick={() => navigate("/communications/messages/compose")}
              >
                Compose Message
              </Button>
              <Button
                color="primary"
                startIcon={<ForumIcon/>}
                onClick={() => openDialog(forumDialogProps)}
              >
                New Conversation
              </Button>
            </ToolbarActionButtons>
          </ToolbarButtonsContainer>
        )}
        {isMedDisplaySize && !isSmlDisplaySize && (
          <>
            <ComposeMessageButton
              color="primary"
              onClick={() => navigate("/communications/messages/compose")}
            >
              <AddCommentIcon fontSize="large"/>
            </ComposeMessageButton>
            <NewForumButton
              color="primary"
              onClick={() => openDialog(forumDialogProps)}
            >
              <ForumIcon fontSize="large"/>
            </NewForumButton>
          </>
        )}
      </>
    ),
    renderTopToolbarCustomActions: () => {
      return (
        <TableTopToolbarContainer>
          <TableTitleContainer>
            <TableTitle variant="h4">Communications</TableTitle>
            {isSmlDisplaySize && (
              <div>
                <ComposeMessageButton
                  color="primary"
                  onClick={() => navigate("/communications/messages/compose")}
                >
                  <AddCommentIcon fontSize="large"/>
                </ComposeMessageButton>
                <NewForumButton
                  color="primary"
                  onClick={() => openDialog(forumDialogProps)}
                >
                  <ForumIcon fontSize="large"/>
                </NewForumButton>
              </div>
            )}
          </TableTitleContainer>
          <LowerTopToolbarContainer>
            <Tabs
              value={activeTab}
              indicatorColor="primary"
              textColor="primary"
              onChange={(_event, newValue) =>
                navigate(`/communications/${newValue}`)
              }
            >
              <Tab value={BoxType.Inbox} label="Inbox"/>
              <Tab value={BoxType.Outbox} label="Sent"/>
              <Tab value={BoxType.Messages} label="Conversations"/>
              <Tab value={BoxType.Archived} label="Archived"/>
            </Tabs>
            {activeTab === BoxType.Inbox && (
              <FilterButtons>
                <Chip
                  icon={<FilterListIcon/>}
                  label="Unread"
                  color="primary"
                  variant={unread ? "filled" : "outlined"}
                  onClick={() => {
                    setUnread((prevState) => !prevState);
                    setSearchParams((prevParams) => {
                      const params = new URLSearchParams(prevParams);
                      if (!unread) {
                        params.set(
                          "unread",
                          "true"
                        );
                      } else {
                        params.delete("unread");
                      }
                      return params;
                    });
                  }}
                />
                <Chip
                  icon={<FilterListIcon/>}
                  label="Action Required"
                  color="primary"
                  variant={actionRequired ? "filled" : "outlined"}
                  onClick={() => {
                    setActionRequired((prevState) => !prevState);
                    setSearchParams((prevParams) => {
                      const params = new URLSearchParams(prevParams);
                      if (!actionRequired) {
                        params.set(
                          "actionRequired",
                          "true"
                        );
                      } else {
                        params.delete("actionRequired");
                      }
                      return params;
                    });
                  }}
                />
              </FilterButtons>
            )}
          </LowerTopToolbarContainer>
          {isMedDisplaySize && (
            <ToolbarButtonsContainer>
              <ToolbarButtons>
                <MRT_ToggleGlobalFilterButton table={table}/>
                <MRT_ToggleFiltersButton table={table}/>
                <MRT_ShowHideColumnsButton table={table}/>
                <MRT_ToggleDensePaddingButton table={table}/>
                <MRT_ToggleFullScreenButton table={table}/>
              </ToolbarButtons>
            </ToolbarButtonsContainer>
          )}
        </TableTopToolbarContainer>
      );
    },
    renderRowActions: ({row}) => {
      if (activeTab === BoxType.Archived) {
        return renderArchiveRowActions(row.original);
      }
      if (activeTab === BoxType.Inbox) {
        return renderInboxRowActions(row.original);
      }
    },
    renderEmptyRowsFallback: () => {
      if (isLoading) {
        return;
      }
      return (
        <NoRowsContainer>
          <AllCaughtUp alt="All Caught Up" src={EmptyInboxPlaceholder}/>
        </NoRowsContainer>
      );
    },
    getRowId: (row) => row.id?.toString() ?? Guid.generate().toString(),
    initialState: {
      columnPinning: {right: ["mrt-row-actions"]},
      columnOrder: [
        "type",
        "description",
        "name",
        "createdDate",
        "actions"
      ],
    },
    manualFiltering: true,
    enableStickyHeader: true,
    enableStickyFooter: true,
    enableColumnPinning: true,
    manualPagination: true,
    manualSorting: true,
    muiTableContainerProps: ({table}) => ({
      sx: {
        height: `calc(100% - ${table.refs.topToolbarRef.current?.offsetHeight}px - ${table.refs.bottomToolbarRef.current?.offsetHeight}px)`,
      },
    }),
    muiTablePaperProps: {
      sx: {
        height: "100%",
      },
    },
    muiToolbarAlertBannerProps: wasErrorLoading
      ? {
        color: "error",
      }
      : {
        action: (
          <AlertActions>
            {activeTab === BoxType.Archived && (
              <Button
                color="primary"
                startIcon={<UnarchiveIcon/>}
                onClick={unArchiveMultiple}
              >
                Un-Archive
              </Button>
            )}
            {activeTab === BoxType.Inbox && (
              <>
                <Button
                  color="primary"
                  startIcon={<MarkEmailReadIcon/>}
                  onClick={markMultipleSeen}
                >
                  Mark Read
                </Button>
                <Button
                  color="primary"
                  startIcon={<MarkEmailUnreadIcon/>}
                  onClick={markMultipleUnseen}
                >
                  Mark Unread
                </Button>
              </>
            )}
            {activeTab !== BoxType.Archived && (
              <Button
                color="primary"
                startIcon={<ArchiveIcon/>}
                onClick={archiveMultiple}
              >
                Archive
              </Button>
            )}
          </AlertActions>
        ),
      },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    onRowSelectionChange: setRowSelection,
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: wasErrorLoading,
      showProgressBars: isRefetching,
      sorting,
      rowSelection,
    },
  });

  if (!session.user?.companyEntityId) {
    return (
      <Container sx={{height: "100%", textAlign: "center"}}>
        <Typography
          sx={{alignContent: "center", height: "100%"}}
          variant="h4"
        >
          You are not authorized to view this page
        </Typography>
      </Container>
    );
  }

  return <MaterialReactTable table={table}/>;
}
