import AddBoxIcon from "@mui/icons-material/AddBox";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import FilterListIcon from "@mui/icons-material/FilterList";
import {
  Button,
  Chip,
  Container,
  Icon,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Tab,
  Tabs,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {styled} from "@mui/material/styles";
import {ConfirmResponse, useConfirmDialog,} from "app/providers/confirm-dialog";
import {useDialog} from "app/providers/dialog";
import {useAttorneyHubDispatch} from "app/realtime-store/redux-store";
import CreatorLink from "app/routes/proposals/creator-link";
import {PageTab} from "app/routes/proposals/tabs";
import {AccountType} from "common/values/account-type/account-type";
import Guid from "common/values/guid/guid";
import {
  createMRTColumnHelper,
  MaterialReactTable,
  MRT_ShowHideColumnsButton,
  MRT_ToggleDensePaddingButton,
  MRT_ToggleFiltersButton,
  MRT_ToggleFullScreenButton,
  MRT_ToggleGlobalFilterButton,
  useMaterialReactTable,
} from "material-react-table";
import {enqueueSnackbar} from "notistack";
import React, {useEffect} from "react";
import {Params, useLoaderData, useNavigate} from "react-router";
import {useSearchParams} from "react-router-dom";
import {useSession} from "users/session/session-context";
import {
  getCommentThreadsByProposal,
  getErrorLoadingCommentThreadsByProposal,
  getIsLoadingCommentThreadsByProposal,
  populateCommentThreadsByProposal
} from "work/entities/comment-thread/store/comment-thread-redux-slice";
import {clearPendingCommentsForProposalId} from "work/entities/comment/store/comments-redux-slice";
import ProposalParameters, {
  ProposalContextParam,
} from "work/entities/proposal/api/request-contracts/proposal-parameters";
import ProposalDraftDialog from "work/entities/proposal/draft/view/proposal-draft-dialog";
import ProposalRequestDraftDialog from "work/entities/proposal/draft/view/proposal-request-draft-dialog";
import Proposal, {ProposalFieldCategory,} from "work/entities/proposal/proposal";
import {default as ProposalRedline} from "work/entities/proposal/redlining/view/proposal-redline-dialog";
import {
  clearNewProposalBuilder,
  getErrorLoadingProposalById,
  getErrorLoadingProposalsByQuery,
  getIsLoadingProposalById,
  getIsLoadingProposalsByQuery,
  getProposalById,
  getProposalQueryByInterface,
  getProposalsByQuery,
  populateProposal,
  populateProposalsByQuery,
  ProposalQuery,
  removeBuilderByProposalId,
  removeProposal,
  resetProposalSessionByProposalId,
  updateProposalInterfaceQuery,
} from "work/entities/proposal/store/proposals-redux-slice";
import {HumanReadableProposalStatus, ProposalStatus,} from "work/values/constants";
import FeeScheduleLink from "work/values/fee-schedule/view/fee-schedule-link";
import ProposalReviewer from "work/values/proposal-reviewer";
import SelectedRepresentative from "work/values/selected-representative/selected-representative";
import SelectedTeam from "work/values/selected-team/selected-team";
import TeamLink from "work/values/team/view/team-link";


const ToolbarButtonsContainer = styled("div")(
  ({theme}) => ({
    [theme.breakpoints.down("md")]: {
      flexDirection: "row",
      justifyContent: "space-between",
      width: "100%",
    },
    display: "flex",
    alignItems: "end",
    flexDirection: "column",
    gap: theme.spacing(1),
  }));
const ToolbarButtons = styled("div")(
  ({theme}) => ({
    display: "flex",
    alignItems: "end",
    flexWrap: "nowrap",
  }));
const TableTopToolbarContainer = styled("div")(
  ({theme}) => ({
    display: "flex",
    flexDirection: "column",
    width: "100%",
  }));
const LowerTopToolbarContainer = styled("div")(
  ({theme}) => ({
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
    display: "flex",
    flexDirection: "row",
    alignItems: "left",
    width: "100%",
  }));
const TableTitle = styled(Typography)(
  ({theme}) => ({
    paddingLeft: theme.spacing(1),
  }));
const FilterButtons = styled("div")(
  ({theme}) => ({
    marginTop: theme.spacing(1),
    "& > .MuiChip-root": {
      [theme.breakpoints.down("xl")]: {
        marginBottom: theme.spacing(0.5),
      },
      marginLeft: theme.spacing(0.5),
    },
  }));
const AlertActions = styled("div")(
  ({theme}) => ({
    alignItems: "center",
    display: "flex",
    gap: theme.spacing(1),
    flexWrap: "nowrap",
    height: "100%",
    paddingRight: theme.spacing(2),
    whiteSpace: "nowrap",
  }));
const RowTitle = styled(Typography)(
  ({theme}) => ({
    fontSize: "1.1em",
    fontWeight: "600",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  }));
const NewButton = styled(IconButton)(
  ({theme}) => ({
    padding: 0,
  }));

type ProposalProps = {};

export default function Proposals(_props: Readonly<ProposalProps>) {
  const columnHelper = createMRTColumnHelper<Proposal>();
  const [activeTab, setActiveTab] = React.useState(PageTab.Active);
  const [rowSelection, setRowSelection] = React.useState<
    Record<string, boolean>
  >({});

  const [inbox, setInbox] = React.useState(false);
  const [outbox, setOutbox] = React.useState(false);
  const [pendingReview, setPendingReview] = React.useState(false);
  const {openDialog, closeAllDialogs} = useDialog();
  const routeParams = useLoaderData() as Params<string>;
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const session = useSession();
  const confirm = useConfirmDialog();
  const isMediumDisplaySize = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  const dispatch = useAttorneyHubDispatch();
  const proposalQuery = getProposalQueryByInterface("proposalsIndex");
  const proposals = getProposalsByQuery(proposalQuery);
  const isLoadingQueryFromStore = getIsLoadingProposalsByQuery(proposalQuery);
  const errorLoadingQuery = getErrorLoadingProposalsByQuery(proposalQuery);

  const proposalId = routeParams.id ? new Guid(routeParams.id) : undefined;
  const proposalToLoad = getProposalById(proposalId);
  const isLoadingProposal = getIsLoadingProposalById(proposalId);
  const errorLoadingProposal = getErrorLoadingProposalById(proposalId);

  const commentThreads = getCommentThreadsByProposal(proposalToLoad);
  const isLoadingCommentThreads = getIsLoadingCommentThreadsByProposal(
    undefined,
    proposalId
  );
  const errorLoadingCommentThreads = getErrorLoadingCommentThreadsByProposal(
    undefined,
    proposalId
  );

  const draftIsDirtyRef = React.useRef<boolean>(false);

  const [isLoading, setIsLoading] = React.useState(isLoadingQueryFromStore ?? false);

  useEffect(
    () => {
      if (!routeParams.pageTab) {
        navigate(`/proposals/${PageTab.Active}`);
      } else {
        setActiveTab(routeParams.pageTab as PageTab);
      }
    },
    [routeParams]
  );

  useEffect(
    () => {
      dispatchProposalQuery();
    },
    [activeTab]
  );

  useEffect(
    () => {
      if (errorLoadingQuery) {
        enqueueSnackbar(
          "Error loading proposal table",
          {variant: "error"}
        );
      }
      if (proposalToLoad === null) {
        enqueueSnackbar(
          "Proposal not found.",
          {variant: "warning"}
        );
        navigate(`/proposals/${activeTab}`);
      } else if (errorLoadingProposal) {
        enqueueSnackbar(
          "Error loading proposal",
          {variant: "error"}
        );
        navigate(`/proposals/${activeTab}`);
      }
    },
    [
      errorLoadingQuery,
      errorLoadingProposal,
      proposalToLoad
    ]
  );

  useEffect(
    () => {
      if (session.user?.id && proposalId && proposalToLoad) {
        const pageTab = routeParams.pageTab as PageTab;
        const action = routeParams.action as string;
        const reviewer = proposalToLoad.getReviewer(session.user.id);

        redirectForProposalIfNecessary(
          action,
          reviewer,
          pageTab
        );
      }
    },
    [
      routeParams,
      proposalId?.value,
      proposalToLoad?.id?.value,
      proposalToLoad?.replacedBy,
      proposalToLoad?.status,
      proposalToLoad?.reviewersKey,
      proposalToLoad?.isSubmitted
    ]
  );

  useEffect(
    () => {
      if (!session.user) return;
      if (proposalId && proposalToLoad === undefined && !isLoadingProposal && !errorLoadingProposal) {
        dispatch(
          populateProposal({
            session: session,
            id: proposalId,
          })
        );
      } else if (!(proposalId && !proposalToLoad)) {
        const tab =
          ProposalFieldCategory[
            routeParams.tab as keyof typeof ProposalFieldCategory
            ];
        const action = routeParams.action as string;
        if (!action) return;
        openProposalDialog(
          action,
          tab
        );
      }

      if (proposalId && commentThreads === undefined && !isLoadingCommentThreads && !errorLoadingCommentThreads) {
        dispatch(
          populateCommentThreadsByProposal({
            session: session,
            proposalId: proposalId,
          })
        );
      }
    },
    [
      proposalId?.value,
      proposalToLoad?.id?.value,
      isLoadingProposal,
      routeParams,
      proposalToLoad?.reviewersKey
    ]
  );

  useEffect(
    () => {
      if (proposalQuery && !isLoadingQueryFromStore && proposals === undefined) {
        dispatch(
          populateProposalsByQuery({
            session: session,
            query: proposalQuery,
          })
        );
      }
    },
    [
      proposalQuery,
      proposals
    ]
  );


  function redirectForProposalIfNecessary(action: string, reviewer: ProposalReviewer | undefined, pageTab: PageTab) {
    if (!proposalToLoad) return;
    let redirectAction: string | undefined = undefined;
    if (proposalToLoad.replacedBy) {
      const newPath = `/proposals/${pageTab}/${action}/${proposalToLoad.replacedBy.value}`;
      navigate(
        newPath,
        {replace: true}
      );
      return;
    }


    if (action === "edit" && proposalToLoad.isSubmitted) {
      redirectAction = "revise";
    }
    if (!proposalToLoad.isArchived && reviewer && action !== "review") {
      redirectAction = "review";
    }
    if (proposalToLoad.isArchived && action !== "view") {
      redirectAction = "view";
    }

    let redirectPageTab: PageTab | undefined = undefined;
    if (pageTab === PageTab.Drafts && proposalToLoad.isSubmitted) {
      redirectPageTab = PageTab.Active;
    }
    if (pageTab === PageTab.Active && !proposalToLoad.isSubmitted) {
      redirectPageTab = PageTab.Drafts;
    }
    if (proposalToLoad.isArchived && pageTab !== PageTab.Archived) {
      redirectPageTab = PageTab.Archived;
    }

    if (redirectAction || redirectPageTab) {
      const newPath = `/proposals/${redirectPageTab ?? pageTab}/${redirectAction ?? action}/${proposalId}`;
      navigate(
        newPath,
        {replace: true}
      );
    }
  }

  function openProposalDialog(action: string, tab: ProposalFieldCategory) {
    if (!session.user || proposalToLoad?.replacedBy || !action) {
      console.warn("Unable to open proposal dialog. Missing required parameters.");
      return;
    }
    const reviewer = proposalToLoad?.getReviewer(session.user.id);
    switch (action) {
      case "create":
        handleCreateDraftProposal();
        break;
      case "request":
        handleRequestProposal();
        break;
      case "edit":
        handleEditDraftProposal(
          proposalId,
          tab
        );
        break;
      case "review":
        if (proposalToLoad && proposalToLoad.isSubmitted) {
          handleReviewActiveProposal(
            proposalId,
            tab,
            (reviewer && !reviewer.canEdit) || (!reviewer && !proposalToLoad.userCanRevise(session.user))
          )
        } else if (proposalToLoad) {
          handleReviewDraftProposal(
            proposalId,
            tab,
            (reviewer && !reviewer.canEdit) || (!reviewer && !proposalToLoad.userCanRevise(session.user))
          )
        }
        break;
      case "revise":
        handleReviseProposal(proposalId);
        break;
      case "view":
        if (proposalToLoad && proposalToLoad.isSubmitted) {
          handleReviewActiveProposal(
            proposalId,
            tab,
            (reviewer && !reviewer.canEdit) || (!reviewer && !proposalToLoad.userCanRevise(session.user))
          )
        } else if (proposalToLoad) {
          handleReviewDraftProposal(
            proposalId,
            tab,
            (reviewer && !reviewer.canEdit) || (!reviewer && !proposalToLoad.userCanRevise(session.user))
          )
        }
        break;
      default:
        handleReviewDraftProposal(
          proposalId,
          tab,
          true
        );
        break;
    }
  }

  function dispatchProposalQuery() {
    if (!session.user?.companyEntityId) return;
    let contextParam = searchParams.get("context");
    setInbox(contextParam === "inbox");
    setOutbox(contextParam === "outbox");
    setPendingReview(contextParam?.includes("review") ?? false);

    let context: ProposalContextParam | null = null;

    if (contextParam && contextParam !== "") {
      context = contextParam as ProposalContextParam;
    }
    if (!context && (routeParams.pageTab ?? activeTab) === PageTab.Active)
      context = "allactive";
    else if (!context && (routeParams.pageTab ?? activeTab) === PageTab.Drafts)
      context = "draft";
    else if (!context &&
      (routeParams.pageTab ?? activeTab) === PageTab.Archived)
      context = "archive";

    const proposalParams = new ProposalParameters(
      session.user?.companyEntityId,
      session.context?.viewingAsVendor
        ? AccountType.Vendor
        : AccountType.Client,
      context as ProposalContextParam
    );

    const newQuery = new ProposalQuery(proposalParams);
    dispatch(
      updateProposalInterfaceQuery({
        interfaceName: "proposalsIndex",
        query: newQuery,
      })
    );
  }

  function handleRequestProposal() {
    const preSelectedRep =
      SelectedRepresentative.fromURLSearchParams(searchParams);
    const preSelectedTeam = SelectedTeam.fromURLSearchParams(searchParams);
    const parentPath = new URL(
      ".",
      window.location.origin + window.location.pathname
    );

    openDialog(
      {
        title: `Request Proposal from ${
          preSelectedRep?.name ?? preSelectedTeam?.targetTeamName ?? "Vendor"
        }`,
        resetStack: true,
        component: (
          <ProposalRequestDraftDialog
            onProposalSubmitted={(submittedProposal: Proposal) => {
              closeAllDialogs();
              navigate(
                `/proposals/active/revise/${submittedProposal.id}`,
                {
                  replace: true,
                }
              );
            }}
            onCancel={() => {
              closeAllDialogs();
              navigate(parentPath.pathname);
            }}
            preSelectedRep={preSelectedRep}
            preSelectedTeam={preSelectedTeam}
          />
        ),
      },
      () => navigate(parentPath.pathname)
    );
  }

  function handleCreateDraftProposal() {
    let url: string;
    const preSelectedRep =
      SelectedRepresentative.fromURLSearchParams(searchParams);
    const preSelectedTeam = SelectedTeam.fromURLSearchParams(searchParams);
    const parentPath = new URL(
      ".",
      window.location.origin + window.location.pathname
    );

    openDialog(
      {
        title: "Create Proposal",
        MuiProps: {fullWidth: false, maxWidth: "lg"},
        contentSxProps: {pb: {xs: 0}},
        titleStyle: {paddingBottom: 0},
        resetStack: true,
        component: (
          <ProposalDraftDialog
            navigate={navigate}
            isDirtyRef={draftIsDirtyRef}
            onProposalSaved={(newProposal?: Proposal) => {
              url = `/proposals/drafts`;
              closeAllDialogs();
              dispatch(clearNewProposalBuilder());
              dispatch(
                updateProposalInterfaceQuery({
                  interfaceName: "proposalsIndex",
                  query: proposalQuery,
                })
              );
              draftIsDirtyRef.current = false;
              if (newProposal) {
                navigate(`/proposals/drafts/edit/${newProposal.id}`);
              }
            }}
            onProposalSubmitted={(submittedProposal: Proposal) => {
              draftIsDirtyRef.current = false;
              closeAllDialogs();
              if (!submittedProposal.id) return;
              navigate(
                `/proposals/active/revise/${submittedProposal.id.value}`,
                {replace: true}
              );
            }}
            preSelectedRep={preSelectedRep}
            preSelectedTeam={preSelectedTeam}
          />
        ),
      },
      () => {
        dispatch(clearNewProposalBuilder());
        navigate(url ?? parentPath.pathname);
      },
      () => draftIsDirtyRef.current
    );

  }

  function handleEditDraftProposal(proposalId?: Guid, tab?: ProposalFieldCategory) {
    if (!proposalId) {
      console.error("Proposal ID is required to edit a draft proposal.");
      return;
    }
    let url: string;
    const parentPath = new URL(
      "..",
      window.location.origin + window.location.pathname
    );

    openDialog(
      {
        title: "Edit Proposal",
        MuiProps: {fullWidth: false, maxWidth: "lg"},
        contentSxProps: {pb: {xs: 0}},
        titleStyle: {paddingBottom: 0},
        resetStack: true,
        component: (
          <ProposalDraftDialog
            navigate={navigate}
            isDirtyRef={draftIsDirtyRef}
            proposalId={proposalId}
            onProposalSaved={() => {
              url = "/proposals/drafts";
              draftIsDirtyRef.current = false;
            }}
            onProposalSubmitted={(submittedProposal: Proposal) => {
              draftIsDirtyRef.current = false;
              closeAllDialogs();
              if (!submittedProposal.id) return;
              navigate(
                `/proposals/active/revise/${submittedProposal.id.value}`,
                {replace: true}
              );
            }}
          />
        ),
      },
      () => {
        dispatch(removeBuilderByProposalId(proposalId));
        dispatch(clearPendingCommentsForProposalId(proposalId));
        navigate(url ?? parentPath.pathname);
      },
      () => draftIsDirtyRef.current
    );
  }

  function handleReviewDraftProposal(
    proposalId?: Guid,
    tab?: ProposalFieldCategory,
    disableEditing?: boolean
  ) {
    if (!proposalId) {
      console.error("Proposal ID is required to review a proposal.");
      return
    }
    const parentPath = new URL(
      "..",
      window.location.origin + window.location.pathname
    );

    openDialog(
      {
        title: "Review Proposal",
        MuiProps: {fullWidth: false, maxWidth: "lg"},
        contentSxProps: {pb: {xs: 0}},
        titleStyle: {paddingBottom: 0},
        resetStack: true,
        component: (
          <ProposalDraftDialog
            navigate={navigate}
            isDirtyRef={draftIsDirtyRef}
            proposalId={proposalId}
            disableEditing={disableEditing}
          />
        ),
      },
      () => navigate(parentPath.pathname)
    );
  }

  function handleReviewActiveProposal(
    proposalId?: Guid,
    tab?: ProposalFieldCategory,
    disableEditing?: boolean
  ) {
    if (!proposalId) {
      console.error("Proposal ID is required to review a proposal.");
      return
    }
    const parentPath = new URL(
      "..",
      window.location.origin + window.location.pathname
    );

    openDialog(
      {
        title: "Review Proposal",
        MuiProps: {fullWidth: false, maxWidth: "lg"},
        contentSxProps: {pb: {xs: 0}},
        titleStyle: {paddingBottom: 0},
        resetStack: true,
        component: (
          <ProposalRedline
            isDirtyRef={draftIsDirtyRef}
            disableEditing={disableEditing}
            proposalId={proposalId}
            navigate={navigate}
          />
        ),
      },
      () => navigate(parentPath.pathname)
    );
  }

  async function handleSubmitProposal(proposal: Proposal) {
    if (!proposal.id)
      return Promise.reject(new Error("Proposal ID is required"));

    const response = await confirm({
      title: "Submit Proposal",
      message: "Are you use you want to submit this proposal?",
      okButtonText: "Submit",
    });

    if (response === ConfirmResponse.Cancel) return;

    try {
      setIsLoading(true);
      await proposal.submit(session.user?.id);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  async function handleApproveProposal(proposal: Proposal) {
    if (!proposal.id)
      return Promise.reject(new Error("Proposal ID is required"));

    const response = await confirm({
      title: "Approve Proposal",
      message: "Are you use you want to approve this proposal?",
      okButtonText: "Approve",
    });

    if (response === ConfirmResponse.Cancel) return;

    try {
      setIsLoading(true);
      await proposal.approve(session.user?.id);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  async function handleRejectProposal(proposal: Proposal) {
    if (!proposal.id)
      return Promise.reject(new Error("Proposal ID is required"));

    const response = await confirm({
      title: "Reject Proposal",
      message: "Are you use you want to reject this proposal?",
      okButtonText: "Reject",
    });

    if (response === ConfirmResponse.Cancel) return;

    try {
      setIsLoading(true);
      await proposal.reject(session.user?.id);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  async function handleHireProposal(proposal: Proposal) {
    if (!proposal.id)
      return Promise.reject(new Error("Proposal ID is required"));

    const response = await confirm({
      title: "Hire Proposal",
      message: "Are you use you want to hire this proposal?",
      okButtonText: "Hire",
    });

    if (response === ConfirmResponse.Cancel) return;

    try {
      setIsLoading(true);
      await proposal.hire(session.user?.id);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  async function handleDeleteProposal(proposal: Proposal) {
    if (!proposal.id)
      return Promise.reject(new Error("Proposal ID is required"));

    const response = await confirm({
      title: "Delete Proposal",
      message: "Are you use you want to delete this proposal?",
      okButtonText: "Delete",
    });

    if (response === ConfirmResponse.Cancel) return;

    try {
      setIsLoading(true);
      await proposal.delete(session.user?.id);
      dispatch(removeProposal(proposal.id));
      dispatch(
        updateProposalInterfaceQuery({
          interfaceName: "proposalsIndex",
          query: proposalQuery,
        })
      );
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  async function handleCancelProposal(proposal: Proposal) {
    if (!proposal.id)
      return Promise.reject(new Error("Proposal ID is required"));

    const response = await confirm({
      title: "Cancel Proposal",
      message: "Are you use you want to cancel this proposal?",
      okButtonText: "Ok",
    });

    if (response === ConfirmResponse.Cancel) return;

    try {
      setIsLoading(true);
      await proposal.cancel(session.user?.id);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  function handleReviseProposal(proposalId?: Guid) {
    if (!proposalId) {
      console.error("Proposal ID is required to revise a proposal.");
      return
    }
    const parentPath = new URL(
      "..",
      window.location.origin + window.location.pathname
    );

    openDialog(
      {
        title: "Review/Revise Proposal",
        titleStyle: {paddingBottom: 0},
        MuiProps: {fullWidth: false, maxWidth: "xl"},
        contentSxProps: {pb: {xs: 0}},
        resetStack: true,
        component: (
          <ProposalRedline
            isDirtyRef={draftIsDirtyRef}
            proposalId={proposalId}
            navigate={navigate}
          />
        ),
      },
      () => {
        dispatch(resetProposalSessionByProposalId(proposalId));
        dispatch(clearPendingCommentsForProposalId(proposalId));
        navigate(parentPath.pathname);
      },
      () => draftIsDirtyRef.current
    );
  }

  async function handleBulkDraftProposalDelete() {
    const selectedRowIds: Guid[] = Object.keys(rowSelection)
      .filter((id) => rowSelection[id] === true)
      .map((id) => new Guid(id));

    const response = await confirm({
      title: "Delete Proposals?",
      message: `Are you sure you want to delete ${selectedRowIds.length} proposal(s)?`,
      okButtonText: "Delete",
    });

    if (response === ConfirmResponse.Cancel) return;

    try {
      setIsLoading(true);
      setRowSelection({});

      selectedRowIds.forEach((id) => dispatch(removeProposal(id)));
      dispatch(
        updateProposalInterfaceQuery({
          interfaceName: "proposalsIndex",
          query: proposalQuery,
        })
      );
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  function renderStatusColumn(proposal: Proposal): string {
    const userId = session.user?.id;
    let proposalStatus: string = ProposalStatus[proposal.status].toString();
    let displayStatus: string =
      HumanReadableProposalStatus[
        proposalStatus as keyof typeof ProposalStatus
        ];
    const isClient = proposal.client?.userId.isEqualTo(userId);
    const isTeamLeader = proposal.team?.leader?.userId.isEqualTo(userId);
    const isVendor = proposal.vendors?.some((vendor) =>
      vendor.userId.isEqualTo(userId)
    );

    if (
      (proposal.status === ProposalStatus.AwaitingApprovalByClient &&
        isClient) ||
      (proposal.status === ProposalStatus.AwaitingApprovalByTeamLeader &&
        isTeamLeader) ||
      (proposal.status === ProposalStatus.AwaitingApprovalByVendors && isVendor)
    )
      displayStatus = "Awaiting Your Approval";

    return displayStatus;
  }

  function renderCreatorColumn(proposal: Proposal) {
    if (!proposal.creatorInfo) return null;
    if (proposal.creatorInfo.userId.isEqualTo(session.user?.id)) {
      return "You";
    }
    return <CreatorLink creator={proposal.creatorInfo}/>;
  }

  function renderTeamColumn(proposal: Proposal) {
    if (!proposal.team) return <Icon>remove</Icon>;
    return <TeamLink proposal={proposal} session={session}/>;
  }

  function renderFeeScheduleColumn(proposal: Proposal) {
    if (!proposal.feeSchedule) return <Icon>remove</Icon>;
    return <FeeScheduleLink proposal={proposal}/>;
  }

  const columns = [
    columnHelper.accessor(
      renderStatusColumn,
      {
        header: "Status",
        id: "status",
        filterVariant: "select",
        filterSelectOptions: Object.entries(HumanReadableProposalStatus).map(
          ([key, value]) => ({value: key, label: value})
        ),
      }
    ),
    columnHelper.accessor(
      (proposal) => proposal.name?.value,
      {
        header: "Name",
        id: "name",
        Cell: ({row}) => <RowTitle>{row.original.name?.value}</RowTitle>,
      }
    ),
    columnHelper.accessor(
      (proposal) => proposal.description?.value,
      {
        header: "Description",
        id: "description",
      }
    ),
    columnHelper.accessor(
      renderCreatorColumn,
      {
        header: "Creator",
        id: "creator",
      }
    ),
    columnHelper.accessor(
      (proposal) => proposal.negotiable,
      {
        header: "Negotiable",
        id: "negotiable",
        size: 50,
        Cell: ({cell}) =>
          cell.getValue<boolean>() ? <CheckIcon/> : <CloseIcon/>,
      }
    ),
    columnHelper.accessor(
      renderTeamColumn,
      {
        header: "Team",
        id: "team",
        size: 50,
      }
    ),
    columnHelper.accessor(
      renderFeeScheduleColumn,
      {
        header: "Fee Schedule",
        id: "feeSchedule",
        size: 50,
      }
    ),
    columnHelper.accessor(
      (proposal) =>
        proposal.responseDueBy?.format("MM/DD/YYYY") ?? <Icon>remove</Icon>,
      {
        header: "Response Due By",
        id: "responseDueBy",
        size: 50,
      }
    ),
    columnHelper.accessor(
      (proposal) =>
        proposal.startDate?.format("MM/DD/YYYY") ?? <Icon>remove</Icon>,
      {
        header: "Start Date",
        id: "startDate",
        size: 50,
      }
    ),
    columnHelper.accessor(
      (proposal) =>
        proposal.endDate?.format("MM/DD/YYYY") ?? <Icon>remove</Icon>,
      {
        header: "End Date",
        id: "endDate",
        size: 50,
      }
    ),
  ];

  const getMenuItems = (proposal: Proposal): React.ReactNode[] => {
    const menuItems: React.ReactNode[] = [];

    if (
      !proposal.availableActions?.Edit.some((id) =>
        id.isEqualTo(session.user?.id)
      ) &&
      !proposal.availableActions?.Review.some((id) =>
        id.isEqualTo(session.user?.id)
      ) &&
      !proposal.availableActions?.Revise.some((id) =>
        id.isEqualTo(session.user?.id)
      )
    ) {
      menuItems.push(
        <MenuItem
          key="view"
          onClick={() =>
            navigate(`/proposals/${activeTab}/view/${proposal.id}`)
          }
        >
          <ListItemIcon>
            <Icon>visibility</Icon>
          </ListItemIcon>
          <ListItemText primary="View"/>
        </MenuItem>
      );
    }

    if (
      proposal.availableActions?.Edit.some((id) =>
        id.isEqualTo(session.user?.id)
      )
    ) {
      menuItems.push(
        <MenuItem
          key="edit"
          onClick={() => navigate(`/proposals/drafts/edit/${proposal.id}`)}
        >
          <ListItemIcon>
            <Icon>edit</Icon>
          </ListItemIcon>
          <ListItemText primary="Review"/>
        </MenuItem>
      );
    }

    if (
      proposal.availableActions?.Review.some((id) =>
        id.isEqualTo(session.user?.id)
      )
    ) {
      menuItems.push(
        <MenuItem
          key="review"
          onClick={() =>
            navigate(`/proposals/${activeTab}/review/${proposal.id}`)
          }
        >
          <ListItemIcon>
            <Icon>edit</Icon>
          </ListItemIcon>
          <ListItemText primary="Edit"/>
        </MenuItem>
      );
    }

    if (
      proposal.availableActions?.Submit.some((id) =>
        id.isEqualTo(session.user?.id)
      )
    ) {
      menuItems.push(
        <MenuItem key="submit" onClick={() => handleSubmitProposal(proposal)}>
          <ListItemIcon>
            <Icon>send</Icon>
          </ListItemIcon>
          <ListItemText primary="Submit"/>
        </MenuItem>
      );
    }

    if (
      proposal.availableActions?.Revise.some((id) =>
        id.isEqualTo(session.user?.id)
      )
    ) {
      menuItems.push(
        <MenuItem
          key="revise"
          onClick={() => navigate(`/proposals/active/revise/${proposal.id}`)}
        >
          <ListItemIcon>
            <Icon>edit</Icon>
          </ListItemIcon>
          <ListItemText primary="Revise"/>
        </MenuItem>
      );
    }

    if (
      proposal.availableActions?.Approve.some((id) =>
        id.isEqualTo(session.user?.id)
      )
    ) {
      menuItems.push(
        <MenuItem key="approve" onClick={() => handleApproveProposal(proposal)}>
          <ListItemIcon>
            <Icon color="success">check</Icon>
          </ListItemIcon>
          <ListItemText primary="Approve"/>
        </MenuItem>
      );
    }

    if (
      proposal.availableActions?.Reject.some((id) =>
        id.isEqualTo(session.user?.id)
      )
    ) {
      menuItems.push(
        <MenuItem key="reject" onClick={() => handleRejectProposal(proposal)}>
          <ListItemIcon>
            <Icon color="error">close</Icon>
          </ListItemIcon>
          <ListItemText primary="Reject"/>
        </MenuItem>
      );
    }

    if (
      activeTab !== PageTab.Archived &&
      proposal.availableActions?.Hire?.some((id) =>
        id.isEqualTo(session.user?.id)
      )
    ) {
      menuItems.push(
        <MenuItem key="hire" onClick={() => handleHireProposal(proposal)}>
          <ListItemIcon>
            <Icon>work</Icon>
          </ListItemIcon>
          <ListItemText primary="Hire"/>
        </MenuItem>
      );
    }

    if (
      proposal.availableActions?.Cancel.some((id) =>
        id.isEqualTo(session.user?.id)
      )
    ) {
      menuItems.push(
        <MenuItem key="cancel" onClick={() => handleCancelProposal(proposal)}>
          <ListItemIcon>
            <Icon color="error">cancel</Icon>
          </ListItemIcon>
          <ListItemText primary="Cancel Negotiation"/>
        </MenuItem>
      );
    }

    if (
      proposal.availableActions?.Delete.some((id) =>
        id.isEqualTo(session.user?.id)
      )
    ) {
      menuItems.push(
        <MenuItem key="delete" onClick={() => handleDeleteProposal(proposal)}>
          <ListItemIcon>
            <DeleteIcon color="error"/>
          </ListItemIcon>
          <ListItemText primary="Delete"/>
        </MenuItem>
      );
    }

    return menuItems;
  };

  const openProposal = (proposal: Proposal) => {
    if (!proposal.id) {
      console.error("Proposal ID is required");
      return;
    }

    const editable = proposal.availableActions.Edit.some((userId) =>
      userId.isEqualTo(session.user?.id)
    );
    if (editable) {
      navigate(`/proposals/drafts/edit/${proposal.id}`);
      return;
    }

    const revisable = proposal.availableActions.Revise.some((userId) =>
      userId.isEqualTo(session.user?.id)
    );
    if (revisable) {
      navigate(`/proposals/active/revise/${proposal.id}`);
      return;
    }

    const reviewable = proposal.availableActions.Review.some((userId) =>
      userId.isEqualTo(session.user?.id)
    );
    if (reviewable) {
      navigate(`/proposals/${activeTab}/review/${proposal.id}`);
      return;
    }

    navigate(`/proposals/${activeTab}/view/${proposal.id}`);
  };

  const table = useMaterialReactTable({
    columns,
    data: proposals ?? [],
    enableRowSelection: activeTab === PageTab.Drafts,
    enableRowActions: true,
    enableTableHead: true,
    muiTableBodyRowProps: ({row}) => ({
      onClick: () => openProposal(row.original),
      sx: {cursor: "pointer"},
    }),
    renderToolbarInternalActions: ({table}) => (
      <>
        {!isMediumDisplaySize && (
          <ToolbarButtonsContainer>
            <ToolbarButtons>
              <MRT_ToggleGlobalFilterButton table={table}/>
              <MRT_ToggleFiltersButton table={table}/>
              <MRT_ShowHideColumnsButton table={table}/>
              <MRT_ToggleDensePaddingButton table={table}/>
              <MRT_ToggleFullScreenButton table={table}/>
            </ToolbarButtons>
            <Button
              startIcon={<AddBoxIcon/>}
              onClick={() => {
                if (routeParams.action === "create") {
                  handleCreateDraftProposal();
                } else {
                  navigate("/proposals/drafts/create")
                }
              }}
            >
              Create Proposal
            </Button>
          </ToolbarButtonsContainer>
        )}
        {isMediumDisplaySize && (
          <NewButton
            color="primary"
            onClick={() => navigate("/proposals/drafts/create")}
          >
            <AddBoxIcon fontSize="large"/>
          </NewButton>
        )}
      </>
    ),
    renderTopToolbarCustomActions: () => {
      return (
        <TableTopToolbarContainer>
          <TableTitle variant="h4">Proposals</TableTitle>
          <LowerTopToolbarContainer>
            <Tabs
              value={activeTab}
              indicatorColor="primary"
              textColor="primary"
              onChange={(_event, newValue) =>
                navigate(`/proposals/${newValue}`)
              }
            >
              <Tab value={PageTab.Active} label="Active"/>
              <Tab value={PageTab.Drafts} label="Drafts"/>
              <Tab value={PageTab.Archived} label="Archived"/>
            </Tabs>
            <FilterButtons>
              {activeTab === PageTab.Active && (
                <>
                  <Chip
                    icon={<FilterListIcon/>}
                    label="Inbox"
                    color="primary"
                    variant={inbox ? "filled" : "outlined"}
                    onClick={() => {
                      setInbox(!inbox);
                      setOutbox(false);
                      setPendingReview(false);
                      setSearchParams((prevParams) => {
                        const params = new URLSearchParams(prevParams);
                        if (!inbox) {
                          params.set(
                            "context",
                            "inbox"
                          );
                        } else {
                          params.delete("context");
                        }
                        return params;
                      });
                    }}
                  />
                  <Chip
                    icon={<FilterListIcon/>}
                    label="Outbox"
                    color="primary"
                    variant={outbox ? "filled" : "outlined"}
                    onClick={() => {
                      setOutbox(!outbox);
                      setInbox(false);
                      setPendingReview(false);
                      setSearchParams((prevParams) => {
                        const params = new URLSearchParams(prevParams);
                        if (!outbox) {
                          params.set(
                            "context",
                            "outbox"
                          );
                        } else {
                          params.delete("context");
                        }
                        return params;
                      });
                    }}
                  />
                  <Chip
                    icon={<FilterListIcon/>}
                    label="Pending Your Review"
                    color="primary"
                    variant={pendingReview ? "filled" : "outlined"}
                    onClick={() => {
                      setPendingReview(!pendingReview);
                      setInbox(false);
                      setOutbox(false);
                      setSearchParams((prevParams) => {
                        const params = new URLSearchParams(prevParams);
                        if (!pendingReview) {
                          params.set(
                            "context",
                            "activereviewawaiting"
                          );
                        } else {
                          params.delete("context");
                        }
                        return params;
                      });
                    }}
                  />
                </>
              )}
              {activeTab === PageTab.Drafts && (
                <Chip
                  icon={<FilterListIcon/>}
                  label="Pending Your Review"
                  color="primary"
                  variant={pendingReview ? "filled" : "outlined"}
                  onClick={() => {
                    setPendingReview(!pendingReview);
                    setInbox(false);
                    setOutbox(false);
                    setSearchParams((prevParams) => {
                      const params = new URLSearchParams(prevParams);
                      if (!pendingReview) {
                        params.set(
                          "context",
                          "draftreview"
                        );
                      } else {
                        params.delete("context");
                      }
                      return params;
                    });
                  }}
                />
              )}
            </FilterButtons>
          </LowerTopToolbarContainer>
          {isMediumDisplaySize && (
            <ToolbarButtonsContainer>
              <ToolbarButtons>
                <MRT_ToggleGlobalFilterButton table={table}/>
                <MRT_ToggleFiltersButton table={table}/>
                <MRT_ShowHideColumnsButton table={table}/>
                <MRT_ToggleDensePaddingButton table={table}/>
                <MRT_ToggleFullScreenButton table={table}/>
              </ToolbarButtons>
            </ToolbarButtonsContainer>
          )}
        </TableTopToolbarContainer>
      );
    },
    getRowId: (row) => row.id?.toString() ?? Guid.generate().toString(),
    renderRowActionMenuItems: ({row}) => getMenuItems(row.original),
    enableColumnPinning: true,
    initialState: {
      columnPinning: {right: ["mrt-row-actions"]},
    },
    manualFiltering: true,
    enableStickyHeader: true,
    enableStickyFooter: true,
    manualPagination: true,
    manualSorting: true,
    muiTableContainerProps: ({table}) => ({
      sx: {
        height: `calc(100% - ${table.refs.topToolbarRef.current?.offsetHeight}px - ${table.refs.bottomToolbarRef.current?.offsetHeight}px)`,
      },
    }),
    muiTablePaperProps: {
      sx: {
        height: "100%",
      },
    },
    muiToolbarAlertBannerProps: errorLoadingQuery
      ? {
        color: "error",
        children: "Error loading data",
      }
      : {
        action: (
          <AlertActions>
            <Button
              color="primary"
              startIcon={<DeleteIcon/>}
              onClick={handleBulkDraftProposalDelete}
            >
              Delete
            </Button>
          </AlertActions>
        ),
      },
    // onColumnFiltersChange: setColumnFilters,
    // onGlobalFilterChange: setGlobalFilter,
    // onPaginationChange: setPagination,
    // onSortingChange: setSorting,
    onRowSelectionChange: setRowSelection,
    state: {
      // columnFilters,
      // globalFilter,
      isLoading,
      // pagination,
      showAlertBanner: Boolean(errorLoadingQuery),
      showProgressBars: isLoading,
      // sorting,
      rowSelection,
    },
  });

  if (!session.user?.companyEntityId) {
    return (
      <Container sx={{height: "100%", textAlign: "center"}}>
        <Typography
          sx={{alignContent: "center", height: "100%"}}
          variant="h4"
        >
          You are not authorized to view this page
        </Typography>
      </Container>
    );
  }

  return <MaterialReactTable table={table}/>;
}
