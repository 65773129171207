import moment, { Moment } from 'moment';
import Guid from "common/values/guid/guid";

import ReadReceipt from 'messaging/values/read-receipts/read-receipt';
import CommentThread from 'work/entities/comment-thread/comment-thread';
import Message from 'messaging/entities/message/message';

export default class Comment {
  id: Guid;
  senderId: Guid | null;
  thread: CommentThread;
  content: string;
  publishedOn?: Moment;
  readReceipts: ReadReceipt[] = [];
  issues: string[] = [];
  deletedAt?: Moment;

  isAutoGenerated: boolean = false;
  markedForCreation?: boolean;
  markedForEdit?: boolean;
  markedForReadReceipt?: boolean;
  markedForUnread?: boolean;
  markedForDeletion?: boolean;

  constructor(
    thread: CommentThread,
    content: string,
    senderId: Guid | null,
    isAutoGenerated: boolean = false,
    id?: Guid,
    publishedOn?: Moment,
    issues?: string[],
    readReceipts?: ReadReceipt[],
    deletedAt?: Moment
  ) {
    if (!content) throw new EmptyCommentError();

    this.thread = thread;
    this.id = id ?? Guid.generate();
    this.senderId = senderId;
    this.isAutoGenerated = isAutoGenerated;
    this.content = content;
    this.publishedOn = publishedOn;
 
    this.issues = issues ?? [];
    this.readReceipts = readReceipts ?? [];
    this.deletedAt = deletedAt;

    if(!id){
      this.publishedOn = moment();
      this.markedForCreation = true;
    }
  }

  public get isPending(): boolean {
    return Boolean(
      this.markedForCreation ||
      this.markedForEdit ||
      this.markedForReadReceipt !== undefined ||
      this.markedForUnread !== undefined ||
      this.markedForDeletion
    )
  }

  public edit(content: string): void {
    this.content = content;
    this.markedForEdit = true;
  }
  
  public setDeleted(): void {
    this.deletedAt = moment();
    this.markedForDeletion = true;
  }

  public setUndeleted(): void {
    this.deletedAt = undefined;
    this.markedForDeletion = false;
  }

  public setRead(): void {
    if(this.markedForUnread){
      this.markedForUnread = false;
      this.markedForReadReceipt = false;
    } else {
      this.markedForReadReceipt = true
    }
  }
  public setUnread(): void {
    if(this.markedForReadReceipt){
      this.markedForReadReceipt = false;
      this.markedForUnread = false;
    } else {
      this.markedForUnread = true;
    }
  }

  /** Soft delete flag */
  public get isDeleted() : boolean{
    return !!this.deletedAt;
  }

  public isReadByUser(userId?: Guid): boolean {
    if (!userId) return false;
    return this.readReceipts.some((receipt) => receipt.userId.isEqualTo(userId));
  }

  public static fromMessage(message: Message): Comment {
    return new Comment(
      CommentThread.fromForum(message.forum),
      message.content,
      message.senderId,
      message.isAutoGenerated,
      message.id,
      message.publishedOn,
      message.issues,
      message.readReceipts,
      message.deletedAt
    );
  }
  public toMessage(isViewingAsVendor: boolean): Message {
    const message = new Message(
      this.thread.toForum(isViewingAsVendor),
      this.content,
      this.senderId,
      undefined,
      this.publishedOn,
      undefined,
      this.id,
      this.issues,
      this.readReceipts,
      this.deletedAt
    );
  
    message.isAutoGenerated = this.isAutoGenerated;
    message.markedForCreation = this.markedForCreation;
    message.markedForEdit = this.markedForEdit;
    message.markedForReadReceipt = this.markedForReadReceipt;
    message.markedForUnread = this.markedForUnread;
    message.markedForDeletion = this.markedForDeletion;

    return message;
  }
  public clone() : Comment {
    const clone = new Comment(
      this.thread,
      this.content,
      this.senderId,
      this.isAutoGenerated,
      this.id,
      this.publishedOn,
      this.issues,
      this.readReceipts,
      this.deletedAt
    )
    clone.markedForCreation = this.markedForCreation;
    clone.markedForEdit = this.markedForEdit;
    clone.markedForReadReceipt = this.markedForReadReceipt;
    clone.markedForUnread = this.markedForUnread;
    clone.markedForDeletion = this.markedForDeletion;
    return clone;
  }
}

export class EmptyCommentError extends Error {
  constructor() {
    super(`Comment cannot be empty`);
  }
}
