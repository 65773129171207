import AddCommentIcon from "@mui/icons-material/AddComment";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import {
  Chip,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grow,
  List,
  ListItem,
  ListItemText,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import {styled} from "@mui/material/styles";
import {useAttorneyHubDispatch} from "app/realtime-store/redux-store";
import Loader from "common/components/loader";
import LoadingButton from "common/components/loading-button";
import * as Constants from "common/helpers/constants";
import Guid from "common/values/guid/guid";
import _ from "lodash";
import {populateIndividuals} from "marketplace/entities/individual/realtime-store/individuals-redux-slice";
import React, {useEffect, useRef} from "react";
import {useSession} from "users/session/session-context";
import CommentThread from "work/entities/comment-thread/comment-thread";
import Comment from "work/entities/comment/comment";
import {
  addComment,
  getCommentsByField,
  getIsLoadingComments,
  populateThreadComments,
} from "work/entities/comment/store/comments-redux-slice";

import CommentItem from "work/entities/comment/view/comment-item";
import Proposal, {ProposalField} from "work/entities/proposal/proposal";

const CommentsContainer = styled("div")(() => ({
  display: "contents",
  height: "100%",
}));
const CommentsList = styled(List)(() => ({
  overflowY: "auto",
}));
const PostCommentInput = styled(TextField)(({theme}) => ({
  "& .MuiOutlinedInput-root": {
    borderRadius: 0,
    "& fieldset": {
      borderColor: theme.palette.common.black,
    },
    "&:hover fieldset": {
      borderColor: theme.palette.common.black,
    },
    "&.Mui-focused fieldset": {
      borderColor: theme.palette.common.black,
    },
  },
}));
const AudienceLabel = styled("span")<{ isexternal: boolean }>(
  ({theme, isexternal}) => ({
    color: isexternal ? theme.palette.error.main : theme.palette.common.black,
    fontWeight: isexternal ? theme.typography.fontWeightBold : undefined,
  })
);
const BackToTopChipContainer = styled("div")(() => ({
  display: "flex",
  height: 0,
  justifyContent: "center",
  position: "relative",
  width: "100%",
  zIndex: 1,
}));
const BackToTopChip = styled(Chip)(({theme}) => ({
  backgroundColor: theme.palette.primary.main,
  top: "20px",
  boxShadow:
    "0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%), 0 2px 4px 0 rgb(0 0 0 / 20%)",
  color: "white",
  position: "absolute",
  "&:hover": {
    backgroundColor: theme.palette.primary.main,
  },
}));

type CommentsProps = {
  className?: string;
  proposal: Proposal;
  commentThreads: CommentThread[];
  field: ProposalField;
  isSaving: boolean;
};

export default function Comments(props: Readonly<CommentsProps>) {
  const {className, proposal, commentThreads, field, isSaving} = props;

  const topRef = useRef<HTMLDivElement>(null);
  const commentsListRef = useRef<HTMLUListElement>(null);

  const [backToTopVisible, setBackToTopVisible] = React.useState(false);

  const session = useSession();

  const fieldComments = getCommentsByField(proposal.id, field);
  const isLoadingComments = getIsLoadingComments(proposal.id, field);
  const dispatch = useAttorneyHubDispatch();

  const [commentText, setCommentText] = React.useState("");
  const [isExternal, setIsExternal] = React.useState(false);

  const handleWindowScrolled = (_event: any) => {
    if (!commentsListRef?.current) return;
    const scrollPercent = Math.round(
      (commentsListRef?.current.scrollTop /
        (commentsListRef?.current.scrollHeight -
          commentsListRef?.current.clientHeight)) *
      100.0
    );
    setBackToTopVisible(scrollPercent > 5);
  };

  const throttledScrollEventHandler = _.throttle(
    handleWindowScrolled,
    Constants.backToTopThrottleDelay
  );

  useEffect(() => {
    scrollToBottom();
    window.setTimeout(() => {
      commentsListRef?.current?.addEventListener(
        "scroll",
        throttledScrollEventHandler
      );
    }, 1000);

    const subscriberIds = _.uniqBy(
      [
        ...(fieldComments?.map((comment: Comment) => comment.senderId).filter((id) => id) ?? []),
        ...commentThreads.flatMap(
          (thread: CommentThread) => thread.subscriberIds
        ),
      ],
      (id) => id?.value
    )

    dispatch(populateIndividuals({session, userIds: subscriberIds as Guid[]}));
  }, []);

  useEffect(
    () => {
      if (fieldComments === undefined) {
        commentThreads.forEach((thread) => {
          if (field.isEqualTo(thread.field)) {
            dispatch(populateThreadComments({session, thread}));
          }
        });
      }
    },
    [
      fieldComments,
      field,
      commentThreads
    ]
  );

  const scrollToBottom = () => {
    window.setTimeout(() => {
      topRef?.current?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "nearest",
      });
    }, 500);
  };

  function renderAudienceSwitch() {
    const isClient = proposal?.client?.userId.isEqualTo(session.user?.id);
    const isTeamLeader = proposal?.team?.leader?.userId.isEqualTo(
      session.user?.id
    );
    if (proposal && !isClient && !isTeamLeader) return null;

    return (
      <FormControl component="span" variant="standard">
        <FormControlLabel
          control={
            <Switch
              checked={isExternal}
              color={isExternal ? "error" : undefined}
              onChange={() => setIsExternal((prevValue) => !prevValue)}
            />
          }
          label={
            <AudienceLabel isexternal={isExternal}>
              External Comment
            </AudienceLabel>
          }
        />
        {isExternal && (
          <FormHelperText>
            <Typography color={isExternal ? "error" : undefined}>
              <strong>Note:</strong> This comment will be visible to everyone
              associated with this proposal, including:
              <br/>
              <i>
                the proposal creator, team, and both client and vendor reviewers
              </i>
            </Typography>
          </FormHelperText>
        )}
      </FormControl>
    );
  }

  function handleCommentPosted() {
    const comment = draftComment(field, commentText, isExternal);
    if (!comment) {
      console.error("Failed to draft comment");
      return;
    }
    dispatch(addComment(comment));
    setCommentText("");
    setIsExternal(false);
    scrollToBottom();
  }

  function sortCommentsByDate(comments: Comment[]): Comment[] {
    let sortedComments = [
      ..._.uniqBy(comments, (comment) => comment.id?.value),
    ];
    return sortedComments.sort((a, b) => {
      if (a.publishedOn && b.publishedOn) {
        return b.publishedOn.diff(a.publishedOn);
      }
      return 0;
    });
  }

  function draftComment(
    field: ProposalField,
    commentText: string,
    isExternal: boolean,
    isAutoComment: boolean = false
  ): Comment | undefined {
    if (!proposal?.id || !session.user?.id) return;

    const thread = getOrCreateCommentThread(field, isExternal);
    const newComment = new Comment(
      thread,
      commentText,
      session.user.id,
      isAutoComment
    );

    return newComment;
  }

  function getOrCreateCommentThread(
    field: ProposalField,
    isExternal: boolean
  ): CommentThread {
    const existingThread = commentThreads?.find((thread) => {
      return (
        thread.field.isEqualTo(field) &&
        thread.isExternal === isExternal &&
        thread.proposalId.isEqualTo(proposal.id)
      );
    });
    if (existingThread) {
      return existingThread;
    } else {
      return CommentThread.fromProposalField(
        proposal,
        field ?? ProposalField.General,
        session.context?.viewingAsVendor ?? false,
        isExternal
      );
    }
  }

  return isLoadingComments ? (
    <Loader/>
  ) : (
    <CommentsContainer className={className}>
      <PostCommentInput
        placeholder="Type your comment here..."
        multiline
        rows={4}
        variant="outlined"
        fullWidth
        value={commentText}
        helperText={renderAudienceSwitch()}
        slotProps={{
          input: {
            endAdornment: (
              <LoadingButton
                color="primary"
                endIcon={<AddCommentIcon/>}
                disabled={commentText.trim() === ""}
                loading={isSaving}
                onClick={handleCommentPosted}
              >
                Post
              </LoadingButton>
            ),
          },
        }}
        onKeyDown={(event) => {
          if (event.key === "Enter" && commentText.trim() !== "") {
            event.preventDefault();
            handleCommentPosted();
          }
        }}
        onChange={(event) => setCommentText(event.target.value)}
      />
      {Boolean(fieldComments?.length && fieldComments.length > 0) && (
        <BackToTopChipContainer>
          <Grow in={backToTopVisible}>
            <BackToTopChip
              label="Jump to latest"
              icon={<ArrowUpwardIcon style={{color: "white"}}/>}
              onClick={() =>
                topRef?.current?.scrollIntoView({
                  behavior: "smooth",
                  block: "nearest",
                  inline: "nearest",
                })
              }
            />
          </Grow>
        </BackToTopChipContainer>
      )}
      <CommentsList ref={commentsListRef}>
        <div ref={topRef}/>
        {fieldComments?.length === 0 && (
          <ListItem key={Guid.generate().value} disableGutters>
            <ListItemText>No comments yet</ListItemText>
          </ListItem>
        )}
        {sortCommentsByDate(fieldComments).map((comment: Comment) => {
          return (
            <CommentItem
              key={comment.id?.value}
              comment={comment}
              audienceSwitch={renderAudienceSwitch()}
            />
          );
        })}
      </CommentsList>
    </CommentsContainer>
  );
}
